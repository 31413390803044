import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/layout';
import localizationService from './services/localization.service';
import syncService from './services/sync.service';
import kitService from './services/kit.service';
import localization from './localization/localization';
import { loc } from './localization/localization';
import KitManager from './components/kits/kit-manager';
import Loader from './components/shared/loader';
import { loaderConstants } from './constants/constants';
import OverViewManager from './components/overview/overview-manager';
import { setValuesFromCookie } from './helpers/storage.utils';
import { StudySupplyOverviewRoute } from './routes/authorization-route';
import FullPageError from './components/shared/full-page-error';
import { canAccessStudySupplyOverView } from './permissions/user-permissions';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            guid: null,
            contextLoading: true,
            syncError: '',
            contextError: '',
            settingsModalIsOpen: false,
            transferModalIsOpen: false,
            overviewSelectedSite: '',
            overviewSelectedCountry: '',
            projectName: ''
        };

        setValuesFromCookie(document.cookie);
    }

    componentDidMount() {
        this.loadLocalization().then(() => {
            this.loadContext();
        });
    }

    loadContext() {
        let self = this;
        kitService
            .getContext()
            .then(context => {
                syncService
                    .sync(context.randGuid)
                    .then(() => {
                        kitService
                            .getContext()
                            .then(context => {
                                self.setState({
                                    guid: context.randGuid,
                                    projectName: context.projectName,
                                    contextLoading: false,
                                    studyPermissions: {
                                        isStudyManagerWithEditPermission:
                                            context.studyPermissions
                                                .isStudyManagerWithEditPermission,
                                        isStudyManagerWithViewPermission:
                                            context.studyPermissions
                                                .isStudyManagerWithViewPermission
                                    }
                                });
                            })
                            .fail(() => {
                                this.setState({
                                    contextError: loc.rtsmConnectionError,
                                    contextLoading: false
                                });
                            });
                    })
                    .fail(() => {
                        this.setState({
                            syncError: loc.permissionError,
                            contextLoading: false
                        });
                    });
            })
            .fail(() => {
                this.setState({
                    contextError: loc.rtsmConnectionError,
                    contextLoading: false
                });
            });
    }

    loadLocalization() {
        return new Promise((resolve, reject) => {
            localizationService.get().then(result => {
                try {
                    localization.setLoc(result);
                    localization.testLoc();
                    resolve();
                } catch (e) {
                    for (let i = 0; i < e.length; i++) {
                        console.error('localization error: ' + e[i]);
                    }
                    reject();
                }
            });
        });
    }

    onOverviewCountryChange(country) {
        this.setState({ overviewSelectedCountry: country });
    }

    onOverviewSiteChange(site) {
        this.setState({ overviewSelectedSite: site });
    }

    renderContent() {
        return (
            <Layout>
                <BrowserRouter>
                    <Switch>
                        <StudySupplyOverviewRoute
                            exact
                            path={['/overview', '/']}
                            isAuthorized={
                                canAccessStudySupplyOverView(this.state.studyPermissions)
                            }
                            render={routeProps => (
                                <OverViewManager
                                    {...routeProps}
                                    {...this.state}
                                    modalIsOpen={this.state.settingsModalIsOpen}
                                    selectedSite={
                                        this.state.overviewSelectedSite
                                    }
                                    selectedCountry={
                                        this.state.overviewSelectedCountry
                                    }
                                    onCountryChange={this.onOverviewCountryChange.bind(
                                        this
                                    )}
                                    onSiteChange={this.onOverviewSiteChange.bind(
                                        this
                                    )}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={[
                                '/kit/:guid',
                                '/kits/:filterParam',
                                '/kit',
                                '/kits',
                                '/kits/:countryCode/:siteCode'
                            ]}
                            render={routeProps => (
                                <KitManager {...routeProps} {...this.state} />
                            )}
                        />
                    </Switch>
                </BrowserRouter>
            </Layout>
        );
    }

    render() {
        let content =
            this.state.contextLoading ||
                this.state.contextError ||
                this.state.syncError ? (
                    <div></div>
                ) : (
                    this.renderContent()
                );

        return (
            <React.Fragment>
                <Loader
                    loading={this.state.contextLoading}
                    position={loaderConstants.position.horizontallyCenter}
                    type={loaderConstants.type.medium}
                >
                    <FullPageError
                        message={this.state.contextError}
                    ></FullPageError>
                    <FullPageError
                        message={this.state.syncError}
                    ></FullPageError>
                    {content}
                </Loader>
            </React.Fragment>
        );
    }
}

export default App;
