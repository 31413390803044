export const capitalizeFirstLetter = (string) =>  {
    if (!string) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const lowerCaseFirstLetter = (string) =>  {
    if (!string) {
        return '';
    }
    return string.charAt(0).toLowerCase() + string.slice(1);
};