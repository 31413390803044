import React from "react";
import PropTypes from 'prop-types';
import ActionLink from '../shared/actionlink';
import { loc } from "../../localization/localization";

export default class KitMenuItemSettings extends React.Component {

    onOpenModal() {
        let obj = {};
        //todo maybe pass in modal type
        this.props.onOpenModal(obj);
    }

    render() {
        return (
            <React.Fragment>
                <ActionLink title={loc.edit}
                    onClick={() => this.onOpenModal()}>
                    <i className="icon tools-blue-round" />
                </ActionLink>
            </React.Fragment>
        );
    }
}

KitMenuItemSettings.propTypes = {
    onOpenModal: PropTypes.func.isRequired,
};