import React from "react";
import PropTypes from 'prop-types';
import Checkbox from '../../shared/checkbox';
import localization from '../../../localization/localization';
import { getPropertyValues } from "../../../helpers/object.utils";
import { generalSettingsConstants } from "../../../constants/constants";
import { isExpiryPeriodValid } from './validation/validation';

const kitIconTypes = getPropertyValues(generalSettingsConstants, 'kitIcon');

export default class SettingsGeneralContainer extends React.Component {

    render() {
        const { settings, readOnly } = this.props;

        return (
            <React.Fragment>
                <div className="field-row">
                    <Checkbox onChange={this.props.onExpiryPeriodEnabledChanged}
                        value={settings.id} isChecked={settings.expiryPeriod.enabled}
                        labelText={localization.getLocOrKey('useExpiryPeriod')}
                        disabled={readOnly}
                        classNames={['smaller']} />
                    <div className="field-item margin-left-10 margin-top-minus-2">
                        <div className={`text-field ${isExpiryPeriodValid(settings.expiryPeriod) ? '' : 'hasError'}`}>
                            <input type="text"
                                onChange={e => this.props.onExpiryPeriodValueChanged(e.target.value)}
                                value={settings.expiryPeriod.value || ''} className="strong" style={{ width: 60 }}
                                disabled={readOnly}
                                maxLength="3" />
                        </div>
                    </div>
                </div>
                {/* <div className="field-row padding-bottom-0">
                    <div className="field-item" style={{ width: 200 }}>
                        <div className="ttl-box text-grey-dark-lighter">
                            <small>{localization.getLocOrKey('nameOfKitType')}</small>
                        </div>
                        {settings.kitTypeSettings.map((kitTypeSetting, index) =>
                            <SettingsGeneralKitTypeRow key={index} name={kitTypeSetting.name} />
                        )}
                    </div>
                    <div className="field-item margin-left-10">
                        <div className="ttl-box text-grey-dark-lighter">
                            <small>{localization.getLocOrKey('numberOfAllocationsPerSubject')}</small>
                        </div>
                        {settings.kitTypeSettings.map((kitTypeSetting, index) =>
                            <SettingsGeneralAllocationsPerSubjectRow key={index}
                                setting={kitTypeSetting}
                                onChange={this.props.onNumberOfAllocationsPerSubjectChange} />
                        )}
                    </div>
                </div> */}
                {/* <div className="field-row padding-bottom-0">
                    <SettingsGeneralKitIconContainer kitIcon={settings.kitIcon} onChange={this.props.onKitIconChange} />
                </div> */}
                {/* <div className="field-row padding-bottom-0">
                    <Checkbox onChange={() => this.props.onAutomaticInvalidationChanged()}
                        value={settings.id} isChecked={settings.automaticInvalidation.enabled}
                        labelText={localization.getLocOrKey('invalidateKitAutomaticallyWhenTheExpiryDateIsCloserThan')}
                        classNames={['smaller']} />
                    <div className="field-item margin-left-10 margin-top-minus-2">
                        <div className={`text-field ${isAutomaticInvalidationValid(settings.automaticInvalidation) ? '' : 'hasError'}`}>
                            <input type="text"
                                onChange={e => this.props.onAutomaticInvalidationValueChanged(e.target.value)}
                                value={settings.automaticInvalidation.value || ''} className="strong" style={{ width: 60 }} />
                        </div>
                    </div>
                    <div className="field-item margin-top-7 margin-left-10">{localization.getLocOrKey('daysWithDot')}</div>
                </div> */}
                {/* <div className="field-row padding-top-10 padding-bottom-0">
                    <Checkbox onChange={() => this.props.onAllocatedInstantlyWhenInTransfer()}
                        value={settings.id} isChecked={settings.allowAllocateOnKitsInTransfer}
                        labelText={localization.getLocOrKey('allowValidKitsToBeAllocatedInstantlyWhenKitsAreSetInTransferToTheCorrespondingSite')}
                        classNames={['smaller']} />
                </div> */}
                {/* <div className="field-row">
                    <Checkbox onChange={() => this.props.onCentralDepotAllocation()}
                        value={settings.id} isChecked={settings.allowAllocationOnKitsInCentralDepot}
                        labelText={localization.getLocOrKey('allowValidKitsToBeAllocatedWhenKitsAreInCentralDepot')}
                        classNames={['smaller']} />
                </div> */}
                {/* <div className="field-row divider padding-bottom-0">
                    <Checkbox onChange={() => this.props.onEditContactInfo()}
                        value={settings.id} isChecked={settings.allowEditLocationContactInfo}
                        labelText={localization.getLocOrKey('allowLocalLogisticsManagerToEditLocalContactInfo')}
                        classNames={['smaller']} />
                </div> */}
                {/* <div className="field-row">
                    <Checkbox onChange={() => this.props.onTransferToOtherSites()}
                        value={settings.id} isChecked={settings.allowTransferToOtherSites}
                        labelText={localization.getLocOrKey('allowLocalLogisticsManagerToTransferKitsToOtherSitesAndToSeeOtherSitesContactInfo')}
                        classNames={['smaller']} />
                </div> */}
            </React.Fragment>
        );
    }
}


SettingsGeneralContainer.propTypes = {
    settings: PropTypes.shape({
        id: PropTypes.string.isRequired,
        kitTypeSettings: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            code: PropTypes.string.isRequired,
            numberOfAllocationsPerSubject: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            // validation: PropTypes.shape({
            //     numberOfAllocationsPerSubject: PropTypes.arrayOf(PropTypes.shape({
            //         errorMessage: PropTypes.string.isRequired,
            //         key: PropTypes.string.isRequired,
            //         value: PropTypes.string.isRequired
            //     })).isRequired
            // }).isRequired
        })).isRequired,
        kitIcon: PropTypes.oneOf(kitIconTypes).isRequired,
        automaticInvalidation: PropTypes.shape({
            enabled: PropTypes.bool.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            // validation: PropTypes.shape({
            //     value: PropTypes.arrayOf(PropTypes.shape({
            //         errorMessage: PropTypes.string.isRequired,
            //         key: PropTypes.string.isRequired,
            //         value: PropTypes.string.isRequired
            //     })).isRequired
            // }).isRequired
        }).isRequired,
        expiryPeriod: PropTypes.shape({
            enabled: PropTypes.bool.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            validation: PropTypes.shape({
                value: PropTypes.arrayOf(PropTypes.shape({
                    errorMessage: PropTypes.string.isRequired,
                    key: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                    conditionPropertyName: PropTypes.string,
                    conditionValue: PropTypes.bool,
                })).isRequired
            }).isRequired
        }).isRequired,
        allowAllocateOnKitsInTransfer: PropTypes.bool.isRequired,
        allowAllocationOnKitsInCentralDepot: PropTypes.bool.isRequired,
        allowEditLocationContactInfo: PropTypes.bool.isRequired,
        allowTransferToOtherSites: PropTypes.bool.isRequired
    }).isRequired,
    readOnly: PropTypes.bool.isRequired,
    onKitIconChange: PropTypes.func.isRequired,
    onNumberOfAllocationsPerSubjectChange: PropTypes.func.isRequired,
    onAutomaticInvalidationChanged: PropTypes.func.isRequired,
    onAutomaticInvalidationValueChanged: PropTypes.func.isRequired,
    onAllocatedInstantlyWhenInTransfer: PropTypes.func.isRequired,
    onCentralDepotAllocation: PropTypes.func.isRequired,
    onEditContactInfo: PropTypes.func.isRequired,
    onTransferToOtherSites: PropTypes.func.isRequired,
    onExpiryPeriodEnabledChanged: PropTypes.func.isRequired,
    onExpiryPeriodValueChanged: PropTypes.func.isRequired
};