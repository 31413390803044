import {
    jsonRequiredAttribute,
    jsonRegularExpression,
    jsonConditionRegularExpression
} from './constants';
import { regexConstants } from '../constants/constants';

function isPropertyValid(
    validationObj,
    propertyName,
    propertyValue,
    conditionPropertyValue
) {
    const validationProperties = validateProperty(
        validationObj,
        propertyName,
        propertyValue,
        conditionPropertyValue
    );

    const isValid = validationProperties.every(prop => prop.isValid);

    return isValid;
}

function getErrorMessages(validationObj, propertyName) {
    const validationProperties = getValidationPropertiesForProperty(
        propertyName,
        validationObj
    );

    const messages = [];

    validationProperties.forEach(validationProperty => {
        messages.push(
            getValidationPropertyObject(
                validationProperty.index,
                propertyName,
                validationObj
            ).errorMessage
        );
    });

    return messages.filter(msg => msg !== '');
}

function getErrorMessagesForInvalidValidations(
    validationObj,
    propertyName,
    propertyValue,
    conditionPropertyValue
) {
    const validationProperties = validateProperty(
        validationObj,
        propertyName,
        propertyValue,
        conditionPropertyValue
    );
    return validationProperties
        .filter(x => !x.isValid)
        .map(x => x.errorMessage);
}

function validateProperty(
    validationObj,
    propertyName,
    propertyValue,
    conditionPropertyValue
) {
    if (
        !validationObj ||
        (Object.keys(validationObj).length === 0 &&
            validationObj.constructor === Object)
    ) {
        throw new Error('Validation model is null or empty');
    }

    let validationProperties = [];

    //fetches validation properties for the property name of the model
    const validationPropertyInfo = getValidationPropertiesForProperty(
        propertyName,
        validationObj
    );

    validationPropertyInfo.forEach(validation => {
        const { validationPropertyName, index } = validation;
        switch (validationPropertyName) {
            //the property has a required attribute
            case jsonRequiredAttribute:
                const validationPropertyObjectRequired = getValidationPropertyObject(
                    index,
                    propertyName,
                    validationObj
                );

                if (!validateRequired(propertyValue)) {
                    validationProperties.push({
                        isValid: false,
                        errorMessage:
                            validationPropertyObjectRequired.errorMessage
                    });
                } else {
                    validationProperties.push({ isValid: true });
                }

                break;
            //the property has a regular expression attribute
            case jsonRegularExpression:
                const validationPropertyObjectRegex = getValidationPropertyObject(
                    index,
                    propertyName,
                    validationObj
                );

                if (
                    !validateRegex(
                        propertyValue,
                        validationPropertyObjectRegex.value,
                        validationPropertyObjectRegex.allowNull
                    )
                ) {
                    validationProperties.push({
                        isValid: false,
                        errorMessage: validationPropertyObjectRegex.errorMessage
                    });
                } else {
                    validationProperties.push({ isValid: true });
                }
                break;
            //the property has a regular expression that should be executed based on a boolean value
            case jsonConditionRegularExpression:
                //null or undefined
                if (conditionPropertyValue === null) {
                    throw new Error(
                        'A condition regex needs a condition property value'
                    );
                }

                const validationPropertyObjectConditionRegex = getValidationPropertyObject(
                    index,
                    propertyName,
                    validationObj
                );

                //if the condition property is not set to the chosen value, a regex validation should not be performed
                if (
                    conditionPropertyValue !==
                    validationPropertyObjectConditionRegex.conditionValue
                ) {
                    validationProperties.push({ isValid: true });
                }
                //if the condition property matches the chosen value, a regex validation must be performed for the property to be valid
                else if (
                    conditionPropertyValue ===
                        validationPropertyObjectConditionRegex.conditionValue &&
                    validateRegex(
                        propertyValue,
                        validationPropertyObjectConditionRegex.value,
                        validationPropertyObjectConditionRegex.allowNull
                    )
                ) {
                    validationProperties.push({ isValid: true });
                } else {
                    validationProperties.push({
                        isValid: false,
                        errorMessage:
                            validationPropertyObjectConditionRegex.errorMessage
                    });
                }

                break;
            default:
                throw new Error('Not Implemented');
        }
    });

    return validationProperties;
}

function validateRegex(value, pattern, allowNull) {
    // eslint-disable-next-line eqeqeq
    if (allowNull === true && value == null) {
        return true;
    }
    // eslint-disable-next-line eqeqeq
    else if (value == null) {
        return false;
    }

    const regex = new RegExp(pattern);
    let result = regex.test(value);

    return result;
}

/**
 * Validates a required object.
 * The object has to be non-undefined and non-null.
 * For objects of type "string", it must be non-empty and non-whitespace.
 * For objects of type "number", it has to have a value larger than or equal to 0.
 * @param {*} value The value to validate
 */
function validateRequired(value) {
    switch (typeof value) {
        case 'undefined':
            return false;
        case 'object':
            // eslint-disable-next-line eqeqeq
            return value != null;
        case 'string':
            return validateRegex(
                value,
                regexConstants.notEmptyOrWhitespace,
                false
            );
        case 'number':
            return value >= 0;
        default:
            throw new Error(
                `No validation implemented for type "${typeof value}"`
            );
    }
}

function getValidationPropertiesForProperty(propertyName, validation) {
    try {
        let validationProps = validation[propertyName];

        if (!validationProps && validationProps.length === 0) {
            //this property has no corresponding validation property
            return null;
        }

        let names = [];
        //adds validation properties
        //eq. required, regex
        for (let i = 0; i < validationProps.length; i++) {
            names.push({
                validationPropertyName: validationProps[i].key,
                index: i
            });
        }

        return names;
    } catch (e) {
        return null;
    }
}

function getValidationPropertyObject(index, modelPropertyName, validation) {
    //finds the property name in the validation object
    const propObj = validation[modelPropertyName][index];

    return propObj;
}

const modelValidator = {
    isPropertyValid,
    getErrorMessages,
    getErrorMessagesForInvalidValidations
};

export default modelValidator;
