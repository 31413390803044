import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../localization/localization';
import ActionLink from './actionlink';


export default class PageSize extends React.Component {

    render() {
        const currentPageSize = this.props.pageSize;
        const sizes = this.props.pageSizes ? this.props.pageSizes : [20, 50, 100];
        var self = this;

        return (
            <React.Fragment>
                {loc.viewPerPage} {sizes.map(
                    size => {
                        if (size === currentPageSize) {
                            return <span key={size}>{size}</span>;
                        }
                        return <ActionLink key={size} title={size} onClick={() => self.props.onPageSizeChange(size)}>{size}</ActionLink>;
                    }).reduce((p, c) => [p, " | ", c])}
            </React.Fragment>
        );
    }
}

PageSize.propTypes = {
    pageSize: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    pageSizes: PropTypes.array,
};