import React from "react";
import PropTypes from 'prop-types';
import AnimatedModal from "./animated-modal";
import AnimatedModalHeader from "./animated-modal-header";
import AnimatedModalAnimation from "./animated-modal-animation";
import AnimatedModalBody from "./animated-modal-body";

export default class KitActionTransferModal extends React.Component {
    render() {
        const { id } = this.props;

        return (
            <React.Fragment>
                <AnimatedModal
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}>
                    <AnimatedModalHeader
                        onClose={this.props.onClose}>
                    </AnimatedModalHeader>
                    <AnimatedModalAnimation>
                        <AnimatedModalBody
                            id={id}
                            onClose={this.props.onClose}>
                        </AnimatedModalBody>
                    </AnimatedModalAnimation>
                </AnimatedModal>
            </React.Fragment>
        );
    }
}

KitActionTransferModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.string
};