import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import TopBar from '../topbar';
import KitListManager from '../kits/kit-list-manager';
import KitDetailsManager from '../kits/kit-details-manager';
import KitMenuContainer from '../menu/kit-menu-container';
import localization from '../../localization/localization';
import { loc } from '../../localization/localization';
import KitMetricsManager from '../metrics/kit-metrics-manager';
import fileUtils from '../../helpers/file.utils';
import { exportConstants, loaderConstants, metricsConstants } from '../../constants/constants';
import exportService from '../../services/export.service';
import locationService from '../../services/location.service';
import BtnDropdown from '../shared/btn-dropdown';
import Error from '../shared/error';
import Loader from '../shared/loader';
import { localizeSiteNames } from '../../helpers/localization.utils';

export default class KitManager extends React.Component {
    constructor(props) {
        super(props);

        let countryCode = this.props.match.params.countryCode;
        let siteCode = this.props.match.params.siteCode;

        this.kitListManagerComponent = React.createRef();
        this.kitMetricsManagerComponent = React.createRef();

        this.state = {
            selectedCountry: this.parseCode(countryCode),
            selectedSite: this.parseCode(siteCode),
            filterStatus: '',
            filterQuery: '',
            filterQueryFromUrl: '',
            orderBy: '',
            orderBySortingDirection: '',
            exportErrorMessage: '',
            exportLoading: false,
            loading: true,
            locationErrorMsg: '',
            pageSize: 20,
            pageIndex: 0,
            menuSettings: {
                displayContactItem: false,
                displayAddItem: false,
                displaySettingsItem: false
            }
        };
    }

    componentDidMount() {
        this.loadLocations().then(
            () => this.setState({ loading: false }, this.setFilterQueryIfProvided));
    }

    componentDidUpdate(prevProps) {
        this.setFilterQueryIfProvided();

        if (this.filterParamDidChange(prevProps)) {
            this.reloadMetricsAndKitList();
        }
    }

    loadLocations() {
        return new Promise((resolve, reject) => {
            locationService.getForStockList().then(locations => {
                this.setState({ locations: localizeSiteNames(locations) }, resolve);
            }).fail(() =>
                this.setState({
                    locationErrorMsg:
                        localization.getLocOrKey('couldNotFetchLocations')
                }, reject));
        });
    };

    filterParamDidChange(prevProps) {
        return prevProps.match.params.filterParam !== this.props.match.params.filterParam;
    }

    setFilterQueryIfProvided() {
        const filter = this.props.match.params.filterParam;

        if (this.state.filterQueryFromUrl !== filter) {
            this.setState({ filterQueryFromUrl: filter, pageIndex: 0 });

            if (filter) this.setState({ filterQuery: filter });
        }
    }

    parseCode(value) {
        if (value && value !== '0') return value;
        return '';
    }

    onListOrderByChange(column) {
        this.setState({
            orderBy: column.columnType,
            orderBySortingDirection: column.sortingDirection
        });
    }

    onCountryChange(country) {
        this.setState({ selectedCountry: country });
    }

    onSiteChange(site) {
        this.setState({ selectedSite: site });
    }

    onListFilterStatusChange(value) {
        this.setState({ filterStatus: value });
    }

    onFilterSearchChange(value) {
        this.setState({ filterQuery: value });
    }

    onFilterSearchTriggered(value) {
        this.setState(
            { filterQuery: value, pageIndex: 0 },
            this.reload(this.kitListManagerComponent)
        );
    }

    onPageSizeChange(value) {
        this.setState({ pageSize: value });
    }

    onPageIndexChanged(value) {
        this.setState({ pageIndex: value });
    }

    onKitActionApplied() {
        this.reloadMetricsAndKitList();
    }

    onExport(exportType) {
        const { exportLoading } = this.state;

        if (exportLoading) {
            return;
        } else {
            this.setState({ exportLoading: true, exportErrorMessage: '' }, () =>
                this.doExport(exportType)
            );
        }
    }

    doExport(exportType) {
        const { guid } = this.props;
        const {
            orderBy,
            orderBySortingDirection,
            selectedCountry,
            selectedSite
        } = this.state;
        const kitGuid = this.props.match.params.guid;

        switch (exportType) {
            case exportConstants.type.allKits:
                exportService
                    .stockList(
                        guid,
                        orderBy,
                        orderBySortingDirection,
                        selectedCountry,
                        selectedSite
                    )
                    .then((data, status, headers) => {
                        if (status === 'success') {
                            const fileName = fileUtils.getFileNameFromHeaders(
                                headers
                            );
                            fileUtils.downloadFile(data, fileName);

                            this.setState({ exportLoading: false });
                        } else {
                            this.exportFailed();
                        }
                    })
                    .fail(() => this.exportFailed());
                break;
            case exportConstants.type.allKitsWithHistory:
                exportService
                    .kitsWithHistory(
                        guid,
                        orderBy,
                        orderBySortingDirection,
                        selectedCountry,
                        selectedSite
                    )
                    .then((data, status, headers) => {
                        if (status === 'success') {
                            const fileName = fileUtils.getFileNameFromHeaders(
                                headers
                            );
                            fileUtils.downloadFile(data, fileName);

                            this.setState({ exportLoading: false });
                        } else {
                            this.exportFailed();
                        }
                    })
                    .fail(() => this.exportFailed());
                break;
            case exportConstants.type.singleKit:
                exportService
                    .kit(guid, kitGuid)
                    .then((data, status, headers) => {
                        if (status === 'success') {
                            const fileName = fileUtils.getFileNameFromHeaders(
                                headers
                            );
                            fileUtils.downloadFile(data, fileName);

                            this.setState({ exportLoading: false });
                        } else {
                            this.exportFailed();
                        }
                    })
                    .fail(() => this.exportFailed());
                break;
            default:
                throw new Error('Not implemented');
        }
    }

    exportFailed() {
        this.setState({
            exportErrorMessage: localization.getLocOrKey('couldNotExportAllKits'),
            exportLoading: false
        });
    }

    reloadMetricsAndKitList() {
        this.reload(this.kitListManagerComponent);
        this.reload(this.kitMetricsManagerComponent);
    }

    reload(componentRef) {
        if (componentRef && componentRef.current) {
            componentRef.current.reload();
        }
    }

    kitDetailPath = '/kit/:guid';
    kitListManagerPath = [
        '/kits/:filterParam',
        '/kits',
        '/kits/:countryCode/:siteCode',
        '/'
    ];

    renderComponents() {
        return (
            <React.Fragment>
                <Route
                    exact
                    path={this.kitDetailPath}
                    render={routeProps => (
                        <KitDetailsManager
                            {...routeProps}
                            {...this.state}
                            {...this.props}
                            onKitActionApplied={this.onKitActionApplied.bind(
                                this
                            )}
                        />
                    )}
                />
                <Route
                    exact
                    path={this.kitListManagerPath}
                    render={routeProps => (
                        <KitListManager
                            ref={this.kitListManagerComponent}
                            {...routeProps}
                            {...this.state}
                            {...this.props}
                            onListFilterStatusChange={this.onListFilterStatusChange.bind(
                                this
                            )}
                            onFilterSearchChange={this.onFilterSearchChange.bind(
                                this
                            )}
                            onFilterSearchTriggered={this.onFilterSearchTriggered.bind(
                                this
                            )}
                            onListOrderByChange={this.onListOrderByChange.bind(
                                this
                            )}
                            onPageSizeChange={this.onPageSizeChange.bind(this)}
                            onPageIndexChanged={this.onPageIndexChanged.bind(
                                this
                            )}
                            onKitActionApplied={this.onKitActionApplied.bind(
                                this
                            )}
                        />
                    )}
                />
            </React.Fragment>
        );
    }

    renderExportButton() {
        //If the user has navigated to the kit details page,
        //only allow export of that specific kit.
        var exportButton =
            this.props.match.path === this.kitDetailPath ? (
                <button
                    className="button-hollow"
                    onClick={() =>
                        this.onExport(exportConstants.type.singleKit)
                    }
                >
                    <Loader
                        loading={this.state.exportLoading}
                        type={loaderConstants.type.small}
                    >
                        {localization.getLocOrKey('export')}
                    </Loader>
                </button>
            ) : (
                    <BtnDropdown id={"kitExportButton"}
                        onClick={this.onExport.bind(this)}
                        items={[
                            {
                                id: exportConstants.type.allKits,
                                name: localization.getLocOrKey('allKits')
                            },
                            {
                                id: exportConstants.type.allKitsWithHistory,
                                name: localization.getLocOrKey('allKitsWithHistory')
                            }
                        ]}
                    >
                        <Loader
                            loading={this.state.exportLoading}
                            type={loaderConstants.type.small}
                        >
                            {localization.getLocOrKey('export')}
                        </Loader>
                    </BtnDropdown>
                );

        return (
            <div className="box-section no-borders margin-bottom-30 padding-0 step-navigation">
                {exportButton}
                {this.state.exportErrorMessage
                    ? this.state.exportErrorMessage
                    : null}
            </div>
        );
    }

    render() {
        let content =
            this.state.loading || this.state.listColumnsError ? (
                <div></div>
            ) : (
                    this.renderComponents()
                );

        return (
            <React.Fragment>
                <Error message={this.state.locationErrorMsg}>
                    <Loader loading={this.state.loading}
                        position={loaderConstants.position.horizontallyCenter}
                        type={loaderConstants.type.medium} >
                        <TopBar title={loc.stockListTitle} />
                        <KitMenuContainer
                            locations={this.state.locations}
                            selectedCountry={this.state.selectedCountry}
                            selectedSite={this.state.selectedSite}
                            onCountryChange={this.onCountryChange.bind(this)}
                            onSiteChange={this.onSiteChange.bind(this)}
                            studyPermissions={this.props.studyPermissions}
                            menuSettings={this.state.menuSettings}
                        />
                        <div className="content-container">
                            <KitMetricsManager
                                ref={this.kitMetricsManagerComponent}
                                initiatedBy={metricsConstants.initiatedBy.kitList}
                                {...this.props}
                                {...this.state}
                            />
                            <div className="box-section no-borders padding-0">
                                <div className="well">{content}</div>
                            </div>
                            {this.renderExportButton()}
                        </div>
                    </Loader>
                </Error>
            </React.Fragment>
        );
    }
}

KitManager.propTypes = {
    guid: PropTypes.string.isRequired
};
