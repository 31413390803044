import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../../../localization/localization';
import TextArea from '../../../shared/textarea';
import { lowerCaseFirstLetter } from '../../../../helpers/string.utils';

export default class KitActionReason extends React.Component {
  render() {

    const label = (
      <span>
        {loc.addReason}{' '}
        <span className="text-grey">{'(' + lowerCaseFirstLetter(loc.required) + ')'}</span>
      </span>
    );

    return (
      <React.Fragment>
        <div className="field-row items">
          <div className="field-item" style={{ width: '100%' }}>
            <TextArea
              label={label}
              value={this.props.value || ''}
              onChanged={this.props.onValueChanged}
              hasError={!this.props.isValid}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

KitActionReason.propTypes = {
  value: PropTypes.string,
  onValueChanged: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};
