import { systemVariables } from '../constants/constants';

export const setValuesFromCookie = (cookie) => {
    var isDev = systemVariables.env.dev;
    var jwtToken = getValueFromCookie(cookie, systemVariables.token.name);
    var language = getValueFromCookie(cookie, systemVariables.language.culture);

    if(jwtToken || isDev === false)
        setItemInStorage(systemVariables.token.name, jwtToken);
    if(language || isDev === false)
        setItemInStorage(systemVariables.language.culture, language);
};

export const getToken = () => {
    if (systemVariables.env.dev) {
        return localStorage.getItem(systemVariables.token.name);
    } else {
        return sessionStorage.getItem(systemVariables.token.name);
    }
};

export const getLanguageCode = () => {
    return getItemFromStorage(systemVariables.language.culture);
};

function getItemFromStorage(key){
    if (systemVariables.env.dev) {
        return localStorage.getItem(key);
    } else {
        return sessionStorage.getItem(key);
    }
}

function setItemInStorage(key, value){
    if (systemVariables.env.dev) {
        //no need to through viedoc to debug (so that the token is used from the previous session)
        localStorage.setItem(key, value);
    } else {
        sessionStorage.setItem(key, value);
    }
}

function getValueFromCookie(cookie, key){
    var value = "; " + cookie;
    var cookieValue;

    var parts = value.split("; " + key + "=");
    if (parts.length === 2)
        cookieValue = parts.pop().split(";").shift();

    return cookieValue;
}