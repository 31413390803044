import React from 'react';
import { loc } from '../../../../localization/localization';

export default class KitNoAction extends React.Component {
  render() {
    return (
      <div className="text-center padding-top-5">
        <i className="icon info-grey-round-large"></i>
        <div className="padding-top-20">{loc.noActionsAvailable}</div>
      </div>
    );
  }
}
