import React from "react";

export default class KitMenuItemContact extends React.Component {
    render() {
        return (
            <a href="#" title="Phone">
                <i className="icon phone-book-orange-large" />
            </a>
        );
    }
}