import React from 'react';
import PropTypes from 'prop-types';
import { kitCssConstants } from '../../../../constants/constants';
import { getKitStatus } from '../../../../helpers/kit.utils';
import StatusIcon from '../../shared/status-icon';
import { Link } from "react-router-dom";
import KitLocation from '../../shared/kit-location';
import KitStatus from '../../shared/kit-status';
import Checkbox from '../../../shared/checkbox';
import ExpiryDate from '../../shared/expiry-date';


export default class KitListRow extends React.Component {

    render() {
        const { kit, selectedKits, selectable, isSomeKitsSelectable } = this.props;

        const status = getKitStatus(kit);
        let kitIsSelected = selectedKits.filter(selectedKit => selectedKit.kitGuid === kit.kitGuid).length > 0;

        return (
            <tr className={kit.isMissing ? 'in-transfer-out' : ''}>
                <td>{kit.seqNo}</td>
                <td>
                    <strong>
                        <Link title={kit.kitNo} to={`/kit/${kit.kitGuid}`}>{kit.kitNo}</Link>
                    </strong>
                </td>
                <td>{kit.item}</td>
                <td>
                    <StatusIcon size={kitCssConstants.size.small} status={status} /><strong><KitStatus status={status} /></strong>
                </td>
                <td>
                    <KitLocation location={kit.location} isMissing={kit.isMissing} />
                </td>
                <td>
                    <ExpiryDate value={kit.expiryDate} isExpired={kit.isExpired} />
                </td>
                {isSomeKitsSelectable
                    ?
                    <td>
                        {selectable
                            ?
                            <Checkbox onChange={() => this.props.onKitSelected(kit)}
                                classNames={['smaller']}
                                value={kit.kitNo}
                                isChecked={kitIsSelected}
                            />
                            : null}
                    </td>
                    : null}

            </tr>
        );
    }
}

KitListRow.propTypes = {
    kit: PropTypes.object.isRequired,
    selectedKits: PropTypes.array.isRequired,
    onKitSelected: PropTypes.func.isRequired,
    selectable: PropTypes.bool.isRequired,
    isSomeKitsSelectable: PropTypes.bool.isRequired
};