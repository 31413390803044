import React from 'react';
import PropTypes from 'prop-types';

export default class ModalBody extends React.Component {
    render() {
        const { heading } = this.props;
        return (
            <section className="content-area">
                <header>
                    <h2>{heading}</h2>
                </header>
                {this.props.children}
            </section>
        );
    }
}

ModalBody.propTypes = {
    heading: PropTypes.string.isRequired
};