import baseService from './base.service';

const url = baseService.baseUrl + "/alertsettings";

function get(randGuid) {
    let requestUrl = getRequestUrl(randGuid);
    return baseService.get(requestUrl);
}

function put(randGuid, obj) {
    let requestUrl = getRequestUrl(randGuid);
    return baseService.put(requestUrl, obj);
}

function getLocationAlerts(randGuid, locationGuid) {
    let requestUrl = getRequestUrl(randGuid, locationGuid);
    return baseService.get(requestUrl);
}

function saveLocationAlerts(randGuid, locationGuid, obj) {
    let requestUrl = getRequestUrl(randGuid, locationGuid);
    return baseService.post(requestUrl, obj);
}

function updateLocationAlerts(randGuid, locationGuid, obj) {
    let requestUrl = getRequestUrl(randGuid, locationGuid);
    return baseService.put(requestUrl, obj);
}

function getRequestUrl(randGuid, locationGuid) {
    return locationGuid
        ? `${url}/${randGuid}/${locationGuid}`
        : `${url}/${randGuid}`;
}

var service = {
    get: get,
    put: put,
    getLocationAlerts: getLocationAlerts,
    saveLocationAlerts: saveLocationAlerts,
    updateLocationAlerts: updateLocationAlerts,
};

export default service;