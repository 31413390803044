import React from "react";
import PropTypes from 'prop-types';

const DescriptionTab = (props) => {

    const { active, text, id, valid, onClick } = props;

    const renderExclamation = () => {
        return <i className="icon exclamation-red-xxs"></i>;
    };

    const clickTab = () => {
        if(onClick){
            onClick(id);
        }
    };

    return (
        <dd onClick={() => clickTab()} className={active ? 'active' : ''}>
            <a>
                {text}
                {valid ? null : renderExclamation()}
            </a>
        </dd>
    );

};

export default DescriptionTab;

DescriptionTab.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    active: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    onClick: PropTypes.func
};