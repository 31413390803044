import { locationConstans } from '../constants/constants';
import localization from '../localization/localization';

export const localizeAlertLevel = (alertSettingsConstants, type) => {
    switch (type) {
        case alertSettingsConstants.type.notification:
            return localization.getLocOrKey('enableNotificationForUnitsOrSite');
        case alertSettingsConstants.type.warning:
            return localization.getLocOrKey('enableWarningForUnitsOrSite');
        default:
            throw new Error('Not implemented');
    }
};

export const localizeSiteNames = locations => {
    locations.forEach(loc => {
        loc.location = getLocalizedSiteName(loc.location, loc.type, loc.countryCode);
    });

    return locations;
};

export const localizeSiteNamesForKits = kits => {
    kits.forEach(kit => {
        kit.location.name = getLocalizedSiteName(kit.location.name, kit.location.type, kit.location.countryCode);
    });

    return kits;
};

export const getLocalizedSiteName = (locationName, locationType, countryCode) => {
    switch (locationType) {
        case locationConstans.type.centralDepot:
            return localization.getLocOrKey('centralDepot');
        case locationConstans.type.depot:
            return (
                localization.getLocOrKey('countryDepot') +
                ' (' +
                countryCode +
                ')'
            );
        default:
            return locationName;
    }
};
