import React from 'react';
import { overviewConstants, kitCssConstants } from '../../../../constants/constants';
import { loc } from '../../../../localization/localization';
import PropTypes from 'prop-types';

export default class ListOverviewHeaderNode extends React.Component {

    render() {
        var columnName = this.translateColumn(this.props.column);
        return (
            <th>
                {columnName}
            </th>
        );
    }

    translateColumn(column) {
        let columnTypes = overviewConstants.columnTypes;

        switch (column.type) {
            case columnTypes.notSpecified:
                return <strong>#</strong>;
            case columnTypes.location:
                return <strong> {loc.site} </strong>;
            case columnTypes.item:
                return <strong>{loc.kitType}</strong>;
            case columnTypes.valid:
                return <strong>{loc.validPlural}</strong>;
            case columnTypes.allocated:
                return <i className={`icon ${kitCssConstants.status[column.type]}-${kitCssConstants.size.small}`} title={loc.allocatedPlural}></i>;
            case columnTypes.returned:
                return <i className={`icon ${kitCssConstants.status[column.type]}-${kitCssConstants.size.small}`} title={loc.returnedPlural}></i>;
            case columnTypes.quarantined:
                return <i className={`icon ${kitCssConstants.status[column.type]}-${kitCssConstants.size.small}`} title={loc.quarantinedPlural}></i>;
            case columnTypes.expired:
                return <i className={`icon ${kitCssConstants.status[column.type]}-${kitCssConstants.size.small}`} title={loc.expiredPlural}></i>;
            case columnTypes.invalid:
                return <i className={`icon ${kitCssConstants.status[column.type]}-${kitCssConstants.size.small}`} title={loc.invalidPlural}></i>;
            case columnTypes.total:
                return <strong>{loc.kitsInTotal}</strong>;
            default:
                return "NotTranslated";
        }
    };
}

ListOverviewHeaderNode.propTypes = {
    column: PropTypes.object.isRequired,
};