import React from 'react';
import PropTypes from 'prop-types';

const ExpiryDate = props => {
    const { isExpired, value } = props;
    return (
        isExpired ? <strong className="text-burgundy">{value}</strong> : <strong>{value}</strong>
    );
};

ExpiryDate.propTypes = {
    value: PropTypes.string,
    isExpired: PropTypes.bool.isRequired
};

export default ExpiryDate;
