import modelValidatorCreator from '../../../validators/model-validator-creator';
import { kitActionConstants } from '../../../constants/constants';

const InvalidateModel = modelValidatorCreator.createModelWithValidation({
    reason: {
        value: null,
        required: true
    },
    note: {
        value: null,
        conditionPropertyName: 'reason',
        conditionValue: kitActionConstants.invalidateReason.other
    }
});

export default InvalidateModel;
