import React from 'react';
import tipAnimationBackup from '../../../../../../static/images/tip_animation_backup.jpg';

export default class AnimatedModalAnimation extends React.Component {
    render() {
        return (
            <div className="popup-animation-content">
                <div className="bg-white align-center overflow-hidden" style={{ maxWidth: 400 }}>
                    <div id="animation_container" style={{ backgroundColor: 'rgba(255, 255, 255, 1.00)', width: 400, height: 130 }}>
                        <canvas id="canvas" style={{ position: 'absolute', display: 'block', backgroundColor: 'rgb(255, 255, 255)', width: 400, height: 130 }}></canvas>
                        <div id="dom_overlay_container" style={{ pointerEvents: 'none', overflow: 'hidden', width: 400, height: 130, position: 'absolute', left: 0, top: 0, display: 'block' }}></div>
                    </div>
                    <div className="animation-backup-img"><img src={tipAnimationBackup} /></div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

