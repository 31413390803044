import React from "react";

const ActionLink = (props) => {
    const { onClick, ...rest } = props;
    const clickHandler = (e) => {
        e.preventDefault();
        onClick();
    };
    return <a href="" {...rest} onClick={clickHandler}>{props.children}</a>;
};

export default ActionLink;