import React from "react";
import PropTypes from 'prop-types';

const Checkbox = (props) => {
    const toggle = () => {
        const { onChange, isChecked, disabled } = props;
        if (disabled) return;

        onChange(!isChecked);
    };

    const { value, isChecked, disabled, labelText, classNames } = props;

    return (
        <label className={`check-item ${classNames ? classNames.join(' ') : ''}`}>
            <input
                type="checkbox"
                className="check-btn hidden"
                readOnly
                value={value}
                checked={isChecked}
                disabled={disabled}
            />
            <div className={"checkboxArea"} onClick={toggle} />
            <span className="label-text" onClick={toggle}>{labelText}</span>
        </label>
    );

};

export default Checkbox;

Checkbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    value: PropTypes.node,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    labelText: PropTypes.string,
    classNames: PropTypes.array,
};