import React from 'react';
import PropTypes from 'prop-types';

export default class TextArea extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="ttl-box">{this.props.label}</div>
                <div className={`text-area${this.props.hasError ? ' hasError' : ''}`}>
                    <textarea style={{ height: "64px" }} value={this.props.value} onChange={e => this.props.onChanged(e.target.value)} />
                </div>
            </React.Fragment>
        );
    }
}

TextArea.propTypes = {
    label: PropTypes.node,
    value: PropTypes.string,
    hasError: PropTypes.bool,
    onChanged: PropTypes.func.isRequired,
};

