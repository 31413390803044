import modelValidator from '../../../../validators/model-validator';

export const isLevelValid = (notification) => {
    const isValid = modelValidator.isPropertyValid(notification.validation, 'level', notification.level, notification.enabled);
    return isValid;
};

export const getErrorMessagesForLevel = (notification) => {
    const messages = modelValidator.getErrorMessages(notification.validation, 'level');
    return messages;
};

export const getErrorMessagesForIgnoreLevel = (notification) => {
    const messages = modelValidator.getErrorMessages(notification.validation, 'ignoreLevel');
    return messages;
};

export const isIgnoreLevelValid = (notification) => {
    const isValid = modelValidator.isPropertyValid(notification.validation, 'ignoreLevel', notification.ignoreLevel, notification.enabledIgnore);
    return isValid;
};