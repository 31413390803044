import React from "react";

export default class TopBar extends React.Component {
    render() {
        return (
            <header>
                <h2 className="h4 padding-top-20 padding-bottom-18">
                    <i className="icon car-blue-faded-right-large margin-top-minus-2 margin-right-10"></i>{this.props.title}
                </h2>
            </header>
        );
    }
}