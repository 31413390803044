import React from 'react';
import PropTypes from 'prop-types';
import KitListRow from './row/kit-list-row';
import { loc } from '../../../localization/localization';
import { isKitSelectable, isSomeKitsSelectable } from '../../../helpers/kit.utils';


export default class KitList extends React.Component {

    renderNoKitsFound() {
        return (
            <tr>
                <td colSpan="7">
                    <div className="text-center padding-top-45 padding-bottom-45">
                        <i className="icon info-grey-round-large"></i>
                        <div className="padding-top-20">{loc.noItemsFound}.</div>
                    </div>
                </td>
            </tr>
        );
    }

    renderKits(kits) {
        return (
            <React.Fragment>
                {kits.map(kit =>
                    <KitListRow
                        key={kit.kitGuid}
                        kit={kit}
                        locations={this.props.locations}
                        selectedKits={this.props.selectedKits}
                        onKitSelected={this.props.onKitSelected}
                        selectable={isKitSelectable(kit, this.props.selectableKits)}
                        isSomeKitsSelectable={isSomeKitsSelectable(this.props.kits, this.props.selectableKits)} />
                )}
            </React.Fragment>
        );
    }

    render() {
        let kits = this.props.kits;

        let content = kits.length > 0
            ? this.renderKits(kits)
            : this.renderNoKitsFound();

        return (
            <tbody>
                {content}
            </tbody>
        );
    }
}

KitList.propTypes = {
    kits: PropTypes.array.isRequired,
    selectedKits: PropTypes.array.isRequired,
    selectableKits: PropTypes.array.isRequired,
    onKitSelected: PropTypes.func.isRequired,
    locations: PropTypes.array.isRequired
};