import baseService from './base.service';

const url = baseService.baseUrl + "/location";

const getForStockList = () => {
    return baseService.get(url + '/stocklist');
};

const getForOverview = () => {
    return baseService.get(url + '/overview');
};

var service = {
    getForStockList: getForStockList,
    getForOverview: getForOverview
};

export default service;