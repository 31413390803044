import React from 'react';
import PropTypes from 'prop-types';
import KitActionNote from './kit-action-note';

export default class KitActionCancel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: null
    };
  }

  componentDidMount() {
    this.onActionUpdated();
  }

  onNoteTextChanged(value) {
    this.setState({ note: value }, this.onActionUpdated);
  }

  onActionUpdated() {
    this.props.onActionUpdated({ ...this.state });
  }

  render() {
    return <KitActionNote
      noteText={this.state.note}
      onNoteTextChanged={this.onNoteTextChanged.bind(this)}
    />;
  }
}

KitActionCancel.propTypes = {
  onActionUpdated: PropTypes.func.isRequired
};