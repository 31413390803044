export const loaderConstants = {
    type: {
        small: 'small',
        medium: 'medium',
        large: 'large'
    },
    position: {
        horizontallyCenter: 'horizontallyCenter'
    }
};

export const kitActionConstants = {
    invalidateReason: {
        missing: 'missing',
        broken: 'broken',
        other: 'other'
    },
    actions: {
        transfer: 'transfer',
        invalidate: 'invalidate',
        return: 'return',
        cancel: 'cancel',
        receive: 'receive',
        quarantine: 'quarantine',
        restore: 'restore',
        editExpiryDate: 'editExpiryDate'
    }
};

export const settingsConstants = {
    type: {
        general: 'general',
        alert: 'alert'
    }
};

export const generalSettingsConstants = {
    kitIcon: {
        pill: 'pill',
        device: 'device'
    }
};

export const alertSettingsCssConstants = {
    storageIndicator: {
        notification: 'storage-indicator-medium-orange',
        warning: 'storage-indicator-low-red'
    },
    rowColor: {
        notification: 'bg-orange',
        warning: 'bg-red-light'
    }
};

export const alertSettingsConstants = {
    type: {
        warning: 'warning',
        notification: 'notification'
    }
};

export const alertConstants = {
    type: {
        warning: 'warning',
        notification: 'notification',
        notSet: 'notSet'
    }
};

export const kitListConstants = {
    order: {
        asc: 'asc',
        desc: 'desc',
        none: 'none'
    },
    columnTypes: {
        notSpecified: 'notSpecified',
        kitNumber: 'kitNumber',
        kitType: 'kitType',
        status: 'status',
        location: 'location',
        expiryDate: 'expiryDate'
    }
};

export const metricsConstants = {
    initiatedBy: {
        overview: 'overview',
        kitList: 'kitList'
    }
};

export const kitConstants = {
    filter: {
        kitsInTransfer: 'kitsInTransfer',
        valid: 'valid',
        allocated: 'allocated',
        invalid: 'invalid',
        returned: 'returned',
        quarantined: 'quarantined',
        expired: 'expired'
    },
    status: {
        valid: 'valid',
        allocated: 'allocated',
        invalid: 'invalid',
        returned: 'returned',
        quarantined: 'quarantined',
        expired: 'expired'
    },
    locationStatus: {
        inTransit: 'inTransit'
    },
    transferDirection: {
        from: 'from',
        to: 'to'
    },
    history: {
        type: {
            created: 'created',
            valid: 'valid',
            transferred: 'transferred',
            kitReceived: 'kitReceived',
            kitAllocated: 'kitAllocated',
            kitAvailable: 'kitAvailable',
            kitCreated: 'kitCreated',
            transferCanceled: 'transferCanceled',
            kitInvalidated: 'kitInvalidated',
            kitReturned: 'kitReturned',
            undoAllocation: 'undoAllocation',
            replaceAllocation: 'replaceAllocation',
            quarantined: 'quarantined',
            restored: 'restored',
            expired: 'expired',
            editExpiryDate: 'editExpiryDate',
            validAfterExpiration: 'validAfterExpiration',
            expiredWhileUpload: 'expiredWhileUpload'
        }
    },
    kit: {
        kitGuid: 'kitGuid'
    },
    auditRecordConstants: {
        user: { system: 'System' }
    }
};

export const locationConstans = {
    type: {
        site: 'site',
        depot: 'depot',
        centralDepot: 'centralDepot'
    },
    permissions: {
        read: 'read',
        edit: 'edit'
    }
};

export const overviewConstants = {
    columnTypes: {
        notSpecified: 'notSpecified',
        location: 'location',
        item: 'item',
        valid: 'valid',
        allocated: 'allocated',
        returned: 'returned',
        invalid: 'invalid',
        total: 'total',
        quarantined: 'quarantined',
        expired: 'expired'
    }
};

export const kitCssConstants = {
    status: {
        valid: 'pill-green',
        allocated: 'person-blue',
        invalid: 'invalid-red',
        returned: 'arrow-black-left',
        quarantined: 'quarantined-orange',
        expired: 'expired-red',
        total: 'pill-black'
    },
    size: {
        small: 'small',
        large: 'large'
    }
};

export const exportConstants = {
    type: {
        allKits: 'allKits',
        allKitsWithHistory: 'allKitsWithHistory',
        singleKit: 'singleKit'
    }
};

export const systemVariables = {
    env: {
        dev: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    },
    token: {
        name: 'jwtToken'
    },
    language: {
        culture: 'culture'
    }
};

export const regexConstants = {
    notEmptyOrWhitespace: '([^\\s])'
};
