import React from 'react';
import PropTypes from 'prop-types';
import Paging from '../../../shared/paging';
import PageSize from '../../../shared/page-sizing';

export default class ListOverviewFooter extends React.Component {
  render() {
    return (
      <tfoot>
        <tr>
          <td colSpan="6">
            <Paging
              itemsFetchedCount={this.props.locationsFetchedCount}
              pageIndex={this.props.pageIndex}
              pageSize={this.props.pageSize}
              onPageIndexChanged={this.props.onPageIndexChanged}
              itemsTotalCount={this.props.locationsTotalCount}
            />
          </td>
          <td colSpan="5" className="text-right">
            <PageSize
              pageSize={this.props.pageSize}
              onPageSizeChange={this.props.onPageSizeChanged}
              pageSizes={[10, 20, 50, 100]}
            />
          </td>
        </tr>
      </tfoot>
    );
  }
}

ListOverviewFooter.propTypes = {
  locationsTotalCount: PropTypes.number.isRequired,
  locationsFetchedCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
};
