import React from "react";
import PropTypes from 'prop-types';
import localization from '../../../localization/localization';
import Checkbox from '../../shared/checkbox';
import SettingsAlertRow from './settings-alert-row';


export default class SettingsAlertContainer extends React.Component {

    renderEmailAlerts() {
        const { enabledEmailAlertOnWarnings,
            enabledReadOnlyEmailAlertOnWarnings,
            enabledEmailAlertOnNotifications,
            enabledReadOnlyEmailAlertOnNotifications,
            onEnabledEmailAlertOnWarningsChanged,
            onEnabledReadOnlyEmailAlertOnWarningsChanged,
            onEnabledEmailAlertOnNotificationsChanged,
            onEnabledReadOnlyEmailAlertOnNotificationsChanged,
            readOnly } = this.props;

        //don't render checkboxes if no props for them were passed
        //intented use of == because undefined == null
        if (enabledEmailAlertOnWarnings === null
            || enabledReadOnlyEmailAlertOnWarnings === null
            || enabledEmailAlertOnNotifications === null
            || enabledReadOnlyEmailAlertOnNotifications === null
            || onEnabledEmailAlertOnWarningsChanged === null
            || onEnabledReadOnlyEmailAlertOnWarningsChanged === null
            || onEnabledEmailAlertOnNotificationsChanged === null
            || onEnabledReadOnlyEmailAlertOnNotificationsChanged === null) {
            return null;
        }

        return (
            <div className="box-section margin-top-0 with-divider" style={{ margin: 0 }}>
                <div className="field-row padding-bottom-0">
                    <Checkbox onChange={this.props.onEnabledEmailAlertOnNotificationsChanged}
                        isChecked={enabledEmailAlertOnNotifications}
                        labelText={localization.getLocOrKey('emailStudySupplyManagerOnNotification')}
                        disabled={readOnly} />
                </div>
                <div className="field-row padding-bottom-0">
                    <Checkbox onChange={this.props.onEnabledReadOnlyEmailAlertOnNotificationsChanged}
                        isChecked={enabledReadOnlyEmailAlertOnNotifications}
                        labelText={localization.getLocOrKey('emailReadOnlyStudySupplyManagerOnNotification')}
                        disabled={readOnly} />
                </div>
                <div className="field-row padding-bottom-0">
                    <Checkbox onChange={this.props.onEnabledEmailAlertOnWarningsChanged}
                        isChecked={enabledEmailAlertOnWarnings}
                        labelText={localization.getLocOrKey('emailStudySupplyManagerOnWarning')}
                        disabled={readOnly} />
                </div>
                <div className="field-row">
                    <Checkbox onChange={this.props.onEnabledReadOnlyEmailAlertOnWarningsChanged}
                        isChecked={enabledReadOnlyEmailAlertOnWarnings}
                        labelText={localization.getLocOrKey('emailReadOnlyStudySupplyManagerOnWarning')}
                        disabled={readOnly} />
                </div>
            </div>
        );
    }
    render() {
        const { settings, readOnly } = this.props;

        return (
            <React.Fragment>
                {settings.map(setting =>
                    <div key={setting.kitTypeCode}>
                        <SettingsAlertRow key={setting.kitTypeCode} setting={setting}
                            onLevelChanged={this.props.onLevelChanged}
                            onIgnoreLevelChanged={this.props.onIgnoreLevelChanged}
                            onLevelEnabledChanged={this.props.onLevelEnabledChanged}
                            onIgnoreLevelEnabledChanged={this.props.onIgnoreLevelEnabledChanged}
                            hideIgnoreOptions={this.props.hideIgnoreOptions}
                            readOnly={readOnly}>
                        </SettingsAlertRow>
                    </div>
                )}
                {this.renderEmailAlerts()}
            </React.Fragment>
        );
    }
}

SettingsAlertContainer.propTypes = {
    settings: PropTypes.array.isRequired,
    onLevelChanged: PropTypes.func.isRequired,
    onIgnoreLevelChanged: PropTypes.func.isRequired,
    onLevelEnabledChanged: PropTypes.func.isRequired,
    onIgnoreLevelEnabledChanged: PropTypes.func.isRequired,
    hideIgnoreOptions: PropTypes.bool,
    enabledEmailAlertOnWarnings: PropTypes.bool,
    enabledReadOnlyEmailAlertOnWarnings: PropTypes.bool,
    enabledEmailAlertOnNotifications: PropTypes.bool,
    enabledReadOnlyEmailAlertOnNotifications: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    onEnabledEmailAlertOnWarningsChanged: PropTypes.func,
    onEnabledReadOnlyEmailAlertOnWarningsChanged: PropTypes.func,
    onEnabledEmailAlertOnNotificationsChanged: PropTypes.func,
    onEnabledReadOnlyEmailAlertOnNotificationsChanged: PropTypes.func
};