import baseService from './base.service';

const url = baseService.baseUrl + "/generalsettings";

function get(randGuid){
    let requestUrl = getRequestUrl(randGuid);
    return baseService.get(requestUrl);
}

function put(randGuid, obj){
    let requestUrl = getRequestUrl(randGuid);
    return baseService.put(requestUrl, obj);
}

function getRequestUrl(randGuid){
    return `${url}/${randGuid}`;
}

var service = {
    get: get,
    put: put
};

export default service;