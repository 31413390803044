import React from 'react';
import PropTypes from 'prop-types';
import { kitConstants } from '../../../../constants/constants';
import { getPropertyValues } from '../../../../helpers/object.utils';
import { loc } from '../../../../localization/localization';
import { getOptionsAsLocs } from '../../../helpers/select2.utils';
import Select2 from '../../../../helpers/react-select2';

const filterConstants = getPropertyValues(kitConstants, 'filter');

export default class KitListStatusFilter extends React.Component {

    render() {
        const statusFilterLocs = getOptionsAsLocs(filterConstants);

        return (
            <Select2 id={'kit-list-filter-select'} value={this.props.status} onChange={(event) => this.props.onStatusChange(event.target.value)}>
                <option value="">{loc.allKits}</option>
                {statusFilterLocs.map(_ => <option key={_.id} value={_.id}>{_.name}</option>)}
            </Select2>
        );
    }
}

KitListStatusFilter.propTypes = {
    onStatusChange: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired
};