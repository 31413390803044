import modelValidatorCreator from '../../../validators/model-validator-creator';

const editExpiryDateModel = modelValidatorCreator.createModelWithValidation({
    date: {
        value: null,
        required: true
    },
    reason: {
        value: null,
        required: true
    }
});

export default editExpiryDateModel;
