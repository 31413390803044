import React from "react";
import PropTypes from 'prop-types';

const $ = window.$;

function select2CopyClasses(data, container) {
    if (data.element) {
        $(container).addClass($(data.element).attr("class"));
    }
    return data.text;
}

// For this to work jQuery and chosen must be included globally!
class Select2 extends React.Component {

    componentDidMount() {
        const select = $(this.refs.select);

        const { chosenProps } = this.props;
        const defaults = {
            templateResult: select2CopyClasses,
            minimumResultsForSearch: Infinity
        };
        const settings = $.extend({}, defaults, chosenProps);

        $(select).select2(settings)
            .on("change", this.handleChange.bind(this));

        //Triggers onChange to show selected value
        if (this.props.value) {
            $(select).val(this.props.value).trigger('change');
        }
    }

    componentDidUpdate() {
        const select = $(this.refs.select)[0];
        var value = this.props.defaultValue === undefined ? this.props.value : this.props.defaultValue;

        //if selected value was changed by other means than the controller, trigger onchange to show selected value
        //If the select only contain one children, dont bother update, it should not trigger changes.
        if (select.value !== value && select.children.length > 1) {
            $(select).val(value).trigger('change');
        }
    }

    componentWillUnmount() {
        $(this.refs.select).off("change");
    }

    handleChange(a, b, c) {
        this.props.onChange && this.props.onChange(a, b, c);
    }

    render() {
        const selectProps = $.extend({}, this.props.selectProps, { ref: "select" });
        return (
            <div>
                <select id={this.props.id} {...selectProps}>{this.props.children}</select>
            </div>
        );
    }
}

export default Select2;

Select2.propTypes = {
    id: PropTypes.string.isRequired,
};