import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { loc } from '../../localization/localization';
import { canAccessStudySupplyOverView } from '../../permissions/user-permissions';

export default class KitRouteToOverview extends React.Component {
  render() {
    const { studyPermissions } = this.props;

    return canAccessStudySupplyOverView(studyPermissions) ? (
      <dd className="home">
        <Link title={loc.backToSupplyOverview} to={`/`}>
          <i className="icon car-grey-right-large"></i>
        </Link>
        <a href="#" title=""></a>
      </dd>
    ) : (
        ''
      );
  }
}

KitRouteToOverview.propTypes = {
  studyPermissions: PropTypes.object,
};
