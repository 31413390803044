import React from 'react';
import { loc } from '../../../localization/localization';
import PropTypes from 'prop-types';
import ListOverviewSiteRow from './row/list-overview-site-row';

export default class ListOverview extends React.Component {

    renderNoItemsFound() {
        return (
            <tr>
                <td colSpan="9">
                    <div className="text-center padding-top-45 padding-bottom-45">
                        <i className="icon info-grey-round-large"></i>
                        <div className="padding-top-20">{loc.noItemsFound}.</div>
                    </div>
                </td>
            </tr>
        );
    }

    renderItems(metrics) {
        return (
            <React.Fragment>
                {metrics.map((item, index) =>
                    <ListOverviewSiteRow
                        key={item.location.locationGuid}
                        item={item}
                        onOpenLocationSettingsModal={this.props.onOpenLocationSettingsModal} />
                )}
            </React.Fragment>
        );
    }

    render() {
        const metrics = this.props.metrics;

        let content = metrics.length > 0
            ? this.renderItems(metrics)
            : this.renderNoItemsFound();

        return (
            <tbody>
                {content}
            </tbody>
        );
    }
}

ListOverview.propTypes = {
    metrics: PropTypes.array.isRequired,
    onOpenLocationSettingsModal: PropTypes.func.isRequired,
};