import React from "react";
import PropTypes from 'prop-types';
import SettingsAlertNotificationRow from "./settings-alert-notification-row";

export default class SettingsAlertRow extends React.Component {
    render() {
        const { setting, readOnly } = this.props;
        const notifications = setting.notifications;

        return (
            <React.Fragment>
                <div className="field-row padding-bottom-0">
                    <div className="field-item" style={{ width: 200 }}>
                        <div className="text-field hasReadOnly">
                            <input type="text" readOnly value={setting.kitTypeDisplayName} className="strong" />
                        </div>
                    </div>
                </div>
                {notifications.map((notification, index) =>
                    <div key={notification.id} className={`field-row  ${index === 0 ? 'padding-bottom-0' : ''}`}>
                        <SettingsAlertNotificationRow notification={notification}
                            onLevelChanged={this.props.onLevelChanged}
                            onIgnoreLevelChanged={this.props.onIgnoreLevelChanged}
                            onLevelEnabledChanged={this.props.onLevelEnabledChanged}
                            onIgnoreLevelEnabledChanged={this.props.onIgnoreLevelEnabledChanged}
                            hideIgnoreOptions={this.props.hideIgnoreOptions}
                            readOnly={readOnly}>
                        </SettingsAlertNotificationRow>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

SettingsAlertRow.propTypes = {
    setting: PropTypes.shape({
        kitTypeName: PropTypes.string.isRequired,
        notifications: PropTypes.array.isRequired
    }).isRequired,
    onLevelChanged: PropTypes.func.isRequired,
    onIgnoreLevelChanged: PropTypes.func.isRequired,
    onLevelEnabledChanged: PropTypes.func.isRequired,
    onIgnoreLevelEnabledChanged: PropTypes.func.isRequired,
    hideIgnoreOptions: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired
};