import $ from "jquery";
import { getToken } from '../helpers/storage.utils';

const baseUrl = '/api';

function setHeaders(){
    $.ajaxSetup({
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    });
}

function post(url, data) {
    setHeaders();
    return $.ajax({
        type: "POST",
        url: url,
        contentType: "application/json",
        data: JSON.stringify(data)
    });
}

function put(url, data) {
    setHeaders();
    return $.ajax({
        type: "PUT",
        url: url,
        contentType: "application/json",
        data: JSON.stringify(data)
    });
}

function postWithParameters(url, obj) {
    setHeaders();
    return $.post(url, obj);
}

function get(url, cache = false) {
    setHeaders();

    return $.ajax({
        url: url,
        type: 'GET',
        cache: cache,
    });
}

function getWithParameters(url, obj, cache = false) {
    setHeaders();
    return $.ajax({
        url: url,
        type: 'GET',
        cache: cache,
        data: obj
    });
}

function getWithModel(url, model) {
    setHeaders();
    return post(url, model);
}

function getFile(url, data){
    setHeaders();
    return $.ajax({
        url: url,
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
        data: data
    });
}

var BaseService = {
    baseUrl: baseUrl,
    get: get,
    post: post,
    put: put,
    postWithParameters: postWithParameters,
    getWithModel: getWithModel,
    getWithParameters: getWithParameters,
    getFile: getFile
};

export default BaseService;