import React from 'react';


export default class Layout extends React.Component {
    render() {
        return (
            <div className="content-wrapper min-width-1024">
                <div className="inner-wrapper">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
