import React from 'react';
import PropTypes from 'prop-types';

export default class ModalError extends React.Component {

    renderErrors(errors) {
        return (
            <ul className="errors">
                {errors.map((error, index) =>
                    <li key={index}>
                        <a href="#">
                            <b className="icon exclamation-red"></b>
                            <span className="text-red">{error}</span>
                        </a>
                    </li>
                )}
            </ul>
        );
    }

    render() {
        const { errors } = this.props;

        const content = errors && errors.length > 0 ? this.renderErrors(errors) : null;
        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

ModalError.propTypes = {
    errors: PropTypes.array.isRequired
};