import React from 'react';
import PropTypes from 'prop-types';
import { loaderConstants } from '../../../constants/constants';
import { getKitStatus } from '../../../helpers/kit.utils';
import { Link } from "react-router-dom";
import StatusIcon from '../shared/status-icon';
import KitStatus from "../shared/kit-status";
import KitLocation from "../shared/kit-location";
import KitDetailsHistory from './kit-details-history';
import Loader from '../../shared/loader';
import { loc } from '../../../localization/localization';
import ExpiryDate from '../shared/expiry-date';

export default class KitDetailsContainer extends React.Component {

    renderHistory() {
        return (
            <div>
                <KitDetailsHistory events={this.props.history} />
            </div>
        );
    }

    onKitActionSuccess() {
        this.loadKit();
    }

    renderView() {
        const kit = this.props.kit;
        const status = getKitStatus(kit);

        let contents = this.props.historyLoading
            ? <div></div>
            : this.renderHistory();

        let kitItem = kit.item
            ? <React.Fragment>
                <span className="padding-left-right-15 text-grey-faded-3">{'//'}</span>
                {kit.item}
            </React.Fragment>
            : '';

        return (
            <React.Fragment>
                <div className="display-table table-fixed margin-bottom-20">
                    <div className="table-cell width-40">
                        <StatusIcon size="large" status={status} />
                    </div>
                    <div className="table-cell">
                        <h4 className="large">
                            <strong>{kit.kitNo}</strong>
                            {kitItem}
                        </h4>
                    </div>
                </div>
                <div className="padding-left-40">
                    <div className="display-table valign-top table-auto width-auto margin-bottom-30">
                        <div className="table-cell">
                            <small className="text-grey">
                                <strong className="uppercase">{loc.status}</strong>
                            </small>
                            <h5 className="padding-top-5">
                                <strong><KitStatus status={status} /></strong>
                            </h5>
                        </div>
                        <div className="table-cell padding-left-40">
                            <small className="text-grey">
                                <strong className="uppercase">{loc.location}</strong>
                            </small>
                            <h5 className="padding-top-5">
                                <strong><KitLocation location={kit.location} isMissing={kit.isMissing} /></strong>
                            </h5>
                        </div>
                        <div className="table-cell padding-left-40">
                            <small className="text-grey">
                                <strong className="uppercase">{loc.expiryDate}</strong>
                            </small>
                            <h5 className="padding-top-5">
                                <ExpiryDate value={kit.expiryDate} isExpired={kit.isExpired} />
                            </h5>
                        </div>
                    </div>
                    <small className="text-grey display-block margin-bottom-5">
                        <strong className="uppercase">{loc.history}</strong>
                    </small>
                    <Loader loading={this.props.historyLoading} type={loaderConstants.type.small} >
                        {contents}
                    </Loader>
                </div>
            </React.Fragment>
        );
    }

    render() {
        let contents = this.props.kitLoading
            ? <div></div>
            : this.renderView();

        return (
            <React.Fragment>
                <div id="kit-details" className="columns data-area">
                    <div className="width-100 columns data-area bg-grey-silver-lighter">
                        <header className="line-height-1-6">
                            <small className="display-block padding-top-6 padding-left-15">
                                <Link title="Back to list" to="/kits" className="display-inline-block text-blue margin-top-minus-2">
                                    <i className="icon caret-blue-left-small valign-middle margin-top-minus-1 margin-right-5" />{loc.backToList}
                                </Link>
                            </small>
                        </header>
                        <div className="margin-20 bg-white padding-top-30 padding-bottom-30 padding-left-40 padding-right-40 m-height-400">
                            <Loader loading={this.props.kitLoading} type={loaderConstants.type.medium} >
                                {contents}
                            </Loader>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

KitDetailsContainer.propTypes = {
    kit: PropTypes.object.isRequired,
    history: PropTypes.array.isRequired,
    kitLoading: PropTypes.bool.isRequired,
    historyLoading: PropTypes.bool.isRequired
};