import baseService from './base.service';
import { kitActionConstants } from '../constants/constants';

const url = baseService.baseUrl + '/kitaction';

function canDoAction(randGuid, guid){
    const obj = {guid: randGuid, kit: guid};
    return baseService.post(url + '/canDoAction', obj);
}

function transferKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };

    return baseService.post(url + '/transfer', obj);
}

function invalidateKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };

    return baseService.post(url + '/invalidate', obj);
}

function returnKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };

    return baseService.post(url + '/return', obj);
}

function cancelKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };

    return baseService.post(url + '/cancel', obj);
}

function receiveKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };

    return baseService.post(url + '/receive', obj);
}

function quarantineKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };
    return baseService.post(url + '/quarantine', obj);
}

function restoreKits(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };
    return baseService.post(url + '/restore', obj);
}

function editExpiryDate(kitsAction) {
    var obj = { ...kitsAction, kits: kitsAction.kits.map(_ => _.kitGuid) };
    return baseService.post(url + '/editExpiryDate', obj);
}

function get(guid, kitGuids) {
    const kitsAction = {
        randDefGuid: guid,
        kits: kitGuids
    };

    return baseService.getWithModel(url, kitsAction);
}

function applyAction(actionObj, callbacks) {
    if (callbacks.length < 3) {
        throw new Error('All action callbacks must be implemented');
    }

    const actions = kitActionConstants.actions;

    switch (actionObj.type) {
        case actions.transfer:
            this.transferKits(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                    //transfer applied
                    callbacks[1](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
            break;
        case actions.invalidate:
            this.invalidateKits(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
            break;
        case actions.return:
            this.returnKits(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
            break;
        case actions.cancel:
            this.cancelKits(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
            break;
        case actions.receive:
            this.receiveKits(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
            break;
        case actions.quarantine:
            this.quarantineKits(actionObj)
                .then(result => {
                    callbacks[0](result);
                })
                .fail(data => {
                    callbacks[2](data);
                });
            break;
        case actions.restore:
            this.restoreKits(actionObj)
                .then(result => {
                    callbacks[0](result);
                })
                .fail(data => {
                    callbacks[2](data);
                });
            break;
        case actions.editExpiryDate:
            this.editExpiryDate(actionObj)
                .then(result => {
                    //success
                    callbacks[0](result);
                })
                .fail(data => {
                    //error
                    callbacks[2](data);
                });
                break;
        default:
            throw new Error('Action is not implemented');
    }
}

var service = {
    get: get,
    canDoAction: canDoAction,
    transferKits: transferKits,
    invalidateKits: invalidateKits,
    returnKits: returnKits,
    cancelKits: cancelKits,
    receiveKits: receiveKits,
    applyAction: applyAction,
    quarantineKits: quarantineKits,
    restoreKits: restoreKits,
    editExpiryDate: editExpiryDate
};

export default service;
