import baseService from './base.service';
import { getLanguageCode } from '../helpers/storage.utils';

const url = baseService.baseUrl + "/translation";

function get(){
    return baseService.get(`${url}?culture=${getLanguageCode()}`);
}

var service = {
    get: get,
};

export default service;