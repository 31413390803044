import $ from "jquery";

function sync(randGuid) {
    return $.ajax({
        url: `/api/sync?randGuid=${randGuid}`,
        method: "post"
    });
}

var service = {
    sync: sync,
};

export default service;