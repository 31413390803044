import React from 'react';
import PropTypes from 'prop-types';
import KitListFilter from './filter/kit-list-filter';
import KitListStatusFilter from './filter/kit-list-status-filter';


export default class KitListFilterHeader extends React.Component {

    render() {
        return (
            <header>
                <KitListStatusFilter status={this.props.status} onStatusChange={this.props.onStatusChange} />
                <KitListFilter query={this.props.query} onFilterSearchChange={this.props.onFilterSearchChange} onFilterSearchTriggered={this.props.onFilterSearchTriggered} />
            </header>
        );
    }
}

KitListFilterHeader.propTypes = {
    status: PropTypes.string.isRequired,
    query: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired,
    onFilterSearchChange: PropTypes.func.isRequired,
    onFilterSearchTriggered: PropTypes.func.isRequired
};