import React from 'react';
import { Route, Redirect } from 'react-router';

export const StudySupplyOverviewRoute = ({ render, isAuthorized }) => {

    if(isAuthorized){
        return <Route render={render} />;
    }

    return <Redirect to={{
        pathname: '/kits',
      }} />;
};