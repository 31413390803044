import React from "react";
import { loc } from '../../../localization/localization';
import { kitConstants } from '../../../constants/constants';
import PropTypes from 'prop-types';
import { getLocalizedSiteName } from "../../../helpers/localization.utils";

const KitLocation = (props) => {
    const { location, isMissing } = props;
    const { name, locationStatus, transferDirection, eta, type, countryCode } = location;
    const locationName = getLocalizedSiteName(name, type, countryCode);

    if (isMissing) {
        return <span>{loc.unknown} ({locationName})</span>;
    }

    if (locationStatus === kitConstants.locationStatus.inTransit) {
        return (
            <React.Fragment>
                <i className={`icon ${transferDirection === kitConstants.transferDirection.from ? 'car-grey-right-small' : 'car-green-left-small'} margin-right-5`} />
                <small className={`display-inline strong ${transferDirection === kitConstants.transferDirection.from ? '' : 'text-green'}`}><i>{locationName}, {loc.eta} {eta}</i></small>
            </React.Fragment>
        );
    } else {
        return <strong>{locationName}</strong>;
    }
};

export default KitLocation;

KitLocation.propTypes = {
    location: PropTypes.object.isRequired,
    isMissing: PropTypes.bool.isRequired,
};

