import React from 'react';
import PropTypes from 'prop-types';
import {loc} from '../../localization/localization';
import { getCurrentDateUTC } from '../../helpers/date.utils';

export default class Error extends React.Component {

    renderError() {
        const timeStamp = getCurrentDateUTC();
        return (
            <div className="text-center">
                <h1 className="h4 large font-weight-700 padding-top-25">{loc.somethingWentWrong}.<br />{loc.pleaseTryAgain}!</h1>
                <p className="text-grey-strong padding-top-10">{this.props.message}<br />
                    {timeStamp}
                </p>
            </div>
        );
    }
    render() {

        let content = !this.props.message
            ? this.props.children
            : this.renderError();

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

Error.propTypes = {
    message: PropTypes.string.isRequired,
};