import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../../../../localization/localization';
import KitActionNote from '../kit-action-note';
import Select2 from '../../../../../helpers/react-select2';
import CustomDatePicker from '../../../../shared/date-picker';
import { dateAddConstants, dateAdd } from '../../../../../helpers/date.utils';

export default class KitActionTransfer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            note: null,
            location: null
        };
    }

    componentDidMount() {
        this.setState({ location: this.props.locations[0].guid }, this.onActionUpdated);
    }

    onActionUpdated() {
        this.props.onActionUpdated({ ...this.state });
    }

    onNoteTextChanged(value) {
        this.setState({ note: value }, this.onActionUpdated);
    }

    onLocationChanged(value) {
        this.setState({ location: value }, this.onActionUpdated);
    }

    onDateChanged(value) {
        this.setState({ date: value }, this.onActionUpdated);
    }

    render() {
        let minDate = dateAdd(new Date(), -1, dateAddConstants.type.day);

        return (
            <React.Fragment>
                <div className="field-row">
                    <div className="field-item" style={{ width: '100%' }}>
                        <div className="ttl-box">{loc.selectDestination}</div>
                        <Select2
                            id={'kit-transfer-destination-select'}
                            value={this.state.location}
                            onChange={event =>
                                this.onLocationChanged(
                                    event.target.value
                                )
                            }
                            style={{ width: '100%' }}>
                            {this.props.locations.map(_ => (
                                <option key={_.guid} value={_.guid}>
                                    {_.location}
                                </option>
                            ))}
                        </Select2>
                    </div>
                </div>
                <div className="field-row items">
                    <div className="field-item">
                        <div className="ttl-box">{loc.eta}</div>
                        <div className="text-area">
                            <CustomDatePicker
                                onChange={date =>
                                    this.onDateChanged(date)
                                }
                                selected={this.state.date || ''}
                                dateFormat={'yyyy-MM-dd'}
                                placeholderText={'yyyy-MM-dd'}
                                minDate={minDate}
                                disableManualInput
                            />
                        </div>
                    </div>
                </div>
                <KitActionNote
                    noteText={this.state.note}
                    onNoteTextChanged={this.onNoteTextChanged.bind(this)} />
            </React.Fragment>
        );
    }
}

KitActionTransfer.propTypes = {
    locations: PropTypes.array.isRequired,
    onActionUpdated: PropTypes.func.isRequired
};
