import baseService from './base.service';

const url = baseService.baseUrl + '/export';

function stockList(randGuid, orderBy, direction, countryCode, siteCode) {
    const obj = { randGuid, orderBy, direction, countryCode, siteCode };
    return baseService.getFile(url + '/stocklist', obj);
}

function kitsWithHistory(randGuid, orderBy, direction, countryCode, siteCode) {
    const obj = { randGuid, orderBy, direction, countryCode, siteCode };
    return baseService.getFile(url + '/kitsWithHistory', obj);
}

function kit(randGuid, kit) {
    let orderBy = '';
    const obj = { randGuid, kit, orderBy };
    return baseService.getFile(url + '/kit', obj);
}

var service = {
    stockList: stockList,
    kitsWithHistory: kitsWithHistory,
    kit: kit
};

export default service;
