import React from 'react';
import { kitConstants } from '../../../constants/constants';
import PropTypes from 'prop-types';
import ReactStringTemplate from 'react-string-template';
import { loc } from '../../../localization/localization';
import localization from '../../../localization/localization';
import { Link } from "react-router-dom";
import { isEmpty } from '../../../helpers/object.utils';
import { getLocalizedSiteName } from '../../../helpers/localization.utils';


const historyType = kitConstants.history.type;
const auditRecordConstants = kitConstants.auditRecordConstants;

export default class KitDetailsHistory extends React.Component {

    getFragment(event) {
        const eventLocation = getLocalizedSiteName(event.locationName, event.locationType, event.locationCountryCode);
        let subjectKeyDisplay = event.subjectKey ?
            <span>
                <br /><span> {loc.subjectId}: {event.subjectKey}</span>
            </span>
            : "";

            const eventReason = event.kitActionReason === null ? <span></span> : <span><br />{loc.reason}: {localization.getLocOrKey(event.kitActionReason)}</span>;
            const eventNote = isEmpty(event.kitActionNote) && isEmpty(event.transferNote)
             ? ""
             : <div>
                 {loc.note}: {isEmpty(event.kitActionNote) ? event.transferNote : event.kitActionNote}
             </div>;
             
        switch (event.type) {
            case historyType.valid:
                const avalableinLoc = loc.at + " {site} " + loc.by + " {user} {date}";
                const vuser = event.auditRecordUser === auditRecordConstants.user.system ? loc.system +" (0)" : event.auditRecordUser;
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.valid}</strong>&nbsp;
                                <ReactStringTemplate
                                    str={avalableinLoc}
                                    values={{ site: eventLocation, user: vuser, date: event.auditRecordDateTime }} />
                                {eventNote}
                            </div>
                        </div>
                    </li>);
            case historyType.expiredWhileUpload:
                const expiredinLoc = loc.at + " {site} " + loc.by +" {user} {date}";
                const expuser = event.auditRecordUser === auditRecordConstants.user.system ? loc.system +" (0)" : event.auditRecordUser;
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.expired}</strong>&nbsp;
                                <ReactStringTemplate
                                    str={expiredinLoc}
                                    values={{ site: eventLocation, user: expuser, date: event.auditRecordDateTime }} />
                            </div>
                        </div>
                    </li>);
            case historyType.kitReceived:
                const receivedinLoc = loc.at +" {site} " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.received}</strong>&nbsp;
                                <ReactStringTemplate
                                    str={receivedinLoc}
                                    values={{ site: eventLocation, user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                {eventNote}
                            </div>
                        </div>
                    </li>);
            case historyType.transferred:
                const transferByLoc = " " + loc.to + " {site} (" + loc.eta + " {eta}) " + loc.by + " {user} {date}";
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.transferred}</strong>
                                <span>
                                    <ReactStringTemplate
                                        str={transferByLoc}
                                        values={{ site: eventLocation, eta: event.transferEta, user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                </span><br />
                                {loc.shipmentId}: <Link title={event.transferGuid} to={`/kits/${event.transferGuid}`}>{event.transferGuid}</Link> 
                                {eventNote}
                            </div>
                        </div>
                    </li>
                );

            case historyType.transferCanceled:
                const transferCanceledLoc = " " + loc.by + " {user} {date}";
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.transferCanceled}</strong>
                                <span>
                                    <ReactStringTemplate
                                        str={transferCanceledLoc}
                                        values={{ eta: event.transferEta, user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                </span>
                                {eventNote}
                            </div>
                        </div>
                    </li>
                );

            case historyType.kitCreated:
                const createdByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.created}</strong>
                                <span>
                                    <ReactStringTemplate
                                        str={createdByLoc}
                                        values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                </span>
                            </div>
                        </div>
                    </li>);

            case historyType.kitAllocated:
                const allocatedByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.allocated}</strong>
                                <span>
                                    <ReactStringTemplate
                                        str={allocatedByLoc}
                                        values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                </span>
                                { subjectKeyDisplay }
                            </div>
                        </div>
                    </li>);

            case historyType.kitAvailable:
                const availableByLoc = loc.valid + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <ReactStringTemplate
                                    str={availableByLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                {eventNote}
                            </div>
                        </div>
                    </li>);

            case historyType.kitInvalidated:
 
                const invalidatedByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.invalidated}</strong>
                                <ReactStringTemplate
                                    str={invalidatedByLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                {eventReason}
                                {eventNote}
                            </div>
                        </div>
                    </li>);
            case historyType.quarantined:
                const quarantinedByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.quarantined}</strong>
                                <ReactStringTemplate
                                    str={quarantinedByLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                {eventReason}
                            </div>
                        </div>
                    </li>);
            case historyType.restored:
                const restoredByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.restored}</strong>
                                <ReactStringTemplate
                                    str={restoredByLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                {eventReason}
                            </div>
                        </div>
                    </li>);

            case historyType.kitReturned:
                const returnedBy = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.returned}</strong>
                                <ReactStringTemplate
                                    str={returnedBy}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />;
                                {eventNote}
                            </div>
                        </div>
                    </li>);
            case historyType.undoAllocation:
                const undoAllocationinLoc = " " + loc.by + " {user} {date}";
                const validAtLoc = loc.at + " {site}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.allocationUndone}</strong>
                                <ReactStringTemplate
                                    str={undoAllocationinLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                <br />
                                <strong>{event.kitStatus}</strong>&nbsp;
                                <ReactStringTemplate
                                    str={validAtLoc}
                                    values={{ site: eventLocation }} />
                            </div>
                        </div>
                    </li>);
            case historyType.replaceAllocation:
                const replaceAllocationByLoc = " " + loc.by + " {user} {date}";

                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.allocated}</strong>
                                <span>
                                    <ReactStringTemplate
                                        str={replaceAllocationByLoc}
                                        values={{ user: event.auditRecordUser, date: event.auditRecordDateTime }} />
                                </span>
                                {subjectKeyDisplay}
                            </div>
                        </div>
                    </li>);
                case historyType.expired:
                var expiredByLoc = " " + loc.by + " {user} {date} " + loc.note + ": " + loc.duetoexpirydate;
                if (!event.blindedExpiryDate) {
                    expiredByLoc += " {expdate}";
                }
                const expireduser = event.auditRecordUser === auditRecordConstants.user.system ? loc.system +" (0)" : event.auditRecordUser;    
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.expired}</strong>
                                <ReactStringTemplate
                                    str={expiredByLoc}
                                    values={{ user: expireduser, date: event.auditRecordDateTime,expdate:event.expiryDate }} />
                            </div>
                        </div>
                    </li>);
            case historyType.editExpiryDate:
                var editExpiryDateByLoc = " " + loc.by + " {user} {date} ";
                if(!event.blindedExpiryDate){
                    editExpiryDateByLoc += loc.oldDate +": {oldDate}, "+ loc.newDate +": {newDate}";     
                }
                const oldExpDate = event.oldExpiryDate === null || event.oldExpiryDate === '' || event.oldExpiryDate === undefined ? loc.notSet : event.oldExpiryDate;        
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.editExpiryDate}</strong>
                                <ReactStringTemplate
                                    str={editExpiryDateByLoc}
                                    values={{ user: event.auditRecordUser, date: event.auditRecordDateTime, oldDate: oldExpDate, newDate: event.expiryDate }} />
                                {eventReason}
                            </div>
                        </div>
                    </li>);
            case historyType.validAfterExpiration:
                var kitValidByLoc = " " + loc.by + " {user} {date} " + loc.note + ": " + loc.duetoexpirydate;
                if (!event.blindedExpiryDate) {
                kitValidByLoc += " {expdate}";
                }
                const validateduser = event.auditRecordUser === auditRecordConstants.user.system ? loc.system +" (0)" : event.auditRecordUser;
                return (
                    <li key={event.identifier}>
                        <div className="display-table valign-top">
                            <div className="table-cell">
                                <strong>{loc.valid}</strong>
                                <ReactStringTemplate
                                    str={kitValidByLoc}
                                    values={{ user: validateduser, date: event.auditRecordDateTime,expdate:event.expiryDate }} />
                            </div>
                        </div>
                    </li>);

            default:
                return (
                    //todo
                    <div>Error</div>
                );
        }
    }

    render() {
        let events = this.props.events;

        return (
            <ul className="text-dark list-with-top-borders">
                {events.map(_ => this.getFragment(_))}
            </ul>

        );
    }
}

KitDetailsHistory.propTypes = {
    events: PropTypes.array.isRequired
};
