import {
    subMonths,
    subYears,
    addYears,
    addMonths,
    subDays,
    addDays,
    subHours,
    addHours,
    subMinutes,
    addMinutes
} from 'date-fns';

export const getCurrentDateUTC = () => {
    var d = new Date(),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + d.getUTCDate(),
        year = '' + d.getUTCFullYear(),
        hours = '' + d.getUTCHours(),
        minutes = '' + d.getUTCMinutes(),
        seconds = '' + d.getUTCSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    if (hours.length < 2) {
        hours = '0' + hours;
    }
    if (minutes.length < 2) {
        minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
        seconds = '0' + seconds;
    }

    var date = [year, month, day].join('-');
    var time = [hours, minutes, seconds].join(':');

    return date + ' ' + time + ' UTC';
};

export const dateAdd = (date, value, type) => {
    if (number === 0) return;
    let negative = value < 0;
    var number = Math.abs(value);

    switch (type) {
        case dateAddConstants.type.year:
            return negative ? subYears(date, number) : addYears(date, number);
        case dateAddConstants.type.month:
            return negative ? subMonths(date, number) : addMonths(date, number);
        case dateAddConstants.type.day:
            return negative ? subDays(date, number) : addDays(date, number);
        case dateAddConstants.hours:
            return negative ? subHours(date, number) : addHours(date, number);
        case dateAddConstants.minutes:
            return negative
                ? subMinutes(date, number)
                : addMinutes(date, number);
        default:
            throw new Error(`${type} is not supported`);
    }
};

export const dateAddConstants = {
    type: {
        year: 'year',
        month: 'month',
        day: 'day',
        hours: 'hours',
        minutes: 'minutes'
    }
};
