import { lowerCaseFirstLetter } from '../helpers/string.utils';

export let loc = {};

function setLoc(translations) {
    translations.forEach(x => {
        loc[x.key] = x.value;
    });
};

function getLoc (value) {
    let translationKey = lowerCaseFirstLetter(value);
    if (!loc[translationKey]) {
        throw new Error('value ' + translationKey + ' does not exist on loc object');
    }
    return loc[translationKey];
};

function getLocOrKey(localizationKey){
    let translationKey = lowerCaseFirstLetter(localizationKey);
    return loc[translationKey] ? loc[translationKey] : localizationKey;
}

function testLoc ()  {
    const notSetMessage = ' is not set';
    let errors = [];

    for (var value in loc) {
        if (!loc[value]) {
            errors.push(value + notSetMessage);
        }
    }

    if (errors.length > 0) {
        throw (errors);
    }

    return true;
};

const localization = {
    setLoc,
    getLoc,
    getLocOrKey,
    testLoc
  };

  export default localization;