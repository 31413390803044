import React from 'react';
import PropTypes from 'prop-types';
import ActionLink from '../../../../../shared/actionlink';

export default class AnimatedModalHeader extends React.Component {
    render() {
        return (
            <div className="popup-animation-header">
                <ActionLink className="close"
                    onClick={this.props.onClose}>
                    <i className="icon close-blue-round-large"></i>
                </ActionLink>
            </div>
        );
    }
}

AnimatedModalHeader.propTypes = {
    onClose: PropTypes.func.isRequired,
};