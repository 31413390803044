import React from "react";

export default class KitMenuItemAdd extends React.Component {
    render() {
        return (
            <a href="#" title="Add">
                <i className="icon plus-green-round-large" />
            </a>
        );
    }
}