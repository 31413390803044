import React from "react";

const DescriptionTabs = (props) => {

    return (
        <dl className="tabs">
            {props.children}
        </dl>
    );

};

export default DescriptionTabs;