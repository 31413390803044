import React from 'react';
import PropTypes from 'prop-types';
import KitListContainer from '../kits/list/kit-list-container';
import KitActionContainer from '../kits/action/kit-action-container';
import { toggleArray, toggleArrays } from '../../helpers/array.utils';
import { kitConstants } from '../../constants/constants';
import kitActionService from '../../services/kit-action.service';
import KitService from '../../services/kit.service';
import { getUpdatedColumns, getOrderByValue, getSortingDirectionValue, filterSelectableKits, isSomeKitsSelectable } from '../../helpers/kit.utils';
import { recountPageIndex } from '../../helpers/paging.utils';
import { loc } from '../../localization/localization';
import Error from '../shared/error';
import KitActionTransferModal from './action/actions/transfer/modal/kit-action-transfer-modal';
import { localizeSiteNamesForKits } from '../../helpers/localization.utils';


export default class KitListManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedKits: [],
            selectableKits: [],
            kits: [],
            loading: true,
            kitActionLoading: false,
            kitActionErrorMessage: '',
            kitsFetchedCount: 0,
            kitsTotalCount: 0,
            kitsError: '',
            kitColumns: [],
            currentKitRequest: null,
            kitActionTransferModalIsOpen: false,
            transferGuid: null
        };
    }

    componentDidMount() {
        this.loadColumns();
        this.initLoadKits();
    }

    componentDidUpdate(prevProps) {
        if (this.shouldUpdate(prevProps)) {
            this.initLoadKits();
        }
    }

    shouldUpdate(prevProps) {
        if (this.props.selectedCountry !== prevProps.selectedCountry) return true;
        if (this.props.selectedSite !== prevProps.selectedSite) return true;
        if (this.props.filterStatus !== prevProps.filterStatus) return true;
        if (this.props.orderBy !== prevProps.orderBy) return true;
        if (this.props.orderBySortingDirection !== prevProps.orderBySortingDirection) return true;
        if (this.props.pageSize !== prevProps.pageSize) return true;
        if (this.props.pageIndex !== prevProps.pageIndex) return true;

        return false;
    }

    reload() {
        this.initLoadKits();
    }

    initLoadKits() {
        this.setState({ loading: true }, this.loadKits);
    }

    loadColumns() {
        const { guid } = this.props;

        KitService.getColumns(guid).then(result => {
            this.setState({ kitColumns: result.columns });
        }).fail(() => {
            this.setState({ kitsError: loc.listColumnsError });
        });;

    }

    loadKits() {
        const { guid, selectedSite, selectedCountry, filterStatus, orderBy, orderBySortingDirection, pageSize, pageIndex, filterQuery } = this.props;
        if (this.state.currentKitRequest) {
            this.state.currentKitRequest.abort();
        }

        let request = KitService.get(guid, selectedCountry, selectedSite, filterStatus,
            filterQuery, pageIndex, pageSize, getOrderByValue(orderBy), getSortingDirectionValue(orderBySortingDirection));

        this.setState({ currentKitRequest: request });

        request
            .then((result) => {
                KitService.getSelectableKits(guid, result.kits)
                    .then((selectableKits) => {
                        localizeSiteNamesForKits(result.kits);
                        this.setState({
                            loading: false,
                            selectableKits: selectableKits,
                            kits: result.kits,
                            kitsFetchedCount: result.kits.length,
                            kitsTotalCount: result.totalCount,
                            currentKitRequest: null,
                        });
                        this.updateSelectedKits(result.kits);
                    }).fail(data => {
                        if (data && data.statusText === "abort") return;
                        this.setState({ kitsError: loc.couldNotFetchKits, loading: false, currentKitRequest: null });
                    });
            }).fail(data => {
                if (data && data.statusText === "abort") return;
                this.setState({ kitsError: loc.couldNotFetchKits, loading: false, currentKitRequest: null });
            });
    }

    onKitSelected(value) {
        let selectedKits = this.state.selectedKits;
        let toggledArray = toggleArray(selectedKits, kitConstants.kit.kitGuid, value);
        this.setState({ selectedKits: toggledArray}, this.changedKitSelection);
    }

    changedKitSelection() {
        this.setState({ kitActionErrorMessage: '' });
    }

    onSelectAllToggle(value) {
        if (value) {
            this.setState({ selectedKits: filterSelectableKits(this.state.kits, this.state.selectableKits) }, this.changedKitSelection);
        } else {
            this.setState({ selectedKits: [] }, this.changedKitSelection);
        }
    }

    updateSelectedKits(fetchedKits) {
        let selectedKits = this.state.selectedKits;
        let toggledArray = toggleArrays(fetchedKits, selectedKits, kitConstants.kit.kitGuid);

        this.setState({ selectedKits: toggledArray });
    }

    kitActionFailed() {
        this.setState({ kitActionErrorMessage: loc.couldNotApplyKitAction, kitActionLoading: false });
    }

    applyAction(obj) {
        const callbacks = [this.onKitActionApplied.bind(this), this.onTransferActionApplied.bind(this), this.kitActionFailed.bind(this)];

        kitActionService.applyAction(obj, callbacks);
    }

    onTransferActionApplied(result) {
        this.setState({ kitActionTransferModalIsOpen: true, transferGuid: result.guid });
    }

    onKitActionApplied(result) {
        if (result.success) {
            this.setState({ selectedKits: [], kitActionLoading: false });
            this.props.onKitActionApplied();
        } else {
            this.setState({ kitActionErrorMessage: loc.couldNotApplyKitAction, kitActionLoading: false });
        }
    }

    onApplyKitAction(obj) {
        this.setState({ kitActionLoading: true }, () => {
            this.applyAction(obj);
        });
    }

    onPageSizeChange(pageSize) {
        var index = recountPageIndex(this.props.pageIndex, pageSize, this.state.kitsTotalCount);
        if (index !== this.props.pageIndex) {
            this.props.onPageIndexChanged(index);
        }

        this.props.onPageSizeChange(pageSize);
    }

    onOrderByChange(order) {
        let newKitColumns = getUpdatedColumns(this.state.kitColumns, order);
        this.setState({ kitColumns: newKitColumns }, this.props.onListOrderByChange(order));
    }

    onCloseModal() {
        this.setState({ kitActionTransferModalIsOpen: false });
    }

    render() {
        const { kitActionTransferModalIsOpen, transferGuid } = this.state;

        return (
            <React.Fragment>
                <KitActionTransferModal
                    isOpen={kitActionTransferModalIsOpen}
                    onClose={this.onCloseModal.bind(this)}
                    id={transferGuid}>
                </KitActionTransferModal>
                <div className="row as-table table-fixed" style={{ minHeight: 186 }}>
                    <KitListContainer {...this.props} {...this.state}
                        onPageSizeChange={this.onPageSizeChange.bind(this)}
                        onSelectAllToggle={this.onSelectAllToggle.bind(this)}
                        onKitSelected={this.onKitSelected.bind(this)}
                        onStatusChange={this.props.onListFilterStatusChange}
                        onOrderByChange={this.onOrderByChange.bind(this)} />
                    {isSomeKitsSelectable(this.state.kits, this.state.selectableKits)
                        ? <div className="columns sidebar-controls" id="sidebar-controls-JS kit-action" style={{ width: "300px" }}>
                            <Error message={this.state.kitActionErrorMessage}>
                                <KitActionContainer selectedKits={this.state.selectedKits} locations={this.props.locations} guid={this.props.guid}
                                    loading={this.state.kitActionLoading}
                                    onApplyKitAction={this.onApplyKitAction.bind(this)} />
                            </Error>
                        </div>
                        : null}

                </div>
            </React.Fragment>
        );
    }
}

KitListManager.propTypes = {
    locations: PropTypes.array.isRequired,
    selectedCountry: PropTypes.string,
    selectedSite: PropTypes.string,
    filterStatus: PropTypes.string.isRequired,
    filterQuery: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderBySortingDirection: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    onFilterSearchChange: PropTypes.func.isRequired,
    onFilterSearchTriggered: PropTypes.func.isRequired,
    onListFilterStatusChange: PropTypes.func.isRequired,
    onListOrderByChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
    onPageIndexChanged: PropTypes.func.isRequired,
    onKitActionApplied: PropTypes.func.isRequired,
};