export const fileNameFromContentDispositionRegex = /filename=(.*?(?=;))/;


const createObjectURL = (data) => {
    return window.URL.createObjectURL(data);
};

const downloadFile = (data, fileName) => {
    //IE
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(data, fileName);
    } 
    //other browsers
    else {
        const url = createObjectURL(data);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }
};

const getFileNameFromContentDisposition = (contentDisposition) => {
    const fileName = fileNameFromContentDispositionRegex.exec(contentDisposition)[1];
    return fileName;
};

const getFileNameFromHeaders = (headers) => {
    const contentDisposition = getContentDisposition(headers);
    const fileName = getFileNameFromContentDisposition(contentDisposition);

    return fileName;
};

const getContentDisposition = (headers) => {
    return headers.getResponseHeader('content-disposition');
};

var FileUtils = {
    createObjectURL: createObjectURL,
    downloadFile: downloadFile,
    getFileNameFromHeaders: getFileNameFromHeaders,
    getFileNameFromContentDisposition: getFileNameFromContentDisposition
};

export default FileUtils;