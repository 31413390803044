import React from 'react';
import { loaderConstants } from '../../constants/constants';
import { getPropertyValues } from '../../helpers/object.utils';
import PropTypes from 'prop-types';

const types = getPropertyValues(loaderConstants, 'type');
const positions = getPropertyValues(loaderConstants, 'position');

export default class Loader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    renderLoading() {
        const { type, position } = this.props;

        let divClass = 'loading-';

        if (!type) {
            divClass += loaderConstants.small;
        } else {
            for (let i = 0; i < types.length; i++) {
                if (type === types[i]) {
                    divClass += types[i];
                    break;
                }
            }
        }

        let style = this.getStyle();

        return (
            <div className={`${divClass} ${position ? position : ''}`} style={style}></div>
        );
    }

    getStyle() {
        const { style, loading } = this.props;
        //add display none to style if it's not loading
        //this renders the loading symbol and makes it available directly
        //if it's not rendered first, it will not be available when state changes
        const _style = style ? loading ? style : this.getHiddenstyleObject(style) : loading ? {} : { display: 'none' };

        return _style;
    }

    getHiddenstyleObject(obj) {
        const _obj = { ...obj };
        _obj.display = 'none';
        return _obj;
    }

    render() {
        const { loading } = this.props;

        return (
            <React.Fragment>
                {this.renderLoading()}
                {!loading ? this.props.children : null}
            </React.Fragment>
        );
    }
}

Loader.propTypes = {
    loading: PropTypes.bool.isRequired,
    style: PropTypes.object,
    type: PropTypes.oneOf(types).isRequired,
    position: PropTypes.oneOf(positions)
};