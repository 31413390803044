import React from 'react';
import PropTypes from 'prop-types';
import Paging from '../../shared/paging';
import PageSize from '../../shared/page-sizing';

export default class KitListFooter extends React.Component {

    render() {
        return (
            <tfoot>
                <tr>
                    <td colSpan="4">
                        <Paging itemsFetchedCount={this.props.kitsFetchedCount} pageIndex={this.props.pageIndex}
                            pageSize={this.props.pageSize} onPageIndexChanged={this.props.onPageIndexChanged} itemsTotalCount={this.props.kitsTotalCount} />
                    </td>
                    <td colSpan="3" className="text-right">
                        <PageSize pageSize={this.props.pageSize} onPageSizeChange={this.props.onPageSizeChange} />
                    </td>
                </tr>
            </tfoot>
        );
    }
}

KitListFooter.propTypes = {
    kitsTotalCount: PropTypes.number.isRequired,
    kitsFetchedCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageIndexChanged: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func.isRequired
};