import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../../../localization/localization';
import TextArea from '../../../shared/textarea';
import { lowerCaseFirstLetter } from '../../../../helpers/string.utils';

export default class KitActionNote extends React.Component {
  render() {

    const label = (
      <span>
        {loc.addNote}{' '}
        <span className="text-grey">{'(' + (this.props.isRequired ?  lowerCaseFirstLetter(loc.required) : loc.optional) + ')'}</span>
      </span>
    );

    return (
      <React.Fragment>
        <div className="field-row items">
          <div className="field-item" style={{ width: '100%' }}>
            <TextArea
              label={label}
              value={this.props.noteText || ''}
              onChanged={this.props.onNoteTextChanged}
              hasError={!this.props.isValid}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

KitActionNote.propTypes = {
  noteText: PropTypes.string,
  onNoteTextChanged: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  isRequired: PropTypes.bool,
};

KitActionNote.defaultProps = {
  isValid: true,
  isRequired: false,
};
