import React from 'react';
import PropTypes from 'prop-types';
import KitService from '../../services/kit.service';
import kitActionService from '../../services/kit-action.service';
import KitDetailsContainer from '../kits/details/kit-details-container';
import KitActionContainer from '../kits/action/kit-action-container';
import KitActionTransferModal from './action/actions/transfer/modal/kit-action-transfer-modal';
import Error from '../shared/error';
import localization from '../../localization/localization';

export default class KitDetailsManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            kit: {},
            history: [],
            selectedKits: [],
            canDoAction: false,
            kitLoading: true,
            historyLoading: true,
            kitActionTransferModalIsOpen: false,
            transferGuid: null,
            kitActionErrorMessage: '',
            kitActionLoading: false
        };
    }

    componentDidMount() {
        this.loadKit();
    }

    loadKit() {
        const kitGuid = this.props.match.params.guid;
        this.getKit(kitGuid);
    }

    getKit(guid) {
        KitService.getKit(this.props.guid, guid)
            .then((result) => {
                kitActionService.canDoAction(this.props.guid, guid).then(canDoAction => {
                    this.setState({
                        kitLoading: false,
                        kit: result,
                        canDoAction: canDoAction,
                        selectedKits: [result]
                    }, this.getHistory);
                });
            });
    }

    getHistory() {
        KitService.getHistory(this.props.guid, this.state.kit.kitGuid)
            .then(
                (details) => {
                    this.setState({
                        history: details.history,
                        historyLoading: false
                    });
                });
    }

    onApplyKitAction(obj) {
        this.setState({ kitActionLoading: true }, () => {
            this.applyAction(obj);
        });
    }

    onTransferActionApplied(result) {
        this.setState({ kitActionTransferModalIsOpen: true, transferGuid: result.guid });
    }

    onKitActionApplied() {
        this.setState({ kitLoading: true, historyLoading: true, kitActionLoading: false }, this.loadKit);
        this.props.onKitActionApplied();
    }

    kitActionFailed() {
        this.setState({ kitActionLoading: false, kitActionErrorMessage: localization.getLocOrKey('couldNotApplyKitAction') });
    }

    applyAction(obj) {
        const callbacks = [this.onKitActionApplied.bind(this), this.onTransferActionApplied.bind(this), this.kitActionFailed.bind(this)];

        kitActionService.applyAction(obj, callbacks);
    }

    onCloseModal() {
        this.setState({ kitActionTransferModalIsOpen: false, transferGuid: null });
    }

    render() {
        const { kitActionTransferModalIsOpen, transferGuid } = this.state;

        return (
            <React.Fragment>
                <KitActionTransferModal
                    isOpen={kitActionTransferModalIsOpen}
                    onClose={this.onCloseModal.bind(this)}
                    id={transferGuid}>
                </KitActionTransferModal>
                <div className="row as-table table-fixed">
                    <KitDetailsContainer kit={this.state.kit} history={this.state.history} kitLoading={this.state.kitLoading}
                        historyLoading={this.state.historyLoading} />
                    {this.state.kitLoading
                        ? null
                        : this.state.canDoAction ?
                            <div className="columns sidebar-controls" id="sidebar-controls-JS kit-action" style={{ width: "300px" }}>
                                <Error message={this.state.kitActionErrorMessage}>
                                    <KitActionContainer selectedKits={this.state.selectedKits} locations={this.props.locations} guid={this.props.guid}
                                        loading={this.state.kitActionLoading}
                                        onApplyKitAction={this.onApplyKitAction.bind(this)} />
                                </Error>
                            </div>
                            : null
                    }
                </div>
            </React.Fragment>
        );
    }

}

KitDetailsManager.propTypes = {
    locations: PropTypes.array.isRequired,
    selectedCountry: PropTypes.string,
    onKitActionApplied: PropTypes.func.isRequired
};