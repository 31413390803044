import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../../shared/modal/modal';
import animationPopupHeader from '../../../../../../static/images/bg_animation_popup_header.png';

export default class AnimatedModal extends React.Component {

    componentDidMount() {
        //adds script to load the animation
        this.addLogisticsScript();
    }

    addLogisticsScript() {
        const script = document.createElement("script");

        script.src = "js/app_logistics.js";
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <React.Fragment>
                {/* references the image so it loads before opening the modal. it will otherwise load the modal without the image at first */}
                <img src={animationPopupHeader} style={{ display: 'none' }} />
                <Modal
                    isOpen={this.props.isOpen}
                    classNames={['animation-popup']}
                    //id needed for generation of animation
                    id={'popupTip'}
                    onClose={this.props.onClose}>
                    {this.props.children}
                </Modal>
            </React.Fragment>
        );
    }
}

AnimatedModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};