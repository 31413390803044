import React from 'react';
import ListOverviewHeader from './header/list-overview-header';
import ListOverview from './list-overview';
import PropTypes from 'prop-types';
import ListOverviewFooter from './footer/list-overview-footer';
import Error from '../../shared/error';
import Loader from '../../shared/loader';
import { loaderConstants } from '../../../constants/constants';

export default class ListOverviewContainer extends React.Component {
  renderContent() {
    const { headerColumns, metrics } = this.props;
    return (
      <table className="regular no-borders overflow-ellipsis">
        <ListOverviewHeader headerColumns={headerColumns} />
        <ListOverview
         metrics={metrics.locationMetrics}
         onOpenLocationSettingsModal={this.props.onOpenLocationSettingsModal} />
        <ListOverviewFooter
          locationsTotalCount={metrics.locationCount}
          locationsFetchedCount={metrics.locationMetrics.length}
          pageIndex={this.props.pageIndex}
          pageSize={this.props.pageSize}
          onPageIndexChanged={this.props.onPageIndexChanged}
          onPageSizeChanged={this.props.onPageSizeChanged}
        />
      </table>
    );
  }

  render() {
    let content =
      this.props.loading || this.props.error ? (
        <div></div>
      ) : (
          this.renderContent()
        );

    return (
      <React.Fragment>
        <div className="row as-table table-fixed">
          <div id="kit-overview-list" className="columns data-area">
            <header></header>
            <Loader loading={this.props.loading} style={{marginTop: '50px', marginBottom: '50px'}} type={loaderConstants.type.medium} >
              {content}
            </Loader>
          </div>
        </div>
        <Error message={this.props.error}></Error>
      </React.Fragment>
    );
  }
}

ListOverviewContainer.propTypes = {
  headerColumns: PropTypes.array.isRequired,
  metrics: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageIndexChanged: PropTypes.func.isRequired,
  onPageSizeChanged: PropTypes.func.isRequired,
  onOpenLocationSettingsModal: PropTypes.func.isRequired,
};
