import React from 'react';
import ListOverviewHeaderNode from './list-overview-header-node';
import PropTypes from 'prop-types';

export default class ListOverviewHeader extends React.Component {

    render() {
        const headerColumns = this.props.headerColumns;
        return (
            <thead>
                <tr>
                <th className="status-color"></th>
                    {headerColumns.map(column =>
                        <ListOverviewHeaderNode  key={column.type} column={column} />
                    )}
                </tr>
            </thead>
        );
    }
}

ListOverviewHeader.propTypes = {
    headerColumns: PropTypes.array.isRequired,
};