import React from "react";
import PropTypes from 'prop-types';
import { alertSettingsConstants, alertSettingsCssConstants } from '../../../constants/constants';
import { getPropertyValues } from '../../../helpers/object.utils';
import { loc } from '../../../localization/localization';
import Checkbox from '../../shared/checkbox';
import { localizeAlertLevel } from '../../../helpers/localization.utils';
import { isLevelValid, isIgnoreLevelValid } from './validation/validation';
import {lowerCaseFirstLetter} from '../../../helpers/string.utils';

const alertSettingsConstantsTypes = getPropertyValues(alertSettingsConstants, 'type');

export default class SettingsAlertNotificationRow extends React.Component {
    render() {
        const { notification, readOnly } = this.props;

        var ignoreOptions = this.props.hideIgnoreOptions
            ? ''
            : <React.Fragment>
                <Checkbox onChange={() => this.props.onIgnoreLevelEnabledChanged(notification.id)}
                    value={notification.id} isChecked={notification.enabledIgnore}
                    labelText={loc.ignoreWhenEAROrSiteIsLowerThan} classNames={['smaller']}
                    disabled={readOnly} />

                <div className="field-item margin-left-10 margin-top-minus-2">
                    <div className={`text-field ${isIgnoreLevelValid(notification) ? '' : 'hasError'}`}>
                        <input type="text" value={notification.ignoreLevel || ''} className="strong" style={{ width: 40 }}
                            onChange={e => this.props.onIgnoreLevelChanged(notification.id, e.target.value)}
                            disabled={readOnly} />
                    </div>
                </div>
            </React.Fragment>;

        const alertType = lowerCaseFirstLetter(notification.alertSettingsType);
        return (
            <React.Fragment>
                <div className="field-item margin-top-7">
                    <i className={`icon margin-top-minus-1 margin-right-10 ${alertSettingsCssConstants.storageIndicator[alertType]}`}></i>
                </div>

                <Checkbox onChange={() => this.props.onLevelEnabledChanged(notification.id)}
                    value={notification.id} isChecked={notification.enabled}
                    labelText={localizeAlertLevel(alertSettingsConstants, notification.alertSettingsType)}
                    classNames={['margin-left-0', 'smaller']}
                    disabled={readOnly} />

                <div className="field-item margin-left-10 margin-top-minus-2">
                    <div className={`text-field ${isLevelValid(notification) ? '' : 'hasError'}`}>
                        <input type="text" value={notification.level || ''} className="strong" style={{ width: 40 }}
                            onChange={e => this.props.onLevelChanged(notification.id, e.target.value)}
                            disabled={readOnly} />
                    </div>
                </div>
                {ignoreOptions}

            </React.Fragment>
        );
    }
}

SettingsAlertNotificationRow.propTypes = {
    notification: PropTypes.shape({
        id: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
        level: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        enabledIgnore: PropTypes.bool.isRequired,
        ignoreLevel: PropTypes.PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        alertSettingsType: PropTypes.oneOf([alertSettingsConstants.type.warning, alertSettingsConstants.type.notification]).isRequired,
        validation: PropTypes.object.isRequired
    }).isRequired,
    onLevelChanged: PropTypes.func.isRequired,
    onIgnoreLevelChanged: PropTypes.func.isRequired,
    onLevelEnabledChanged: PropTypes.func.isRequired,
    onIgnoreLevelEnabledChanged: PropTypes.func.isRequired,
    hideIgnoreOptions: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired
};