import React, { useState } from "react";
import PropTypes from 'prop-types';
import ActionLink from "./actionlink";
import ClickOutisde from '../shared/click-outside';


const BtnDropdown = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const onMenuClicked = (id) => {
        setIsOpen(false);
        props.onClick(id);
    };

    return (
        <div style={{ paddingBottom: '4.5em' }}>
            <ClickOutisde
                active={isOpen}
                onClick={() => setIsOpen(false)}>
                <button id={props.id} className="button-hollow button-dropdown"
                    onClick={() => setIsOpen(!isOpen)}>
                    {props.children}
                </button>

                <div className="jq-dropdown" style={{ display: isOpen ? 'block' : 'none' }}>
                    <ul className="jq-dropdown-menu">
                        {props.items.map(item =>
                            <li id={item.id} key={item.id}>
                                <ActionLink onClick={() => onMenuClicked(item.id)}>{item.name}</ActionLink>
                            </li>
                        )}
                    </ul>
                </div>
            </ClickOutisde>
        </div>
    );
};

export default BtnDropdown;

BtnDropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired,
        name: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]).isRequired
    })),
    onClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired
};