import React from 'react';
import PropTypes from 'prop-types';
import KitLocationFilter from './kit-location-filter';
import KitMenuItemContact from './kit-menu-item-contact';
import KitMenuItemAdd from './kit-menu-item-add';
import KitMenuItemSettings from './kit-menu-item-settings';
import KitRouteToOverview from './kit-route-to-overview';

export default class KitMenuContainer extends React.Component {
    renderSettingsComponent() {
        return this.props.menuSettings.displaySettingsItem ? (
            <li id="kit_menu_item_settings">
                <KitMenuItemSettings
                    onOpenModal={this.props.onOpenSettingsModal}
                />
            </li>
        ) : (
            ''
        );
    }

    renderContactComponent() {
        return this.props.menuSettings.displayContactItem ? (
            <li id="kit_menu_item_contact">
                <KitMenuItemContact />
            </li>
        ) : (
            ''
        );
    }

    renderAddComponent() {
        return this.props.menuSettings.displayAddItem ? (
            <li id="kit_menu_item_add">
                {' '}
                <KitMenuItemAdd />{' '}
            </li>
        ) : (
            ''
        );
    }

    render() {
        var settingsComponent = this.renderSettingsComponent();
        var contactComponent = this.renderContactComponent();
        var addComponent = this.renderAddComponent();

        return (
            <dl
                id="kit-location-filter"
                className="tabs icon-tabs with-text with-dropdowns width-250"
            >
                <KitRouteToOverview
                    studyPermissions={this.props.studyPermissions}
                />
                <KitLocationFilter
                    locations={this.props.locations}
                    selectedCountry={this.props.selectedCountry}
                    selectedSite={this.props.selectedSite}
                    onSiteChange={this.props.onSiteChange}
                    onCountryChange={this.props.onCountryChange}
                />

                <dd className="tools-menu float-right">
                    <ul>
                        {contactComponent}
                        {addComponent}
                        {settingsComponent}
                    </ul>
                </dd>
            </dl>
        );
    }
}

KitMenuContainer.propTypes = {
    locations: PropTypes.array,
    selectedCountry: PropTypes.string,
    selectedSite: PropTypes.string,
    onSiteChange: PropTypes.func.isRequired,
    onCountryChange: PropTypes.func.isRequired,
    studyPermissions: PropTypes.object,
    onOpenSettingsModal: function(props, propName, componentName) {
        var settings = props['menuSettings'];
        var func = props[propName];

        if (settings && settings.displaySettingsItem && !func) {
            return new Error(
                `onOpenSettingModal is required if menuSettings:displaySettingsItem is set to true in ${componentName}`
            );
        }
    },
    onOpenSettingsModal: PropTypes.func,
    menuSettings: PropTypes.exact({
        displayContactItem: PropTypes.bool.isRequired,
        displayAddItem: PropTypes.bool.isRequired,
        displaySettingsItem: PropTypes.bool.isRequired
    })
};
