import React from "react";
import { kitConstants } from '../../../constants/constants';
import localization from '../../../localization/localization';
import { getPropertyValues } from '../../../helpers/object.utils';
import PropTypes from "prop-types";

const statuses = getPropertyValues(kitConstants, 'status');

const KitStatus = props => {
    const { status } = props;
    const statusLoc = localization.getLoc(kitConstants.status[status]);

    return (
        <React.Fragment>
            {statusLoc}
        </React.Fragment>
    );
};

KitStatus.propTypes = {
    status: PropTypes.oneOf(statuses).isRequired
};

export default KitStatus;