import React from "react";

const DescriptionItems = (props) => {

    return (
        <ul className="tabs-content">
            {props.children}
        </ul>
    );

};

export default DescriptionItems;