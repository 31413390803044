import localization from '../../localization/localization';

export const getOptionsAsLocs = (options) => {
    let locs = [];

    for (let i = 0; i < options.length; i++) {
        locs.push({
            id: options[i],
            name: localization.getLoc(options[i])
        });
    }

    return locs;
};