import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../shared/checkbox';
import KitListSortableNode from './kit-list-sortable-node';
import { isSomeKitsSelectable } from '../../../helpers/kit.utils';


export default class KitListSortableHeader extends React.Component {

    render() {
        const kitColumns = this.props.kitColumns;
        const allKitsAreSelected = this.props.selectableKits.length > 0 && this.props.selectableKits.length === this.props.selectedKits.length;

        return (
            <thead>
                <tr>
                    {kitColumns.map(column =>
                        <KitListSortableNode key={column.name} column={column} onOrderByChange={this.props.onOrderByChange} />
                    )}
                    {isSomeKitsSelectable(this.props.kits, this.props.selectableKits)
                        ?
                        <th width="39" className="text-center">
                            <Checkbox
                                onChange={this.props.onSelectAllToggle}
                                isChecked={allKitsAreSelected}
                                classNames={['smaller']}
                                disabled={!isSomeKitsSelectable(this.props.kits, this.props.selectableKits)} />
                        </th>
                        : null}
                </tr>
            </thead>
        );
    }
}

KitListSortableHeader.propTypes = {
    onOrderByChange: PropTypes.func.isRequired,
    onSelectAllToggle: PropTypes.func.isRequired,
    kitColumns: PropTypes.array.isRequired,
    kits: PropTypes.array.isRequired,
    selectedKits: PropTypes.array.isRequired,
    selectableKits: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired
};