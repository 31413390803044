import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../../localization/localization';
import LazyInput from '../../../shared/lazy-input';

export default class KitListFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            pressedKeys: [],
            scanningMode: false
        };
    }

    componentDidMount() {
        if (this.props.query) {
            this.setState({ value: this.props.query });
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.query !== this.props.query && this.state.value !== this.props.query) {
            this.setState({ value: this.props.query });
        }
    }

    onKeyPressed(event, value) {
        if (event.key === "Enter") {
            this.props.onFilterSearchTriggered(value);
        }

        this.handleHotKeys(event.key);
    }

    handleHotKeys(key) {
        const { pressedKeys, scanningMode } = this.state;

        pressedKeys.push(key);

        this.setState({ pressedKeys: pressedKeys }, () => {
            if (this.scanningHotkeys()) {
                this.setState({ scanningMode: !scanningMode });
            }
        });
    }

    scanningHotkeys() {
        const { pressedKeys } = this.state;

        if (pressedKeys.length < 3) {
            return false;
        }

        //gets 3 latest values
        const _pressedKeys = pressedKeys.slice(pressedKeys.length - 3);

        //hotkeys to start scanning
        return _pressedKeys[0] === "Control"
            && _pressedKeys[1] === "Shift"
            && _pressedKeys[2] === "S";
    }

    onClick(callback, value) {
        callback(value);
    }

    onLazyChange(val) {
        let { scanningMode, value } = this.state;

        if (!scanningMode) {
            //change only if it differs from state value
            if (value !== val) {
                this.setState({ value: value }, this.props.onFilterSearchChange(value));
            }
        } else {
            if (value) {
                value += ',';
            }
            this.setState({ value: value }, this.props.onFilterSearchChange(value));
        }
    }

    onChange(value) {
        this.setState({ value: value }, this.props.onFilterSearchChange(value));
    }

    render() {
        return (
            <div className="search-bar" style={{ width: "300px" }}>
                <a className="button icon-search" onClick={() => this.onClick(this.props.onFilterSearchTriggered, this.state.value)} />
                <LazyInput
                    clasNames={['search']}
                    placeholder={this.state.scanningMode ? localization.getLocOrKey("scanKitNo") : localization.getLocOrKey("typeKitNo")}
                    onChange={this.onChange.bind(this)}
                    value={this.state.value}
                    onKeyPressed={this.onKeyPressed.bind(this)}
                    onLazyChange={this.onLazyChange.bind(this)}>
                </LazyInput>
            </div>
        );
    }
}

KitListFilter.propTypes = {
    query: PropTypes.string.isRequired,
    onFilterSearchChange: PropTypes.func.isRequired,
    onFilterSearchTriggered: PropTypes.func.isRequired
};