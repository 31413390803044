import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../localization/localization';
import KitMetricsService from '../../services/metrics.service';
import KitMetricsContainer from './kit-metrics-container';
import { getPropertyValues } from '../../helpers/object.utils';
import { metricsConstants } from '../../constants/constants';

const views = getPropertyValues(metricsConstants, 'initiatedBy');

export default class KitMetricsManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            summaryLoading: true,
            summaryError: '',
            summary: {},
            currentRequest: null
        };
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedCountry !== prevProps.selectedCountry ||
            this.props.selectedSite !== prevProps.selectedSite
        ) {
            this.init();
        }
    }

    reload() {
        this.init();
    }

    init() {
        this.setState({ summaryLoading: true }, this.loadMetrics);
    }

    loadMetrics() {
        var { guid, selectedCountry, selectedSite, initiatedBy } = this.props;

        if (this.state.currentRequest) {
            this.state.currentRequest.abort();
        }

        let request = null;

        switch (initiatedBy) {
            case metricsConstants.initiatedBy.kitList:
                request = KitMetricsService.getSummary(
                    guid,
                    selectedCountry,
                    selectedSite);
                break;
            case metricsConstants.initiatedBy.overview:
                request = KitMetricsService.getOverviewSummary(
                    guid,
                    selectedCountry,
                    selectedSite);
                break;
            default:
                throw new Error('Not implemented');
        }

        this.setState({ currentRequest: request });
        request
            .then(result => {
                this.setState({
                    summaryLoading: false,
                    summary: result,
                    currentRequest: null
                });
            })
            .fail(error => {
                if (error && error.statusText === 'abort') return;
                this.setState({
                    summaryLoading: false,
                    summaryError: loc.rtsmConnectionError,
                    currentRequest: null
                });
            });
    }

    render() {
        return (
            <div className="box-section no-borders padding-0">
                <div className="well shaded">
                    <div className="well-content table-header-wrapper">
                        <KitMetricsContainer {...this.state} />
                    </div>
                </div>
            </div>
        );
    }
}

KitMetricsManager.propTypes = {
    guid: PropTypes.string.isRequired,
    selectedCountry: PropTypes.string.isRequired,
    selectedSite: PropTypes.string.isRequired,
    initiatedBy: PropTypes.oneOf(views).isRequired,
    updateTimestamp: PropTypes.any
};
