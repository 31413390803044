import React from 'react';
import PropTypes from 'prop-types';
import {
    loaderConstants,
    kitActionConstants
} from '../../../constants/constants';
import Select2 from '../../../helpers/react-select2';
import Loader from '../../shared/loader';
import kitActionService from '../../../services/kit-action.service';
import { arraysMatch } from '../../../helpers/array.utils';
import { loc } from '../../../localization/localization';
import { getOptionsAsLocs } from '../../helpers/select2.utils';
import modelValidator from '../../../validators/model-validator';
import KitActionTransfer from './actions/transfer/kit-action-transfer';
import KitActionInvalidate from './actions/kit-action-invalidate';
import KitActionReturn from './actions/kit-action-return';
import KitActionCancel from './actions/kit-action-cancel';
import KitActionReceive from './actions/kit-action-receive';
import KitNoAction from './actions/kit-no-action';
import KitActionQuarantine from './actions/kit-action-quarantine';
import KitActionRestore from './actions/kit-action-restore';
import KitActionEditExpiryDate from './actions/kit-action-edit-expiry-date';
import {
    invalidateModel,
    quarantineModel,
    restoreModel,
    editExpiryDateModel
} from '../../../models/kits/actions';
import { localizeSiteNames } from '../../../helpers/localization.utils';

export default class KitAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        return {
            actionType: null,
            action: null,
            availableKitActions: [],
            invalidateReasons: [],
            availableKitActionsLoading: true,
            availableDestinations: []
        };
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.selectedKits &&
            this.props.selectedKits.length > 0 &&
            !arraysMatch(this.props.selectedKits, prevProps.selectedKits)
        ) {
            this.setState(this.initState(), this.getActionOptions);
        }
    }

    componentDidMount() {
        this.getActionOptions();
    }

    getActionOptions() {
        const { selectedKits } = this.props;

        this.setState({ availableKitActionsLoading: true });
        const kitGuids = selectedKits.map(function(_) {
            return _.kitGuid;
        });

        kitActionService.get(this.props.guid, kitGuids).then(result => {
            const kitActionLocs = getOptionsAsLocs(result.actions);
            const invalidReasons = getOptionsAsLocs(result.invalidateReasons);
            localizeSiteNames(result.locations);
            this.setState({
                availableKitActions: kitActionLocs,
                availableDestinations: result.locations,
                invalidateReasons: invalidReasons,
                availableKitActionsLoading: false
            });
        });
    }

    renderApplybutton() {
        let selectedKits = this.props.selectedKits.length;
        let btnText =
            selectedKits > 1 || selectedKits === 0
                ? loc.applyToSelected + ' (' + selectedKits + ')'
                : loc.apply;

        return (
            <React.Fragment>
                <button
                    id="submit"
                    className={
                        !this.canApplyAction()
                            ? 'button-flat-rounded bg-grey-light border-grey-light'
                            : ''
                    }
                    onClick={this.onApplyKitAction.bind(this)}
                    disabled={!this.canApplyAction()}
                >
                    {btnText}
                </button>
                <div>
                    <Loader
                        loading={this.props.loading}
                        type={loaderConstants.type.medium}
                        style={{ margin: '10px 0' }}
                    ></Loader>
                </div>
            </React.Fragment>
        );
    }

    onApplyKitAction() {
        if (this.props.loading) return;

        const { guid, selectedKits } = this.props;
        const { actionType, action } = this.state;

        const kitsAction = {
            ...action,
            randDefGuid: guid,
            type: actionType,
            kits: selectedKits
        };

        this.props.onApplyKitAction(kitsAction);
    }

    renderAvailableActions() {
        let kitActions = this.renderAvailableKitActions();

        return (
            <div className="field-row">
                <div className="field-item" style={{ width: '100%' }}>
                    <div className="ttl-box">{loc.selectAction}</div>
                    {kitActions}
                </div>
            </div>
        );
    }

    renderAvailableKitActions() {
        return (
            <Select2
                selectProps={{
                    className: 'js-select2-basic',
                    'data-placeholder': '---'
                }}
                id={'kit-action-select'}
                value={this.state.actionType}
                onChange={this.onActionChanged.bind(this)}
                style={{ width: '100%' }}
            >
                <option value=""></option>
                {this.state.availableKitActions.map(_ => (
                    <option key={_.id} value={_.id}>
                        {_.name}
                    </option>
                ))}
            </Select2>
        );
    }

    onActionChanged(event) {
        this.setState({ action: null, actionType: event.target.value });
    }

    onActionUpdated(action) {
        this.setState({ action: action });
    }

    anyActionAvailable() {
        return (
            this.state.availableKitActions &&
            this.state.availableKitActions.length > 0
        );
    }

    renderAction() {
        const actions = kitActionConstants.actions;

        switch (this.state.actionType) {
            case actions.transfer:
                return (
                    <KitActionTransfer
                        locations={this.state.availableDestinations}
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.invalidate:
                return (
                    <KitActionInvalidate
                        model={invalidateModel}
                        invalidReasons={this.state.invalidateReasons}
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.return:
                return (
                    <KitActionReturn
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.cancel:
                return (
                    <KitActionCancel
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.receive:
                return (
                    <KitActionReceive
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.quarantine:
                return (
                    <KitActionQuarantine
                        model={quarantineModel}
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.restore:
                return (
                    <KitActionRestore
                        model={restoreModel}
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            case actions.editExpiryDate:
                return (
                    <KitActionEditExpiryDate
                        model={editExpiryDateModel}
                        onActionUpdated={this.onActionUpdated.bind(this)}
                    />
                );
            default:
                throw new Error('Action does not exists');
        }
    }

    canApplyAction() {
        const { loading } = this.props;
        const { action, actionType } = this.state;

        if (action !== null && actionType !== null && !loading) {
            const actions = kitActionConstants.actions;
            switch (actionType) {
                case actions.transfer:
                    return true;
                case actions.invalidate:
                    return (
                        modelValidator.isPropertyValid(
                            action.validation,
                            'reason',
                            action.reason
                        ) &&
                        modelValidator.isPropertyValid(
                            action.validation,
                            'note',
                            action.note,
                            action.reason
                        )
                    );
                case actions.return:
                    return true;
                case actions.cancel:
                    return true;
                case actions.receive:
                    return true;
                case actions.quarantine:
                    return modelValidator.isPropertyValid(
                        action.validation,
                        'reason',
                        action.reason
                    );
                case actions.restore:
                    return modelValidator.isPropertyValid(
                        action.validation,
                        'reason',
                        action.reason
                    );
                case actions.editExpiryDate:
                    return (
                        modelValidator.isPropertyValid(
                            action.validation,
                            'date',
                            action.date
                        ) &&
                        modelValidator.isPropertyValid(
                            action.validation,
                            'reason',
                            action.reason
                        )
                    );
            }
        }

        return false;
    }

    renderActionsView() {
        if (!this.anyActionAvailable()) return <KitNoAction />;

        const { actionType } = this.state;

        return (
            <React.Fragment>
                {this.renderAvailableActions()}
                {actionType ? this.renderAction() : null}
                {actionType ? this.renderApplybutton() : null}
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Loader
                    loading={this.state.availableKitActionsLoading}
                    type={loaderConstants.type.medium}
                    style={{ marginTop: '20px' }}
                >
                    {this.renderActionsView()}
                </Loader>
            </React.Fragment>
        );
    }
}

KitAction.propTypes = {
    guid: PropTypes.string.isRequired,
    locations: PropTypes.array.isRequired,
    selectedKits: PropTypes.array.isRequired,
    onApplyKitAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};
