import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../localization/localization';
import Loader from '../shared/loader';
import { loaderConstants, kitCssConstants } from '../../constants/constants';
import Error from '../shared/error';

export default class KitMetricsContainer extends React.Component {
    renderContent() {
        var metrics = this.props.summary;

        var validInTransit = metrics.validInTransitCount ? (
            <React.Fragment>
                <i className="icon car-green-left-small valign-middle margin-top-minus-2"></i>
                <h5 className="display-inline-block strong padding-left-4 text-green padding-left-4">
                    <i>+{metrics.validInTransitCount}</i>
                </h5>
            </React.Fragment>
        ) : (
            ''
        );

        return (
            <table className="header with-icons margin-bottom-0">
                <tbody>
                    <tr>
                        <td className="nowrap-text">
                            <i className={`icon ${kitCssConstants.status.valid}-medium valign-middle margin-top-minus-2 margin-right-8`}></i>
                            <strong className="uppercase">
                                {loc.validPlural}
                            </strong>
                            <h3 className="strong text-green margin-left-5 padding-left-5 padding-right-10 display-inline-block">
                                {metrics.validCount}
                            </h3>
                            {validInTransit}
                        </td>
                        <td className="border-left padding-right-0">
                            <ul className="item-list">
                                <li>
                                    <i className={`icon ${kitCssConstants.status.allocated}-small valign-middle margin-top-minus-2 margin-right-5`}></i>
                                    <span className="uppercase">
                                        {loc.allocatedPlural}{' '}
                                    </span>
                                    <h5 className="display-inline-block text-blue text-strong">
                                        {metrics.allocatedCount}
                                    </h5>
                                </li>
                                <li>
                                    <i className={`icon ${kitCssConstants.status.returned}-small valign-middle margin-top-minus-2 margin-right-5`}></i>
                                    <span className="uppercase">
                                        {loc.returnedPlural}{' '}
                                    </span>
                                    <h5 className="display-inline-block text-brown text-strong">
                                        {metrics.returnedCount}
                                    </h5>
                                </li>
                                <li>
                                    <i className={`icon ${kitCssConstants.status.quarantined}-small valign-middle margin-top-minus-2 margin-right-5`}></i>
                                    <span className="uppercase">
                                        {loc.quarantinedPlural}{' '}
                                    </span>
                                    <h5 className="display-inline-block text-brown-light text-strong">
                                        {metrics.quarantinedCount}
                                    </h5>
                                </li>
                                <li>
                                    <i className={`icon ${kitCssConstants.status.expired}-small valign-middle margin-top-minus-2 margin-right-5`}></i>
                                    <span className="uppercase">
                                        {loc.expiredPlural}{' '}
                                    </span>
                                    <h5 className="display-inline-block text-red-strong text-strong">
                                        {metrics.expiredCount}
                                    </h5>
                                </li>
                                <li>
                                    <i className={`icon ${kitCssConstants.status.invalid}-small valign-middle margin-top-minus-2 margin-right-5`}></i>
                                    <span className="uppercase">
                                        {loc.invalidPlural}{' '}
                                    </span>
                                    <h5 className="display-inline-block text-red-strong text-strong">
                                        {metrics.invalidCount}
                                    </h5>
                                </li>
                            </ul>
                        </td>
                        <td className="border-left nowrap-text padding-right-0">
                            <i className={`icon ${kitCssConstants.status.total}-medium valign-middle margin-top-minus-2 margin-right-8`}></i>
                            <strong className="uppercase">{loc.total} </strong>
                            <h3 className="text-black text-strong display-inline-block margin-left-5">
                                {metrics.kitCount}
                            </h3>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    render() {
        var content =
            this.props.summaryLoading || this.props.summaryError
                ? ''
                : this.renderContent();

        return (
            <React.Fragment>
                <Loader
                    loading={this.props.summaryLoading}
                    type={loaderConstants.type.medium}
                >
                    {content}
                </Loader>
                <Error message={this.props.summaryError} />
            </React.Fragment>
        );
    }
}

KitMetricsContainer.propTypes = {
    summaryLoading: PropTypes.bool.isRequired,
    summary: PropTypes.object.isRequired,
    summaryError: PropTypes.string.isRequired
};
