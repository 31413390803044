import React from 'react';
import PropTypes from 'prop-types';
import localization from '../../../../../../localization/localization';
import { Link } from "react-router-dom";

export default class AnimatedModalBody extends React.Component {
    render() {
        const { id } = this.props;

        return (
            <div className="padding-20 padding-top-bottom-30">
                <small className="strong text-grey-dark-lighter" style={{ textTransform: 'uppercase' }} >{localization.getLocOrKey('shipmentId')}</small>
                
                <Link title={id} to={`/kits/${id}`} onClick={this.props.onClose}>
                    <h6 className="strong padding-top-5">{id}</h6>
                </Link>
                <p className="margin-bottom-0">{localization.getLocOrKey('useShipmentId')}</p>
            </div>
        );
    }
}

AnimatedModalBody.propTypes = {
    id: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};