import React from "react";
import PropTypes from 'prop-types';
import Modal from "../shared/modal/modal";
import ModalHeader from "../shared/modal/modal-header";
import ModalBody from "../shared/modal/modal-body";
import { settingsConstants, loaderConstants, locationConstans } from '../../constants/constants';
import Error from "../shared/error";
import SettingsAlertContainer from "./alerts/settings-alert-container";
import alertSettingsService from '../../services/alertSettings.service';
import localization from '../../localization/localization';
import DescriptionTabs from '../shared/description-list/description-tabs';
import DescriptionTab from '../shared/description-list/description-tab';
import DescriptionItems from '../shared/description-list/description-items';
import DescriptionItem from '../shared/description-list/description-item';
import { isLevelValid, isIgnoreLevelValid, getErrorMessagesForLevel, getErrorMessagesForIgnoreLevel } from './alerts/validation/validation';
import ModalError from "../shared/modal/modal-error";
import { isEqual, asNumberOrDefault } from "../../helpers/object.utils";

export default class LocationsSettingsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSetting: settingsConstants.type.alert,
            alertSettings: null,
            originalAlertSettings: null,
            alertSettingsErrors: [],
            loading: true,
            error: '',
            hideIgnoreOptions: true,
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;

        if (!prevProps.isOpen && isOpen) {
            const { activeSetting } = this.state;

            switch (activeSetting) {
                case settingsConstants.type.alert:
                    let hideIgnoreOptions = true; //this.props.location.type === locationConstans.type.depot;
                    this.setState({ hideIgnoreOptions: hideIgnoreOptions }, this.loadAlertSettings);
                    break;
                default:
                    throw new Error('Not implemented');
            }
        }
    }

    loadAlertSettings() {
        this.getAlertSettings().then(result => {
            if (this.props.location.type === locationConstans.type.depot) {
                result.thresholdSettings.forEach(x => {
                    x.enabledIgnore = false;
                    x.ignoreLevel = 0;
                });
            }
            this.setState({ alertSettings: result, originalAlertSettings: result, loading: false }, this.updateErrorMessagesForAlertSettings);
        }
        ).fail(() =>
            this.setState({ error: localization.getLocOrKey('getAlertSettingsError'), loading: false }));
    }

    getAlertSettings() {
        if (this.props.location.hasLocationSpecificSettings) {
            return alertSettingsService.getLocationAlerts(this.props.randGuid, this.props.location.locationGuid);
        }

        return alertSettingsService.get(this.props.randGuid);
    }

    onLevelChanged(id, value) {
        var number = asNumberOrDefault(value, value);

        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                thresholdSettings: prevState.alertSettings.thresholdSettings.map(setting => ({
                    ...setting, notifications: setting.notifications.map(notification =>
                        notification.id === id ? { ...notification, level: number } : notification)
                }))
            }
        }), this.updateErrorMessagesForAlertSettings);
    }

    onIgnoreLevelChanged(id, value) {
        var number = asNumberOrDefault(value, value);

        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                thresholdSettings: prevState.alertSettings.thresholdSettings.map(setting => ({
                    ...setting, notifications: setting.notifications.map(notification =>
                        notification.id === id ? { ...notification, ignoreLevel: number } : notification)
                }))
            }
        }), this.updateErrorMessagesForAlertSettings);
    }

    onLevelEnabledChanged(id) {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                thresholdSettings: prevState.alertSettings.thresholdSettings.map(setting => ({
                    ...setting, notifications: setting.notifications.map(notification =>
                        notification.id === id ? { ...notification, enabled: !notification.enabled } : notification)
                }))
            }
        }), this.updateErrorMessagesForAlertSettings);
    }

    onIgnoreLevelEnabledChanged(id) {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                thresholdSettings: prevState.alertSettings.thresholdSettings.map(setting => ({
                    ...setting, notifications: setting.notifications.map(notification =>
                        notification.id === id ? { ...notification, enabledIgnore: !notification.enabledIgnore } : notification)
                }))
            }
        }), this.updateErrorMessagesForAlertSettings);
    }

    saveAlertSettings(alertSettings) {
        const { randGuid, location } = this.props;
        this.setState({ loading: true }, () => {
            alertSettingsService.saveLocationAlerts(randGuid, location.locationGuid, alertSettings).then(() => {
                this.setState({ loading: false });
                this.props.onSaveModal();
            }).fail(() => {
                this.setState({ loading: false, error: localization.getLocOrKey('couldNotSaveAlertSettings') });
            });
        });

    }

    updateAlertSettings(alertSettings) {
        const { randGuid, location } = this.props;
        this.setState({ loading: true }, () => {
            alertSettingsService.updateLocationAlerts(randGuid, location.locationGuid, alertSettings).then(() => {
                this.setState({ loading: false });
                this.props.onSaveModal();
            }).fail(() => {
                this.setState({ loading: false, error: localization.getLocOrKey('couldNotSaveAlertSettings') });
            });
        });
    }

    isThresholdSettingsValid(thresholdSettings) {
        const isValid = thresholdSettings.every(
            setting => setting.notifications.every(
                notification => isLevelValid(notification) && isIgnoreLevelValid(notification)));
        return isValid;
    }

    updateErrorMessagesForAlertSettings() {
        const { alertSettings } = this.state;
        let messages = [];

        alertSettings.thresholdSettings.map(
            setting => setting.notifications.map(
                notification => !isLevelValid(notification) ? messages.push(getErrorMessagesForLevel(notification)) : null));

        alertSettings.thresholdSettings.map(
            setting => setting.notifications.map(
                notification => !isIgnoreLevelValid(notification) ? messages.push(getErrorMessagesForIgnoreLevel(notification)) : null));

        this.setState({ alertSettingsErrors: messages });
    }

    onSaveModal() {
        const { activeSetting, alertSettings, originalAlertSettings } = this.state;

        switch (activeSetting) {
            case settingsConstants.type.alert:
                if (isEqual(alertSettings, originalAlertSettings) || !this.isThresholdSettingsValid(alertSettings.thresholdSettings)) return;

                if (this.props.location.hasLocationSpecificSettings) {
                    this.updateAlertSettings(alertSettings);
                }
                else {
                    this.saveAlertSettings(alertSettings);
                }
                break;
            default:
                throw new Error('Not implemented');
        }
    }

    render() {
        const { activeSetting, alertSettings, originalAlertSettings, alertSettingsErrors, loading, error, hideIgnoreOptions } = this.state;
        const { isOpen, location, onCloseModal, readOnly } = this.props;

        if (!alertSettings || loading) {
            return null;
        }

        return (
            <React.Fragment>
                <Modal isOpen={isOpen && location !== undefined}
                    classNames={['large']}>
                    <ModalHeader
                        heading={location ? location.name : ''}
                        saveBtnDisabled={!this.isThresholdSettingsValid(alertSettings.thresholdSettings) || loading || isEqual(alertSettings, originalAlertSettings)}
                        onCloseModal={onCloseModal}
                        onSaveModal={this.onSaveModal.bind(this)}>
                        <ModalError errors={alertSettingsErrors} />
                    </ModalHeader>
                    <ModalBody heading={localization.getLocOrKey('viedocLogisticsSettings')}>
                        <DescriptionTabs>
                            <DescriptionTab
                                active={activeSetting === settingsConstants.type.alert}
                                text={localization.getLocOrKey('alerts')}
                                valid={this.isThresholdSettingsValid(alertSettings.thresholdSettings)} />
                        </DescriptionTabs>
                        <DescriptionItems>
                            <DescriptionItem active={activeSetting === settingsConstants.type.alert} >
                                <div className="box-section margin-top-0 with-divider" style={{ margin: 0 }}>
                                    <SettingsAlertContainer settings={alertSettings.thresholdSettings}
                                        onLevelChanged={this.onLevelChanged.bind(this)}
                                        onIgnoreLevelChanged={this.onIgnoreLevelChanged.bind(this)}
                                        onLevelEnabledChanged={this.onLevelEnabledChanged.bind(this)}
                                        onIgnoreLevelEnabledChanged={this.onIgnoreLevelEnabledChanged.bind(this)}
                                        hideIgnoreOptions={hideIgnoreOptions}
                                        readOnly={readOnly}>
                                    </SettingsAlertContainer>
                                </div>
                            </DescriptionItem>
                            <Error message={error}></Error>
                        </DescriptionItems>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

LocationsSettingsModal.propTypes = {
    location: PropTypes.shape({
        type: PropTypes.oneOf([locationConstans.type.site, locationConstans.type.depot]).isRequired,
        hasLocationSpecificSettings: PropTypes.bool.isRequired,
        locationGuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }),
    randGuid: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSaveModal: PropTypes.func.isRequired,
};