import React from 'react';
import PropTypes from 'prop-types';
import KitAction from './kit-action';
import { loc } from '../../../localization/localization';

export default class KitActionContainer extends React.Component {

    render() {
        let selectedKits = this.props.selectedKits;
        let locations = this.props.locations;
        const guid = this.props.guid;
        const headerText = this.props.loading
            ? <div></div>
            : (selectedKits.length > 1 || selectedKits.length === 0) ? selectedKits.length + ' ' + loc.kitsSelected : selectedKits[0].kitNo;

        let view;
        if (selectedKits.length > 0) {
            view = <KitAction selectedKits={selectedKits} locations={locations} guid={guid}
                onApplyKitAction={this.props.onApplyKitAction} loading={this.props.loading} />;
        } else {
            view = <div className="text-center">
                <i className="icon tick-grey-transparent-outline-large padding-top-20" />
                <h6 className="strong text-grey-lighter padding-top-17">
                    <i>{loc.selectOneOrMoreKitsToChangeTheKitStatus}</i>
                </h6>
            </div>;
        }

        return (
            <React.Fragment>
                <header className="bg-blue-light">
                    <h6 className="text-blue strong">{headerText}</h6>
                </header>
                <div className="sidebar-controls-content padding-top-20 padding-left-right-30">
                    {view}
                </div>
            </React.Fragment>
        );
    }
}

KitActionContainer.propTypes = {
    selectedKits: PropTypes.array.isRequired,
    locations: PropTypes.array.isRequired,
    onApplyKitAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};