import React from 'react';
import PropTypes from 'prop-types';

export default class ClickOutisde extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.props.active) {
      this.props.onClick();
    }
  }

  render() {
    return <span ref={this.setWrapperRef}>{this.props.children}</span>;
  }
}

ClickOutisde.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
]).isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};