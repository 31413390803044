import React from 'react';
import { kitConstants, kitCssConstants } from '../../../constants/constants';
import { getPropertyValues } from '../../../helpers/object.utils';
import PropTypes from 'prop-types';

const statuses = getPropertyValues(kitConstants, 'status');
const sizes = getPropertyValues(kitCssConstants, 'size');

const StatusIcon = props => {
    const { status, size } = props;
    return (
        <i
            className={`icon ${kitCssConstants.status[status]}-${kitCssConstants.size[size]} margin-right-5`}
        />
    );
};

StatusIcon.propTypes = {
    size: PropTypes.oneOf(sizes).isRequired,
    status: PropTypes.oneOf(statuses).isRequired
};

export default StatusIcon;
