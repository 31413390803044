import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../topbar';
import KitMenuContainer from '../menu/kit-menu-container';
import ListOverViewManager from './list-overview-manager';
import KitMetricsManager from '../metrics/kit-metrics-manager';
import { loc } from '../../localization/localization';
import locationService from '../../services/location.service';
import Loader from '../shared/loader';
import { loaderConstants, metricsConstants } from '../../constants/constants';
import Error from '../shared/error';
import localization from '../../localization/localization';
import { localizeSiteNames } from '../../helpers/localization.utils';

export default class OverViewManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settingsModalIsOpen: false,
            locations: [],
            loading: true,
            locationErrorMsg: '',
            metricsKey: Math.random(),
            listKey: Math.random(),
            menuSettings: {
                displayContactItem: false,
                displayAddItem: false,
                displaySettingsItem: true
            }
        };
    }

    componentDidMount() {
        this.loadLocations().then(
            () => this.setState({ loading: false }));
    }

    loadLocations() {
        return new Promise((resolve, reject) => {
            locationService.getForOverview().then(locations => {
                this.setState({ locations: localizeSiteNames(locations) }, resolve);
            }).fail(() =>
                this.setState({
                    locationErrorMsg: localization.getLocOrKey('couldNotFetchLocations')
                }, reject));
        });
    };

    onOpenSettingsModal() {
        this.setState({ settingsModalIsOpen: true });
    }

    onCloseSettingsModal() {
        this.setState({ settingsModalIsOpen: false });
    }

    onSaveSettingsModal() {
        this.onCloseSettingsModal();
        //changing the keys to force a reload on save
        this.setState({ metricsKey: Math.random(), listKey: Math.random() });
    }

    render() {
        return (
            <React.Fragment>
                <Error message={this.state.locationErrorMsg}>
                    <Loader loading={this.state.loading}
                        position={loaderConstants.position.horizontallyCenter}
                        type={loaderConstants.type.medium} >
                        <TopBar title={loc.studySupplyOverviewTitle} />
                        <KitMenuContainer
                            locations={this.state.locations}
                            selectedCountry={this.props.selectedCountry}
                            selectedSite={this.props.selectedSite}
                            onCountryChange={this.props.onCountryChange}
                            onSiteChange={this.props.onSiteChange}
                            onOpenSettingsModal={this.onOpenSettingsModal.bind(this)}
                            menuSettings={this.state.menuSettings}
                        />
                        <div className="content-container">
                            <KitMetricsManager key={this.state.metricsKey}
                                initiatedBy={metricsConstants.initiatedBy.overview}
                                {...this.props}
                                {...this.state} />
                            <div className="box-section no-borders padding-0">
                                <div className="well">
                                    <ListOverViewManager
                                        key={this.state.listKey}
                                        {...this.props}
                                        {...this.state}
                                        onCloseModal={this.onCloseSettingsModal.bind(this)}
                                        onSaveModal={this.onSaveSettingsModal.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </Loader>
                </Error>
            </React.Fragment>
        );
    }
}

OverViewManager.propTypes = {
    guid: PropTypes.string.isRequired,
    selectedCountry: PropTypes.string.isRequired,
    selectedSite: PropTypes.string.isRequired,
    studyPermissions: PropTypes.shape({
        isStudyManagerWithEditPermission: PropTypes.bool.isRequired,
        isStudyManagerWithViewPermission: PropTypes.bool.isRequired
    }).isRequired,
    onCountryChange: PropTypes.func.isRequired,
    onSiteChange: PropTypes.func.isRequired
};
