import modelValidator from '../../../../validators/model-validator';

export const isNumberOfAllocationsPerSubjectValid = (kitTypeSetting) => {
    const isValid = modelValidator.isPropertyValid(kitTypeSetting.validation, 'numberOfAllocationsPerSubject', kitTypeSetting.numberOfAllocationsPerSubject);
    return isValid;
};

export const isAutomaticInvalidationValid = (automaticInvalidation) => {
    if (!automaticInvalidation.enabled) {
        const isValid = modelValidator.isPropertyValid(automaticInvalidation.validation, 'value', automaticInvalidation.value);
        return isValid;
    }
    if (automaticInvalidation.enabled && automaticInvalidation.value) {
        const isValid = modelValidator.isPropertyValid(automaticInvalidation.validation, 'value', automaticInvalidation.value);
        return isValid;
    }

    return false;
};

export const isExpiryPeriodValid = (expiryPeriod) => {
    const isValid = modelValidator.isPropertyValid(expiryPeriod.validation, 'value', expiryPeriod.value, expiryPeriod.enabled);
    return isValid;
};

export const getErrorMessagesForAutomaticInvalidation = (automaticInvalidation) => {
    const messages = modelValidator.getErrorMessages(automaticInvalidation.validation, 'value');
    return messages;
};

export const getErrorMessagesForNumberOfAllocationsPerSubject = (kitTypeSetting) => {
    const messages = modelValidator.getErrorMessages(kitTypeSetting.validation, 'numberOfAllocationsPerSubject');
    return messages;
};

export const getErrorMessagesForExpiryPeriod = (expiryPeriod) => {
    return modelValidator.getErrorMessagesForInvalidValidations(expiryPeriod.validation, 'value', expiryPeriod.value, expiryPeriod.enabled);
};

