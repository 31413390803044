import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../../../localization/localization';
import KitActionNote from './kit-action-note';
import Select2 from '../../../../helpers/react-select2';
import modelValidator from '../../../../validators/model-validator';
import { kitActionConstants } from '../../../../constants/constants';

export default class KitActionInvalidate extends React.Component {

  componentDidMount() {
    const { model } = this.props;
    this.setState(model, this.onActionUpdated);
    this.setState({ reason: this.props.invalidReasons[0].id }, this.onActionUpdated);
  }

  onNoteTextChanged(value) {
    this.setState({ note: value }, this.onActionUpdated);
  }

  onInvalidReasonChanged(value) {
    this.setState({ reason: value }, this.onActionUpdated);
  }

  onActionUpdated() {
    this.props.onActionUpdated({ ...this.state });
  }

  renderComponent() {
    return (
      <React.Fragment>
        <div className="field-row">
          <div className="field-item" style={{ width: '208px' }}>
            <div className="ttl-box">{loc.selectReason}</div>
            <Select2
              id={'kit-invalidate-reason-select'}
              value={this.state.reason}
              onChange={event => this.onInvalidReasonChanged(event.target.value)}
              style={{ width: '100%' }}
            >
              {this.props.invalidReasons.map(_ => (
                <option key={_.id} value={_.id}>
                  {_.name}
                </option>
              ))}
            </Select2>
          </div>
        </div>
        <KitActionNote
          noteText={this.state.note}
          onNoteTextChanged={this.onNoteTextChanged.bind(this)}
          isValid={modelValidator.isPropertyValid(this.state.validation, 'note', this.state.note, this.state.reason)}
          isRequired={this.state.reason === kitActionConstants.invalidateReason.other}
        />
      </React.Fragment>
    );
  }

  render() {
    // eslint-disable-next-line eqeqeq
    return this.state != null ? this.renderComponent() : null;
  }
}

KitActionInvalidate.propTypes = {
  invalidReasons: PropTypes.array.isRequired,
  onActionUpdated: PropTypes.func.isRequired,
  model: PropTypes.shape({
    reason: PropTypes.string,
    note: PropTypes.string,
    validation: PropTypes.shape({
      reason: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired
      })
      ).isRequired,
      note: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired
      })
      ).isRequired,
    }).isRequired
  }).isRequired
};