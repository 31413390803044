import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../localization/localization';
import ActionLink from './actionlink';


export default class Paging extends React.Component {

    render() {
        let fetchedCount = this.props.itemsFetchedCount;
        let pageIndex = this.props.pageIndex;
        let pageSize = this.props.pageSize;
        let totalCount = this.props.itemsTotalCount;

        let from = pageIndex * pageSize + 1;
        let to = (from - 1) + fetchedCount;

        let previousEnabled = pageIndex > 0 && fetchedCount > 0;
        let nextEnabled = (pageIndex + 1) * pageSize < totalCount;

        let showingLabel;
        let previousLink;
        let nextLink;

        if (fetchedCount > 0) {
            showingLabel = <span>{loc.showing} {from} - {to} {loc.of} {totalCount}</span>;
        } else {
            showingLabel = <span>{loc.showing} 0 {loc.of} 0</span>;
        }

        if (previousEnabled) {
            previousLink = <ActionLink title="Previous" onClick={() => this.props.onPageIndexChanged(pageIndex - 1)}>{loc.previous}</ActionLink>;
        } else {
            previousLink = <span className="text-grey">{loc.previous}</span>;
        }

        if (nextEnabled) {
            nextLink = <ActionLink title="Next" onClick={() => this.props.onPageIndexChanged(pageIndex + 1)}>{loc.next}</ActionLink>;
        } else {
            nextLink = <span className="text-grey">{loc.next}</span>;
        }

        return (
            <React.Fragment>
                {showingLabel} | {previousLink} | {nextLink}
            </React.Fragment>
        );
    }
}

Paging.propTypes = {
    itemsFetchedCount: PropTypes.number.isRequired,
    itemsTotalCount: PropTypes.number.isRequired,
    pageIndex: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageIndexChanged: PropTypes.func.isRequired
};