import baseService from './base.service';

const url = baseService.baseUrl + "/kitmetrics";

function get(randGuid, countryCode, siteCode, pageIndex, pageSize,) {
    const obj = { randGuid, countryCode, siteCode, pageIndex, pageSize };
    return baseService.getWithParameters(url, obj);
}

function getSummary(randGuid, countryCode, siteCode) {
    const obj = { randGuid, countryCode, siteCode };
    return baseService.getWithParameters(`${url}/summary`, obj);
}

function getOverviewSummary(randGuid, countryCode, siteCode) {
    const obj = { randGuid, countryCode, siteCode };
    return baseService.getWithParameters(`${url}/overviewsummary`, obj);
}

function getColumns(randGuid) {
    const obj = { randGuid };
    return baseService.getWithParameters(url + '/columns', obj);
}

var service = {
    get: get,
    getColumns: getColumns,
    getSummary: getSummary,
    getOverviewSummary: getOverviewSummary
};

export default service;