import React from 'react';
import PropTypes from 'prop-types';
import Error from './error';
import rtsmConnectionErrorImage from '../../static/images/rtsm-connection-error.png';

export default class FullPageError extends React.Component {

    renderError() {
        return (
            <div className="bg-grey-silver-lighter-v2 full-screen-height">
                <div className="text-center padding-top-45">
                    <img src={rtsmConnectionErrorImage} width="153" height="75" className="padding-top-45" alt="RTSM connection error" />
                    <Error message={this.props.message}></Error>
                </div>
            </div>
        );
    }
    render() {
        let content = !this.props.message
            ? <div></div>
            : this.renderError();

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }
}

FullPageError.propTypes = {
    message: PropTypes.string.isRequired,
};