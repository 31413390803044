import {
    jsonRequiredAttribute,
    jsonConditionRegularExpression
} from './constants';
import { regexConstants } from '../constants/constants';

const createModelWithValidation = obj => {
    if (!obj) {
        throw new Error('Object is null or undefined or has no properties');
    }

    let result = { validation: {} };
    for (const property in obj) {
        result[property] = obj[property].value;
        if (obj[property].required === true) {
            result.validation[[property]] = [
                createRequiredObj(obj[property].errorMessage)
            ];
        }
        if (obj[property].conditionPropertyName) {
            result.validation[[property]] = [
                createConditionalRequiredObj(
                    obj[property].conditionValue,
                    obj[property].errorMessage
                )
            ];
        }
    }

    return result;
};

const createRequiredObj = errorMessage => {
    return {
        key: jsonRequiredAttribute,
        errorMessage: errorMessage
    };
};

const createConditionalRequiredObj = (
    conditionValue,
    errorMessage,
    value = regexConstants.notEmptyOrWhitespace
) => {
    return {
        key: jsonConditionRegularExpression,
        errorMessage: errorMessage,
        conditionValue: conditionValue,
        value: value
    };
};

const modelValidatorCreator = {
    createModelWithValidation
};

export default modelValidatorCreator;
