import React from "react";
import PropTypes from 'prop-types';
import Select2 from "../../helpers/react-select2";
import { loc } from '../../localization/localization';
import { removeDuplicates } from "../../helpers/array.utils";

export default class KitLocationFilter extends React.Component {

    countrySorter(c1, c2) {
        return c1.country < c2.country ? -1 : c1.country > c2.country ? 1 : 0;
    }

    onCountryChange(value) {
        const { selectedCountry } = this.props;

        if (selectedCountry !== value) {
            this.props.onCountryChange(value);
            this.onSiteChange('');
        }

    }

    onSiteChange(value) {
        const { selectedSite } = this.props;

        if (selectedSite !== value) {
            this.props.onSiteChange(value);
        }
    }

    render() {
        let locations = this.props.locations;
        let selectedCountry = this.props.selectedCountry;
        let selectedSite = this.props.selectedSite;
        let countryCode = this.props.selectedCountry;
        let countries = locations
            .filter((location) => location.countryCode)
            .map((location) => {
                return {
                    countryCode: location.countryCode,
                    country: location.country
                };
            });

        //removes duplicates because every site is connected to a country, and there might be multiple countries
        countries = removeDuplicates(countries, "countryCode").sort(this.countrySorter);
        let sites = locations.filter((v) => !countryCode || v.countryCode === countryCode);

        if (countries.length > 1) {
            countries.splice(0, 0, { countryCode: "", country: loc.allCountries });
        }

        if (sites.length > 1) {
            sites.splice(0, 0, { siteCode: "", location: loc.allSites });
        }

        return (
            <div>
                <dd>
                    <Select2 id={'country-select'} data-placeholder="Country" value={selectedCountry}
                        onChange={event => { this.onCountryChange(event.target.value); }}
                        selectProps={{ "data-container-css-class": "select2-tab", "data-dropdown-css-class": "in-icon-tab" }}>
                        {countries.map(country => {
                            return <option key={country.countryCode} value={country.countryCode}>{country.country}</option>;
                        })}
                    </Select2>
                </dd>
                <dd>
                    <Select2 id={'site-select'} data-placeholder="City" value={selectedSite}
                        onChange={event => this.onSiteChange(event.target.value)}
                        selectProps={{ "data-container-css-class": "select2-tab", "data-dropdown-css-class": "in-icon-tab" }}>
                        {sites.map(site => {
                            return <option key={site.siteCode} value={site.siteCode}>{site.location}</option>;
                        })}
                    </Select2>
                </dd>
            </div>
        );
    }
}

KitLocationFilter.propTypes = {
    locations: PropTypes.array,
    selectedCountry: PropTypes.string,
    selectedSite: PropTypes.string,
    onSiteChange: PropTypes.func.isRequired,
    onCountryChange: PropTypes.func.isRequired,
};