import React from 'react';
import { Link } from "react-router-dom";
import ListOverviewKitRow from './list-overview-kit-row';
import { alertSettingsCssConstants, alertConstants, locationConstans } from '../../../../constants/constants';
import PropTypes from 'prop-types';
import { loc } from '../../../../localization/localization';

export default class ListOverviewSiteRow extends React.Component {

    getBorderColorForAlert(metrics) {
        const warning = metrics.find(metric => metric.alertType === alertConstants.type.warning);
        const notification = metrics.find(metric => metric.alertType === alertConstants.type.notification);

        if (warning) {
            return alertSettingsCssConstants.rowColor.warning;
        } if (notification) {
            return alertSettingsCssConstants.rowColor.notification;
        }

        return '';
    }

    renderRowSpanContent(item, numberOfKits, countryCode) {
        var settings = item.location.type === locationConstans.type.centralDepot 
        ? '' 
        : <a href="#" onClick={() => this.props.onOpenLocationSettingsModal(item.location, item.hasLocationSpecificSettings)} title={loc.siteSettings}><i className={`icon site-settings ${item.hasLocationSpecificSettings ? 'active' : ''}`}></i></a>;

        return (
            <React.Fragment>
                <td rowSpan={numberOfKits} className={`status-color ${this.getBorderColorForAlert(item.metrics)}`}></td>
                <td rowSpan={numberOfKits}>{item.sequenceNumber + 1}</td>
                <td rowSpan={numberOfKits} className="padding-right-40">
                    <strong>
                        <Link title={item.location.name} to={`/kits/${countryCode}/${item.location.locationGuid}`}>{item.location.name}</Link>
                    </strong>
                    {settings}
                </td>
            </React.Fragment>
        );
    }

    render() {
        const { item } = this.props;
        const numberOfKits = item.metrics.length;
        //Central depot dont have a countrycode, and cant pass empty string as a parameter
        const countryCode = item.location.countryCode === '' ? '0' : item.location.countryCode;

        return (
            <React.Fragment>
                {item.metrics.map((kitMetrics, index) => {
                    return (
                        <tr key={index}>
                            {index === 0 ? this.renderRowSpanContent(item, numberOfKits, countryCode) : null}
                            <ListOverviewKitRow kitMetrics={kitMetrics} />
                        </tr>
                    );
                })}
            </React.Fragment>
        );
    }
}

ListOverviewSiteRow.propTypes = {
    item: PropTypes.object.isRequired,
    onOpenLocationSettingsModal: PropTypes.func.isRequired,
};

