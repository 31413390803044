import React from 'react';
import PropTypes from 'prop-types';
import KitActionReason from './kit-action-reason';
import modelValidator from '../../../../validators/model-validator';

export default class KitActionRestore extends React.Component {

    componentDidMount() {
        const { model } = this.props;
        this.setState(model, this.onActionUpdated);
    }

    onActionUpdated() {
        this.props.onActionUpdated({ ...this.state });
    }

    onReasonValueChanged(value) {
        this.setState({ reason: value }, this.onActionUpdated);
    }

    renderComponent() {
        return (
            <React.Fragment>
                <KitActionReason
                    value={this.state.reason}
                    onValueChanged={this.onReasonValueChanged.bind(this)}
                    isValid={modelValidator.isPropertyValid(this.state.validation, 'reason', this.state.reason)}
                />
            </React.Fragment>
        );
    }

    render() {
        // eslint-disable-next-line eqeqeq
        return this.state != null ? this.renderComponent() : null;
    }
}

KitActionRestore.propTypes = {
    onActionUpdated: PropTypes.func.isRequired,
    model: PropTypes.shape({
        reason: PropTypes.string,
        validation: PropTypes.shape({
            reason: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string.isRequired
            })
            ).isRequired
        }).isRequired
    }).isRequired
};
