import React from 'react';
import PropTypes from 'prop-types';
import { loc } from '../../../../localization/localization';
import modelValidator from '../../../../validators/model-validator';
import KitActionReason from './kit-action-reason';
import CustomDatePicker from '../../../shared/date-picker';

export default class KitActionEditExpiryDate extends React.Component {

    componentDidMount() {
        const { model } = this.props;
        this.setState(model, this.onActionUpdated);
    }

    onActionUpdated() {
        this.props.onActionUpdated({ ...this.state });
    }

    onDateChanged(value) {
        this.setState({ date: value }, this.onActionUpdated);
    }

    onReasonValueChanged(value) {
        this.setState({ reason: value }, this.onActionUpdated);
    }

    renderComponent() {
        const { validation, date, note } = this.state;

        return (
            <React.Fragment>
                <div className="field-row items">
                    <div className="field-item">
                        <div className="ttl-box">{loc.editExpiryDate}</div>
                        <div className="text-area">
                            <CustomDatePicker
                                onChange={date => this.onDateChanged(date)}
                                wrapperClassName={modelValidator.isPropertyValid(validation, 'date', date) || 'hasError'}
                                selected={date || ''}
                                dateFormat={'yyyy-MM-dd'}
                                placeholderText={'yyyy-MM-dd'}
                                disableManualInput
                            />
                        </div>
                    </div>
                </div>
                <KitActionReason
                    value={this.state.reason}
                    onValueChanged={this.onReasonValueChanged.bind(this)}
                    isValid={modelValidator.isPropertyValid(this.state.validation, 'reason', this.state.reason)}
                />
            </React.Fragment>
        );
    }

    render() {
        // eslint-disable-next-line eqeqeq
        return this.state != null ? this.renderComponent() : null;
    }
}

KitActionEditExpiryDate.propTypes = {
    onActionUpdated: PropTypes.func.isRequired,
    model: PropTypes.shape({
        date: PropTypes.string,
        reason: PropTypes.string,
        validation: PropTypes.shape({
            date: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string.isRequired
            })
            ).isRequired,
            reason: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.string.isRequired
            })
            ).isRequired,
        }).isRequired
    }).isRequired
};
