import baseService from './base.service';

const url = baseService.baseUrl + "/kits";

function get(randGuid, countryCode, siteCode, statusFilter, search, pageIndex, pageSize, orderBy, direction) {
    let obj = { randGuid, countryCode, siteCode, pageIndex, pageSize };

    // Only add optional parameters to object if the're defined and not null
    if (statusFilter) {
        obj.statusFilter = statusFilter;
    }
    if (search) {
        obj.search = search;
    }

    if (orderBy) {
        obj.orderBy = orderBy;
    }
    if (direction) {
        obj.direction = direction;
    }

    return baseService.getWithParameters(url + '/list', obj);
}

function getSelectableKits(randGuid, kits) {
    const obj = { guid: randGuid, kits: kits.map(_ => _.kitGuid) };
    return baseService.post(url + '/selectableKits', obj);
}

function getKit(randGuid, kitGuid) {
    const obj = { randGuid, kitGuid };
    return baseService.getWithParameters(url, obj);
}

function getColumns(randGuid) {
    const obj = { randGuid };
    return baseService.getWithParameters(url + '/columns', obj);
}

function getContext() {
    return baseService.get("/api/context");
}

function getHistory(randGuid, kitGuid) {
    const obj = { randGuid, kitGuid };
    return baseService.getWithParameters(url + '/history', obj);
}

var service = {
    get: get,
    getSelectableKits: getSelectableKits,
    getColumns: getColumns,
    getKit: getKit,
    getContext: getContext,
    getHistory: getHistory
};

export default service;