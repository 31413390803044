import React from 'react';
import PropTypes from 'prop-types';
import { alertSettingsCssConstants, alertConstants } from '../../../../constants/constants';
import { getPropertyValues } from '../../../../helpers/object.utils';

const alertConstantTypes = getPropertyValues(alertConstants, 'type');

export default class ListOverviewKitRow extends React.Component {

    getKitInTransit(inTransitTo, inTransitFrom) {
        var transitTo = inTransitTo && inTransitTo > 0
            ? (
                <span className="nowrap-text">
                    <i className="icon car-green-left-small margin-right-5"></i>
                    <small className="display-inline strong text-green"><i>+{inTransitTo}</i></small>
                </span>
            )
            : '';

        var transitFrom = inTransitFrom && inTransitFrom > 0
            ? (
                <span className="nowrap-text">
                    <i className={`icon car-grey-right-small margin-right-5 ${inTransitTo && inTransitTo > 0 ? 'margin-left-10' : ''}`}></i>
                    <small className="display-inline strong text-grey"><i>{inTransitFrom}</i></small>
                </span>
            )
            : '';

        return (
            <React.Fragment>
                {transitTo} {transitFrom}
            </React.Fragment>
        );
    }

    getAlertIcon(alertType) {
        if (alertType !== alertConstants.type.notSet) {
            return <i className={`icon margin-top-minus-2 margin-right-5 ${alertSettingsCssConstants.storageIndicator[alertType]}`}></i>;
        }
    }

    render() {
        const { kitMetrics } = this.props;

        let aggregatedInvalid = '';
        if (kitMetrics.invalidAggregated && kitMetrics.invalidAggregated > 0) {
            aggregatedInvalid = (
                <small className="display-inline text-grey margin-right-10">
                    (<span className="text-burgundy">{kitMetrics.invalidAggregated}</span>)
            </small>);
        }

        var validInTransitTo = this.getKitInTransit(kitMetrics.validInTransitToCount, kitMetrics.validInTransitFromCount);
        var allocatedInTransitTo = this.getKitInTransit(kitMetrics.allocatedInTransitToCount, kitMetrics.allocatedInTransitFromCount);
        var returnedInTransitTo = this.getKitInTransit(kitMetrics.returnedInTransitToCount, kitMetrics.returnedInTransitFromCount);
        var invalidInTransitTo = this.getKitInTransit(kitMetrics.invalidInTransitToCount, kitMetrics.invalidInTransitFromCount);
        var quarantinedInTransit = this.getKitInTransit(kitMetrics.quarantinedInTransitToCount, kitMetrics.quarantinedInTransitFromCount);
        var expiredInTransit = this.getKitInTransit(kitMetrics.expiredInTransitToCount, kitMetrics.expiredInTransitFromCount);

        return (
            <React.Fragment>
                <td className="border-left-visible">
                    {kitMetrics.kitItem}
                </td>
                <td>
                    {this.getAlertIcon(kitMetrics.alertType)}
                    <strong className="display-inline-block margin-right-10">{kitMetrics.validCount}</strong>
                    {validInTransitTo}
                </td>
                <td>
                    <span className="display-inline-block margin-right-10">{kitMetrics.allocatedCount}</span>
                    {allocatedInTransitTo}
                </td>
                <td>
                    <span className="display-inline-block margin-right-10">{kitMetrics.returnedCount}</span>
                    {returnedInTransitTo}
                </td>
                <td>
                    <span className="display-inline-block margin-right-10">{kitMetrics.quarantinedCount}</span>
                    {quarantinedInTransit}
                </td>
                <td>
                    <span className="display-inline-block margin-right-10">{kitMetrics.expiredCount}</span>
                    {expiredInTransit}
                </td>
                <td>
                    <span className={`display-inline-block margin-right-10 ${kitMetrics.invalidCount > 0 ? 'text-burgundy' : ''}`}>{kitMetrics.invalidCount} </span>
                    {aggregatedInvalid}
                    {invalidInTransitTo}
                </td>
                <td>{kitMetrics.kitCount}</td>
            </React.Fragment>
        );
    }
}

ListOverviewKitRow.propTypes = {
    kitMetrics: PropTypes.PropTypes.shape({
        kitItem: PropTypes.string.isRequired,
        alertType: PropTypes.oneOf(alertConstantTypes).isRequired,
        ear: PropTypes.number.isRequired,
        eat: PropTypes.number.isRequired,
        validCount: PropTypes.number.isRequired,
        validInTransitToCount: PropTypes.number.isRequired,
        validInTransitFromCount: PropTypes.number.isRequired,
        validTotalCount: PropTypes.number.isRequired,
        allocatedCount: PropTypes.number.isRequired,
        allocatedInTransitToCount: PropTypes.number.isRequired,
        allocatedInTransitFromCount: PropTypes.number.isRequired,
        allocatedTotalCount: PropTypes.number.isRequired,
        returnedCount: PropTypes.number.isRequired,
        returnedInTransitToCount: PropTypes.number.isRequired,
        returnedInTransitFromCount: PropTypes.number.isRequired,
        returnedTotalCount: PropTypes.number.isRequired,
        invalidCount: PropTypes.number.isRequired,
        invalidInTransitToCount: PropTypes.number.isRequired,
        invalidInTransitFromCount: PropTypes.number.isRequired,
        invalidTotalCount: PropTypes.number.isRequired,
        quarantinedCount: PropTypes.number.isRequired,
        quarantinedInTransitToCount: PropTypes.number.isRequired,
        quarantinedInTransitFromCount: PropTypes.number.isRequired,
        expiredCount: PropTypes.number.isRequired,
        expiredInTransitToCount: PropTypes.number.isRequired,
        expiredInTransitFromCount: PropTypes.number.isRequired,
        kitCount: PropTypes.number.isRequired,
        invalidAggregated: PropTypes.number.isRequired,
    }).isRequired
};