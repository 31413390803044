import DatePicker, { registerLocale } from 'react-datepicker';
import { getLanguageCode } from '../../helpers/storage.utils';
import PropTypes from 'prop-types';
import React from 'react';
import enUs from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import ja from 'date-fns/locale/ja';
import pl from 'date-fns/locale/pl';
import pt from 'date-fns/locale/pt';
import zhCn from 'date-fns/locale/zh-CN';
import sv from 'date-fns/locale/sv';

registerLocale('en-US', enUs);
registerLocale('de-DE', de);
registerLocale('es-ES', es);
registerLocale('fr-FR', fr);
registerLocale('ja-JP', ja);
registerLocale('sv-SE', sv);
registerLocale('pl-PL', pl);
registerLocale('zh-CN', zhCn);
registerLocale('pt-PT', pt);

export default class CustomDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.selected
        };

        //Make max date parsable for .Net
        this.defaultMaxDate = new Date(9999, 1, 1);
    }

    onHandleDateChangeRaw(e) {
        if (this.props.disableManualInput) {
            e.preventDefault();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selected !== prevProps.selected) {
            let date = this.props.selected;

            if (!this.props.useLocalDate && date !== null) {
                //If not using localDate the input will be in UTC
                //Thereby the conversion from utc to local date
                date = new Date(
                    date.getUTCFullYear(),
                    date.getUTCMonth(),
                    date.getUTCDate()
                );
            }

            this.setState({
                date: date
            });
        }
    }

    render() {
        let maxDate = this.props.maxDate
            ? this.props.maxDate
            : this.defaultMaxDate;

        return (
            <DatePicker
                {...this.props}
                locale={getLanguageCode()}
                onChange={this.onChange.bind(this)}
                onChangeRaw={this.onHandleDateChangeRaw.bind(this)}
                selected={this.state.date}
                maxDate={maxDate}
                //Ensure that the datepicker stays visible on the screen
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: 'viewport'
                    }
                }}
            />
        );
    }

    onChange(value) {
        let date = value;
        if (!this.props.useLocalDate && date !== null) {
            //Convert to UTC date
            date = new Date(
                Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            );
        }
        this.props.onChange(date);
    }
}

CustomDatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]).isRequired,
    useLocalDate: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    disableManualInput: PropTypes.bool
};
