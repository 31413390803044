import _ from 'lodash';

export const getPropertyValues = (obj, property) => {
    let values = [];
        for (var value in obj[property]) {
            values.push(value);
        }
    
    return values;
};

export const isEmpty = (obj) => {
    return _.isEmpty(obj, true);
};

export const isEqual = (x, y) => {
    return _.isEqual(x, y);
};

export const clone = (obj) => {
    return _.clone(obj);
};

export const asNumberOrDefault = (value, defaultValue = 0) => {
    if(_.isEmpty(value)) return defaultValue;
    if(_.endsWith(value, ',') || _.endsWith(value, '.')) return defaultValue;

    var number = _.toNumber(value);
    return isNaN(number) ? defaultValue : number;
};

export const remove = (arr, predicate) => {
    _.remove(arr, predicate);
    return arr;
};
