export const arraysMatch = function (arr1, arr2) {

	// Check if the arrays are the same length
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;

};

function findIndexWithAttr(array, attr, value) {
	for (var i = 0; i < array.length; i += 1) {
		if (array[i][attr] === value) {
			return i;
		}
	}
	return -1;
};

export const toggleArray = (array, attr, obj) => {
	let toggledArray = [...array];
	let index = findIndexWithAttr(array, attr, obj[attr]);

	if (index === -1) {
		toggledArray.push(obj);
	} else {
		toggledArray.splice(index, 1);
	}

	return toggledArray;
};

export const toggleArrays = (mainArray, compareArray, attr) => {
	let toggledArray = [];

	for (let i = 0; i < compareArray.length; i++) {
		let index = findIndexWithAttr(mainArray, attr, compareArray[i][attr]);

		if (index !== -1) {
			toggledArray.push(compareArray[i]);
		}
	}

	return toggledArray;
};

export const trimArray = (array) => {
	let filteredArray = array.filter(function (el) {
		return el !== '';
	});

	return filteredArray;
};

export const removeDuplicates = (myArr, prop) => {
	return myArr.filter((obj, pos, arr) => {
		return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
	});
};