import React from 'react';
import PropTypes from 'prop-types';
import ActionLink from '../../shared/actionlink';
import { loc } from '../../../localization/localization';

export default class ModalHeader extends React.Component {

    onSaveModal() {
        if (!this.props.saveBtnDisabled) {
            this.props.onSaveModal();
        }
    }

    renderSaveBtn() {
        const { saveBtnDisabled } = this.props;

        return (
            saveBtnDisabled
                ? null
                :
                <ActionLink title={loc.saveChanges}
                    onClick={() => this.onSaveModal()}>
                    {loc.saveChanges}
                </ActionLink>
        );
    }

    render() {
        return (
            <header className="main">
                <nav className="blue">
                    <ul className="nav-bar nolinks nodividers">
                        <li><span className="icon tools-white-medium"></span>{this.props.heading}</li>
                    </ul>
                    <ul className="actions">
                        <li>
                            {this.renderSaveBtn()}
                        </li>
                        <li>
                            <ActionLink title={loc.close}
                                className={'close'}
                                onClick={() => this.props.onCloseModal()}>
                                <i className="close" />
                                {loc.close}
                            </ActionLink>
                        </li>
                    </ul>
                </nav>
                {this.props.children}
            </header>
        );
    }
}

ModalHeader.propTypes = {
    heading: PropTypes.string.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSaveModal: PropTypes.func,
    saveBtnDisabled: PropTypes.bool
};