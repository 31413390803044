import React from "react";
import PropTypes from 'prop-types';

const DescriptionItem = (props) => {
    const { active } = props;

    return (
        <li className={active ? 'active' : ''}>
            {active ? props.children : null}
        </li>
    );

};

export default DescriptionItem;

DescriptionItem.propTypes = {
    active: PropTypes.bool.isRequired
};