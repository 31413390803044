import React from 'react';
import ListOverviewContainer from './list/list-overview-container';
import KitMetricsService from '../../services/metrics.service';
import PropTypes from 'prop-types';
import { loc } from '../../localization/localization';
import { recountPageIndex } from '../../helpers/paging.utils';
import SettingsModal from '../settings/settings-modal';
import LocationsSettingsModal from '../settings/location-settings-modal';
import { isReadOnlyStudyManager } from '../../permissions/user-permissions';
import { localizeSiteNamesForKits } from '../../helpers/localization.utils';

export default class ListOverViewManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            headerColumns: [],
            metrics: {},
            loading: true,
            error: '',
            pageIndex: 0,
            pageSize: 10,
            locationSettingsModalIsOpen: false,
            selectedLocation: null,
            currentRequest: null
        };
    }

    onPageSizeChanged(value) {
        var pageIndex = this.state.pageIndex;

        if (this.state.metrics) {
            pageIndex = recountPageIndex(
                pageIndex,
                value,
                this.state.metrics.locationCount
            );
        }

        if (pageIndex !== this.state.pageIndex) {
            this.setState({ pageSize: value, pageIndex: pageIndex }, this.init);
        } else {
            this.setState({ pageSize: value }, this.init);
        }
    }

    onPageIndexChanged(value) {
        this.setState({ pageIndex: value }, this.init);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedCountry !== this.props.selectedCountry ||
            prevProps.selectedSite !== this.props.selectedSite
        ) {
            this.init();
        }
    }

    componentDidMount() {
        const { guid } = this.props;
        KitMetricsService.getColumns(guid)
            .then(result => {
                this.setState({
                    headerColumns: result
                });
            })
            .fail(error => {
                this.setState({
                    error: loc.logisticsConnectionError + ' ' + error.status
                });
            });
        this.init();
    }

    init() {
        this.setState({ loading: true }, this.loadMetrics);
    }

    loadMetrics() {
        const { guid, selectedSite, selectedCountry } = this.props;
        const { pageIndex, pageSize } = this.state;

        if (this.state.currentRequest) {
            this.state.currentRequest.abort();
        }

        let request = KitMetricsService.get(
            guid,
            selectedCountry,
            selectedSite,
            pageIndex,
            pageSize
        );
        this.setState({ currentRequest: request });

        request
            .then(result => {
                localizeSiteNamesForKits(result.locationMetrics);
                this.setState({
                    loading: false,
                    metrics: result,
                    currentRequest: null
                });
            })
            .fail(error => {
                if (error && error.statusText === 'abort') return;

                this.setState({
                    error: loc.rtsmConnectionError + ' ' + error.status,
                    loading: false,
                    currentRequest: null
                });
            });
    }

    onCloseLocationSettingsModal() {
        this.setState({
            locationSettingsModalIsOpen: false,
            selectedLocation: null
        });
    }

    onOpenLocationSettingsModal(location, hasOverriddenSettings) {
        if (this.state.locationSettingsModalIsOpen) return;

        let selectedLocation = {
            locationGuid: location.locationGuid,
            name: location.name,
            type: location.type,
            hasLocationSpecificSettings: hasOverriddenSettings
        };

        this.setState({
            selectedLocation: selectedLocation,
            locationSettingsModalIsOpen: true
        });
    }

    onSaveLocationModal() {
        this.onCloseLocationSettingsModal();
        this.init();
    }

    render() {
        return (
            <React.Fragment>
                <SettingsModal
                    randGuid={this.props.guid}
                    isOpen={this.props.settingsModalIsOpen}
                    readOnly={isReadOnlyStudyManager(this.props.studyPermissions)}
                    onCloseModal={this.props.onCloseModal}
                    onSaveModal={this.props.onSaveModal}
                    projectName={this.props.projectName}
                ></SettingsModal>
                <LocationsSettingsModal
                    location={this.state.selectedLocation}
                    randGuid={this.props.guid}
                    isOpen={this.state.locationSettingsModalIsOpen}
                    readOnly={isReadOnlyStudyManager(this.props.studyPermissions)}
                    onCloseModal={this.onCloseLocationSettingsModal.bind(this)}
                    onSaveModal={this.onSaveLocationModal.bind(this)}
                ></LocationsSettingsModal>
                <ListOverviewContainer
                    {...this.state}
                    onPageIndexChanged={this.onPageIndexChanged.bind(this)}
                    onPageSizeChanged={this.onPageSizeChanged.bind(this)}
                    onOpenLocationSettingsModal={this.onOpenLocationSettingsModal.bind(this)}
                />
            </React.Fragment>
        );
    }
}

ListOverViewManager.propTypes = {
    guid: PropTypes.string.isRequired,
    selectedCountry: PropTypes.string,
    selectedSite: PropTypes.string,
    studyPermissions: PropTypes.shape({
        isStudyManagerWithEditPermission: PropTypes.bool.isRequired,
        isStudyManagerWithViewPermission: PropTypes.bool.isRequired
    }).isRequired,
    settingsModalIsOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSaveModal: PropTypes.func.isRequired
};
