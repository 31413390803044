import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../shared/loader';
import Modal from '../shared/modal/modal';
import ModalHeader from '../shared/modal/modal-header';
import ModalBody from '../shared/modal/modal-body';
import { settingsConstants, loaderConstants } from '../../constants/constants';
import Error from '../shared/error';
import SettingsAlertContainer from './alerts/settings-alert-container';
import SettingsGeneralContainer from './general/settings-general-container';
import alertSettingsService from '../../services/alertSettings.service';
import generalSettingsService from '../../services/generalSettingsService';
import localization from '../../localization/localization';
import DescriptionTabs from '../shared/description-list/description-tabs';
import DescriptionTab from '../shared/description-list/description-tab';
import DescriptionItems from '../shared/description-list/description-items';
import DescriptionItem from '../shared/description-list/description-item';
import {
    isLevelValid,
    isIgnoreLevelValid,
    getErrorMessagesForLevel,
    getErrorMessagesForIgnoreLevel
} from './alerts/validation/validation';
import {
    isExpiryPeriodValid,
    getErrorMessagesForExpiryPeriod
} from './general/validation/validation';
import ModalError from '../shared/modal/modal-error';
import { isEqual, clone, asNumberOrDefault, isEmpty } from '../../helpers/object.utils';

export default class SettingsModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initState();
    }

    initState() {
        return {
            activeSetting: settingsConstants.type.general,
            alertSettings: null,
            originalAlertSettings: null,
            alertSettingsErrors: [],
            loadingAlertSettings: true,
            generalSettingsErrors: [],
            generalSettings: null,
            originalGeneralSettings: null,
            loadingGeneralSettings: true,
            error: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        const { activeSetting } = this.state;

        //loads when the modal opens, load both tabs, but start with the active one.
        if (!prevProps.isOpen && isOpen) {
            switch (activeSetting) {
                case settingsConstants.type.alert:
                    this.loadAlertSettings().then(this.loadGeneralSettings.bind(this));
                    break;
                case settingsConstants.type.general:
                    this.loadGeneralSettings().then(this.loadAlertSettings.bind(this));
                    break;
                default:
                    throw new Error('Not implemented');
            }
        }
    }

    //#region general settings

    loadGeneralSettings() {
        const { randGuid } = this.props;
        const self = this;

        return new Promise((resolve, reject) => {
            self.setState(
                {
                    generalSettings: null,
                    generalSettingsErrors: [],
                    loadingGeneralSettings: true
                },
                () => {
                    generalSettingsService
                        .get(randGuid)
                        .then(result =>
                            self.setState(
                                {
                                    generalSettings: result,
                                    originalGeneralSettings: clone(result),
                                    loadingGeneralSettings: false
                                }, () => {
                                    self.updateErrorMessagesForGeneralSettings();
                                    resolve();
                                }
                            )
                        )
                        .fail(() =>
                            self.setState({
                                error: localization.getLocOrKey('getGeneralSettingsError'),
                                loadingGeneralSettings: false
                            }, reject)
                        );
                }
            );
        });
    }

    loadAlertSettings() {
        return new Promise((resolve, reject) => {
            this.setState(
                {
                    alertSettings: null,
                    alertSettingsErrors: [],
                    loadingAlertSettings: true
                },
                () => {
                    alertSettingsService
                        .get(this.props.randGuid)
                        .then(result =>
                            this.setState(
                                {
                                    alertSettings: result,
                                    originalAlertSettings: clone(
                                        result
                                    ),
                                    loadingAlertSettings: false
                                }, () => {
                                    this.updateErrorMessagesForAlertSettings();
                                    resolve();
                                }
                            )
                        )
                        .fail(() =>
                            this.setState({
                                error: localization.getLocOrKey('getAlertSettingsError'),
                                loadingAlertSettings: false
                            }, reject)
                        );
                }
            );
        });
    }

    onCloseModal() {
        this.setState(this.initState(), this.props.onCloseModal);
    }

    //#region event subscribers

    onKitIconChange(value) {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    kitIcon: value
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onNumberOfAllocationsPerSubjectChange(value, code) {
        var number = asNumberOrDefault(value, value);

        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    kitTypeSettings: prevState.generalSettings.kitTypeSettings.map(
                        kitTypeSetting =>
                            kitTypeSetting.code === code
                                ? {
                                    ...kitTypeSetting,
                                    numberOfAllocationsPerSubject: number
                                }
                                : kitTypeSetting
                    )
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onAutomaticInvalidationChanged() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    automaticInvalidation: {
                        ...prevState.generalSettings.automaticInvalidation,
                        enabled: !prevState.generalSettings
                            .automaticInvalidation.enabled
                    }
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onAutomaticInvalidationValueChanged(value) {
        var number = asNumberOrDefault(value, value);

        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    automaticInvalidation: {
                        ...prevState.generalSettings.automaticInvalidation,
                        value: number
                    }
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onAllocatedInstantlyWhenInTransfer() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    allowAllocateOnKitsInTransfer: !prevState.generalSettings
                        .allowAllocateOnKitsInTransfer
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onCentralDepotAllocation() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    allowAllocationOnKitsInCentralDepot: !prevState
                        .generalSettings.allowAllocationOnKitsInCentralDepot
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onEditContactInfo() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    allowEditLocationContactInfo: !prevState.generalSettings
                        .allowEditLocationContactInfo
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onTransferToOtherSites() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    allowTransferToOtherSites: !prevState.generalSettings
                        .allowTransferToOtherSites
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onExpiryPeriodEnabledChanged() {
        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    expiryPeriod: {
                        ...prevState.generalSettings.expiryPeriod,
                        enabled: !prevState.generalSettings.expiryPeriod.enabled
                    }
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    onExpiryPeriodValueChanged(value) {

        var number = asNumberOrDefault(value, value);
        if (isEmpty(value)) {
            number = 0;
        }

        this.setState(
            prevState => ({
                ...prevState,
                generalSettings: {
                    ...prevState.generalSettings,
                    expiryPeriod: {
                        ...prevState.generalSettings.expiryPeriod,
                        value: number
                    }
                }
            }),
            this.updateErrorMessagesForGeneralSettings
        );
    }

    //#endregion

    saveGeneralSettings() {
        const { generalSettings, originalGeneralSettings } = this.state;
        return new Promise((resolve, reject) => {
            if (isEqual(generalSettings, originalGeneralSettings)) {
                resolve();
            } else if (this.isGeneralSettingsValid(generalSettings)) {
                generalSettingsService
                    .put(this.props.randGuid, generalSettings)
                    .then(() => {
                        resolve();
                    })
                    .fail(data => {
                        reject(data);
                    });
            } else {
                reject();
            }
        });
    }

    //#region validation

    isGeneralSettingsValid(generalSettings) {
        if (!generalSettings) return false;

        const isValid = isExpiryPeriodValid(generalSettings.expiryPeriod);
        // && generalSettings.kitTypeSettings.every(setting =>
        //     isNumberOfAllocationsPerSubjectValid(setting)
        // ) &&
        // isAutomaticInvalidationValid(generalSettings.automaticInvalidation);

        return isValid;
    }

    //#endregion

    updateErrorMessagesForGeneralSettings() {
        const { generalSettings } = this.state;
        let messages = [];

        const errorMessages = getErrorMessagesForExpiryPeriod(generalSettings.expiryPeriod);
        errorMessages.forEach(x => messages.push(x));


        // generalSettings.kitTypeSettings.map(setting => !isNumberOfAllocationsPerSubjectValid(setting)
        //         ? messages.push(getErrorMessagesForNumberOfAllocationsPerSubject(setting))
        //         : null);

        // if (!isAutomaticInvalidationValid(generalSettings.automaticInvalidation)) {
        //     messages.push(
        //         getErrorMessagesForAutomaticInvalidation(generalSettings.automaticInvalidation)
        //     );
        // }

        this.setState({ generalSettingsErrors: messages });
    }

    //#endregion
    //#region alert settings

    //#region event subscribers
    onLevelChanged(id, value) {
        var number = asNumberOrDefault(value, value);
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                thresholdSettings: prevState.alertSettings.thresholdSettings.map(thresholdSetting => ({
                    ...thresholdSetting,
                    notifications: thresholdSetting.notifications.map(notification =>
                        notification.id === id
                            ? { ...notification, level: number }
                            : notification
                    )
                }))
            }
        }),
            this.updateErrorMessagesForAlertSettings
        );
    }

    onIgnoreLevelChanged(id, value) {
        var number = asNumberOrDefault(value, value);
        this.setState(
            prevState => ({
                ...prevState,
                alertSettings: {
                    ...prevState.alertSettings,
                    thresholdSettings: prevState.alertSettings.thresholdSettings.map(thresholdSetting => ({
                        ...thresholdSetting,
                        notifications: thresholdSetting.notifications.map(notification =>
                            notification.id === id
                                ? { ...notification, ignoreLevel: number }
                                : notification
                        )
                    }))
                }
            }),
            this.updateErrorMessagesForAlertSettings
        );
    }

    onLevelEnabledChanged(id) {
        this.setState(
            prevState => ({
                ...prevState,
                alertSettings: {
                    ...prevState.alertSettings,
                    thresholdSettings: prevState.alertSettings.thresholdSettings.map(thresholdSetting => ({
                        ...thresholdSetting,
                        notifications: thresholdSetting.notifications.map(notification =>
                            notification.id === id
                                ? {
                                    ...notification,
                                    enabled: !notification.enabled
                                }
                                : notification
                        )
                    }))
                }
            }),
            this.updateErrorMessagesForAlertSettings
        );
    }

    onIgnoreLevelEnabledChanged(id) {
        this.setState(
            prevState => ({
                ...prevState,
                alertSettings: {
                    ...prevState.alertSettings,
                    thresholdSettings: prevState.alertSettings.thresholdSettings.map(thresholdSetting => ({
                        ...thresholdSetting,
                        notifications: thresholdSetting.notifications.map(notification =>
                            notification.id === id
                                ? {
                                    ...notification,
                                    enabledIgnore: !notification.enabledIgnore
                                }
                                : notification
                        )
                    }))
                }
            }),
            this.updateErrorMessagesForAlertSettings
        );
    }

    onEnabledEmailAlertOnWarningsChanged() {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                enabledEmailAlertOnWarnings: !prevState.alertSettings.enabledEmailAlertOnWarnings
            }
        }));
    }

    onEnabledReadOnlyEmailAlertOnWarningsChanged() {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                enabledReadOnlyEmailAlertOnWarnings: !prevState.alertSettings.enabledReadOnlyEmailAlertOnWarnings
            }
        }));
    }

    onEnabledEmailAlertOnNotificationsChanged() {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                enabledEmailAlertOnNotifications: !prevState.alertSettings.enabledEmailAlertOnNotifications
            }
        }));
    }

    onEnabledReadOnlyEmailAlertOnNotificationsChanged() {
        this.setState(prevState => ({
            ...prevState,
            alertSettings: {
                ...prevState.alertSettings,
                enabledReadOnlyEmailAlertOnNotifications: !prevState.alertSettings.enabledReadOnlyEmailAlertOnNotifications
            }
        }));
    }

    //#endregion
    saveAlertSettings() {
        const { alertSettings, originalAlertSettings } = this.state;

        return new Promise((resolve, reject) => {
            if (isEqual(alertSettings, originalAlertSettings)) {
                resolve();
            } else if (this.isThresholdSettingsValid(alertSettings.thresholdSettings)) {
                alertSettingsService
                    .put(this.props.randGuid, alertSettings)
                    .then(() => {
                        resolve();
                    })
                    .fail(data => {
                        reject(data);
                    });
            } else {
                reject();
            }
        });
    }
    //#region validation

    isThresholdSettingsValid(thresholdSettings) {
        const isValid = thresholdSettings.every(setting =>
            setting.notifications.every(
                notification =>
                    isLevelValid(notification) &&
                    isIgnoreLevelValid(notification)
            )
        );
        return isValid;
    }

    updateErrorMessagesForAlertSettings() {
        const { alertSettings } = this.state;
        let messages = [];

        alertSettings.thresholdSettings.map(setting =>
            setting.notifications.map(notification =>
                !isLevelValid(notification)
                    ? messages.push(getErrorMessagesForLevel(notification))
                    : null
            )
        );

        alertSettings.thresholdSettings.map(setting =>
            setting.notifications.map(notification =>
                !isIgnoreLevelValid(notification)
                    ? messages.push(
                        getErrorMessagesForIgnoreLevel(notification)
                    )
                    : null
            )
        );

        this.setState({ alertSettingsErrors: messages });
    }

    //#endregion

    //#endregion

    onSaveModal() {
        this.setState(
            { loadingGeneralSettings: true, loadingAlertSettings: true },
            () => {
                this.saveGeneralSettings()
                    .then(() => {
                        this.saveAlertSettings()
                            .then(() => {
                                this.setState({
                                    loadingGeneralSettings: false,
                                    loadingAlertSettings: false
                                });
                                this.setState(this.initState(), this.props.onSaveModal);
                            })
                            .catch(() => {
                                this.setState({
                                    loadingGeneralSettings: false,
                                    loadingAlertSettings: false,
                                    error: localization.getLocOrKey('couldNotSaveAlertSettings')
                                });
                            });
                    })
                    .catch(() => {
                        this.setState({
                            loadingGeneralSettings: false,
                            loadingAlertSettings: false,
                            error: localization.getLocOrKey('couldNotSaveGeneralSettings')
                        });
                    });
            }
        );
    }

    onDescriptionTabClick(id) {
        this.setState({ activeSetting: id });
    }

    //#region rendering

    renderSettingsAlertContainer() {
        const { alertSettings, loadingAlertSettings } = this.state;
        const { readOnly } = this.props;

        if (loadingAlertSettings) {
            return null;
        }

        return (
            <SettingsAlertContainer
                settings={alertSettings.thresholdSettings}
                readOnly={readOnly}
                onLevelChanged={this.onLevelChanged.bind(this)}
                onIgnoreLevelChanged={this.onIgnoreLevelChanged.bind(this)}
                onLevelEnabledChanged={this.onLevelEnabledChanged.bind(this)}
                hideIgnoreOptions={true}
                onIgnoreLevelEnabledChanged={this.onIgnoreLevelEnabledChanged.bind(this)}
                enabledEmailAlertOnWarnings={alertSettings.enabledEmailAlertOnWarnings}
                enabledReadOnlyEmailAlertOnWarnings={alertSettings.enabledReadOnlyEmailAlertOnWarnings}
                enabledEmailAlertOnNotifications={alertSettings.enabledEmailAlertOnNotifications}
                enabledReadOnlyEmailAlertOnNotifications={alertSettings.enabledReadOnlyEmailAlertOnNotifications}
                onEnabledEmailAlertOnWarningsChanged={this.onEnabledEmailAlertOnWarningsChanged.bind(this)}
                onEnabledReadOnlyEmailAlertOnWarningsChanged={this.onEnabledReadOnlyEmailAlertOnWarningsChanged.bind(this)}
                onEnabledEmailAlertOnNotificationsChanged={this.onEnabledEmailAlertOnNotificationsChanged.bind(this)}
                onEnabledReadOnlyEmailAlertOnNotificationsChanged={this.onEnabledReadOnlyEmailAlertOnNotificationsChanged.bind(this)}
            />
        );
    }

    renderSettingsGeneralContainer() {
        const { generalSettings, loadingGeneralSettings } = this.state;
        const { readOnly } = this.props;

        if (loadingGeneralSettings) {
            return null;
        }

        return (
            <SettingsGeneralContainer
                settings={generalSettings}
                readOnly={readOnly}
                onKitIconChange={this.onKitIconChange.bind(this)}
                onNumberOfAllocationsPerSubjectChange={this.onNumberOfAllocationsPerSubjectChange.bind(this)}
                onAutomaticInvalidationChanged={this.onAutomaticInvalidationChanged.bind(this)}
                onAutomaticInvalidationValueChanged={this.onAutomaticInvalidationValueChanged.bind(this)}
                onAllocatedInstantlyWhenInTransfer={this.onAllocatedInstantlyWhenInTransfer.bind(this)}
                onCentralDepotAllocation={this.onCentralDepotAllocation.bind(this)}
                onEditContactInfo={this.onEditContactInfo.bind(this)}
                onTransferToOtherSites={this.onTransferToOtherSites.bind(this)}
                onExpiryPeriodEnabledChanged={this.onExpiryPeriodEnabledChanged.bind(this)}
                onExpiryPeriodValueChanged={this.onExpiryPeriodValueChanged.bind(this)}
            />
        );
    }

    renderDescriptionItems() {
        const { activeSetting, error, loadingGeneralSettings, loadingAlertSettings } = this.state;

        return (
            <React.Fragment>
                <DescriptionItem active={activeSetting === settingsConstants.type.general}>
                    <div className="box-section margin-top-0 with-divider" style={{ margin: 0 }}>
                        {loadingGeneralSettings ?
                            <div className="field-row">
                                <Loader loading={loadingGeneralSettings} type={loaderConstants.type.small} style={{ margin: 'auto' }} />
                            </div>
                            : this.renderSettingsGeneralContainer()}
                    </div>
                </DescriptionItem>
                <DescriptionItem active={activeSetting === settingsConstants.type.alert}>
                    <div className="box-section margin-top-0 with-divider" style={{ margin: 0 }}>
                        {loadingAlertSettings ?
                            <div className="field-row">
                                <Loader loading={loadingAlertSettings} type={loaderConstants.type.small} style={{ margin: 'auto' }} />
                            </div>
                            : this.renderSettingsAlertContainer()}
                    </div>
                </DescriptionItem>
                <Error message={error}></Error>
            </React.Fragment>
        );
    }

    renderModalContent() {
        const {
            activeSetting,
            alertSettings,
            originalAlertSettings,
            generalSettings,
            originalGeneralSettings,
            generalSettingsErrors,
            alertSettingsErrors,
            loadingAlertSettings,
            loadingGeneralSettings
        } = this.state;

        const { projectName, readOnly } = this.props;

        return (
            <React.Fragment>
                <ModalHeader
                    heading={projectName}
                    saveBtnDisabled={
                        readOnly
                        || loadingGeneralSettings
                        || loadingAlertSettings
                        || (alertSettings ? !this.isThresholdSettingsValid(alertSettings.thresholdSettings) : false)
                        || !this.isGeneralSettingsValid(generalSettings)
                        || (isEqual(alertSettings, originalAlertSettings) &&
                            isEqual(generalSettings, originalGeneralSettings))}
                    onCloseModal={this.onCloseModal.bind(this)}
                    onSaveModal={this.onSaveModal.bind(this)}>
                    <ModalError
                        errors={
                            generalSettingsErrors
                                || alertSettingsErrors
                                ? generalSettingsErrors.concat(alertSettingsErrors) : []} />
                </ModalHeader>
                <ModalBody
                    heading={localization.getLocOrKey(
                        'viedocLogisticsSettings')}>
                    <DescriptionTabs>
                        <DescriptionTab
                            id={settingsConstants.type.general}
                            active={activeSetting === settingsConstants.type.general}
                            text={localization.getLocOrKey('general')}
                            //if it's loading, the tab is not displayed as invalid
                            valid={loadingGeneralSettings || this.isGeneralSettingsValid(generalSettings)}
                            onClick={this.onDescriptionTabClick.bind(this)} />
                        <DescriptionTab
                            id={settingsConstants.type.alert}
                            active={activeSetting === settingsConstants.type.alert}
                            text={localization.getLocOrKey('alerts')}
                            //if it's loading, the tab is not displayed as invalid
                            valid={loadingAlertSettings || (alertSettings ? this.isThresholdSettingsValid(alertSettings.thresholdSettings) : false)}
                            onClick={this.onDescriptionTabClick.bind(this)} />
                    </DescriptionTabs>
                    <DescriptionItems>
                        {this.renderDescriptionItems()}
                    </DescriptionItems>
                </ModalBody>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.props.isOpen}
                    classNames={['large']}>
                    {this.renderModalContent()}
                </Modal>
            </React.Fragment>
        );
    }

    //#endregion
}

SettingsModal.propTypes = {
    randGuid: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSaveModal: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired
};
