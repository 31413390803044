import React from 'react';
import PropTypes from 'prop-types';
import ClickOutisde from '../click-outside';

export default class Modal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classNames, isOpen, id } = this.props;

        const cssClasses = isOpen ? 'logistics-modal logistics-modal-open' : 'logistics-modal logistics-modal-closed';

        return (
            <div role="dialog" aria-modal="true" tabIndex="-1" aria-hidden="true" className={cssClasses}>
                <ClickOutisde
                    active={isOpen && this.props.onClose !== undefined}
                    onClick={this.props.onClose}>
                    <div id={id} role="document" className={`logistics-modal-main modal ${classNames ? classNames.map((str) => str + ' ') : ''}`}>
                        {this.props.children}
                    </div>
                </ClickOutisde>
            </div>
        );
    }
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    classNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClose: PropTypes.func,
    id: PropTypes.string
};