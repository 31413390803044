import { kitConstants, kitListConstants } from '../constants/constants';

export const getKitStatus = kit => {
    try {
        return kitConstants.status[kit.status];
    } catch (err) {
        throw new Error('Kit status is invalid: ' + err);
    }
};

export const getSortOrder = column => {
    if (column.sortingDirection === kitListConstants.order.desc) {
        return kitListConstants.order.desc;
    } else if (column.sortingDirection === kitListConstants.order.asc) {
        return kitListConstants.order.asc;
    }

    return kitListConstants.order.none;
};

export const getUpdatedColumns = (kitColumns, orderByColumn) => {
    let newKitColumns = [...kitColumns];
    for (let i = 0; i < newKitColumns.length; i++) {
        if (orderByColumn.name === newKitColumns[i].name) {
            newKitColumns[i].sortingDirection = toggleOrderBy(orderByColumn);
        } else {
            newKitColumns[i].sortingDirection = kitListConstants.order.none;
        }
    }

    return newKitColumns;
};

export const toggleOrderBy = column => {
    let sortOrder = getSortOrder(column);

    switch (sortOrder) {
        case kitListConstants.order.asc:
            return kitListConstants.order.desc;
        case kitListConstants.order.desc:
            return kitListConstants.order.asc;
        case kitListConstants.order.none:
            return kitListConstants.order.asc;
        default:
            throw new Error('Incorrect sort order supplied');
    }
};

export const getOrderByValue = orderBy => {
    if (!orderBy || orderBy === '')
        return null;
    return orderBy;
};

export const getSortingDirectionValue = sortingDirection => {
    if (!sortingDirection || sortingDirection === '')
        return null;
    
    return sortingDirection;
};

export const filterSelectableKits = (kits, selectableKits) => {
    return kits.filter(kit => isKitSelectable(kit, selectableKits));
};

export const isKitSelectable = (kit, selectableKits) => {
    return selectableKits.includes(kit.kitGuid);
};

export const isSomeKitsSelectable = (kits, selectableKits) => {
    const test =  kits.some(kit => selectableKits.includes(kit.kitGuid));

    return test;
};