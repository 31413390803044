import modelValidatorCreator from '../../../validators/model-validator-creator';

const restoreModel = modelValidatorCreator.createModelWithValidation({
    reason: {
        value: null,
        required: true
    }
});

export default restoreModel;
