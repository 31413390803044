import React from "react";
import PropTypes from 'prop-types';

export default class LazyInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        };
    }

    handleChange(event) {
        const self = this;

        this.setState({ value: event.target.value },
            this.props.onChange(event.target.value));

        //triggers onchange after scanned
        setTimeout(self.onLazyChange.bind(this, event.target.value), 100);
    };

    onLazyChange(val) {
        const { value } = this.state;

        if (value === val) {
            this.props.onLazyChange(value);
        }
    };

    render() {
        const { value, clasNames, placeholder } = this.props;

        return (
            <input type="text"
                value={value}
                placeholder={placeholder}
                onChange={this.handleChange.bind(this)}
                className={`${clasNames ? clasNames.map((str) => str + ' ') : ''}`}
                onKeyDown={e => this.props.onKeyPressed(e, e.target.value)} />
        );
    }
};

LazyInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    clasNames: PropTypes.array.isRequired,
    onLazyChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    onKeyDown: PropTypes.array,
    placeholder: PropTypes.string
};